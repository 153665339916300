import * as React from 'react';

const StarSvg = () => {
    return (
        <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.8">
                <path d="M11.9167 5.73793C11.8825 5.63886 11.8202 5.55183 11.7376 5.48741C11.6549 5.423 11.5553 5.38395 11.4508 5.37502L8.36876 4.92543L6.98751 2.12502C6.94315 2.03344 6.8739 1.9562 6.78768 1.90216C6.70146 1.84812 6.60176 1.81946 6.50001 1.81946C6.39825 1.81946 6.29855 1.84812 6.21234 1.90216C6.12612 1.9562 6.05686 2.03344 6.01251 2.12502L4.63126 4.92002L1.54917 5.37502C1.44892 5.38927 1.35468 5.43133 1.27712 5.49644C1.19957 5.56154 1.14182 5.64708 1.11042 5.74335C1.08169 5.83742 1.07911 5.93755 1.10296 6.03298C1.12682 6.1284 1.17621 6.21554 1.24584 6.28502L3.48292 8.45168L2.94126 11.5284C2.92192 11.6299 2.93205 11.7349 2.97044 11.8308C3.00883 11.9268 3.07389 12.0098 3.15792 12.07C3.23983 12.1286 3.33644 12.1631 3.43691 12.1698C3.53737 12.1765 3.63772 12.1551 3.72668 12.1079L6.50001 10.6617L9.26251 12.1134C9.33853 12.1562 9.42439 12.1786 9.51167 12.1784C9.62641 12.1788 9.73832 12.1427 9.83126 12.0754C9.9153 12.0152 9.98035 11.9322 10.0187 11.8363C10.0571 11.7403 10.0673 11.6353 10.0479 11.5338L9.50626 8.4571L11.7433 6.29043C11.8215 6.22418 11.8794 6.13709 11.91 6.03929C11.9407 5.94149 11.943 5.83699 11.9167 5.73793ZM8.58542 7.9046C8.5219 7.96604 8.47437 8.0421 8.44699 8.12613C8.41961 8.21016 8.41321 8.29961 8.42834 8.38668L8.81834 10.6563L6.78167 9.57293C6.70331 9.5312 6.61588 9.50938 6.52709 9.50938C6.4383 9.50938 6.35088 9.5312 6.27251 9.57293L4.23584 10.6563L4.62584 8.38668C4.64097 8.29961 4.63457 8.21016 4.60719 8.12613C4.57981 8.0421 4.53228 7.96604 4.46876 7.9046L2.84376 6.2796L5.12418 5.94918C5.21192 5.93698 5.29534 5.90344 5.36711 5.8515C5.43888 5.79956 5.49682 5.7308 5.53584 5.65127L6.50001 3.58752L7.51834 5.65668C7.55736 5.73622 7.6153 5.80497 7.68707 5.85691C7.75884 5.90885 7.84226 5.9424 7.93001 5.9546L10.2104 6.28502L8.58542 7.9046Z" fill="#E50A47" stroke="#F2F3F5" stroke-width="0.2" />
            </g>
        </svg>
    );
}

export default StarSvg;