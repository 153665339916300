import React from 'react';
import { Button, Card, Col, Row, Space, Spin, Switch } from 'antd';
import StarSvg from '../../assets/images/StarSvg';
import HeartSvg from '../../assets/images/heartSvg';
import EditTableSvg from '../../assets/images/EditTable';
import DeleteTableSvg from '../../assets/images/DeleteTable';
import ViewTableSvg from '../../assets/images/ViewTable';
import PaginationFetchData from '../data-table/PaginationFetchData';

const MagazineCard = ({
    magazineData, setLoading, loading, t,
    tableParams, setTableParams, tableSettings, navigate, 
    hadleUpdateMagazine, deleteMagazineData, handleShowMagazine
}) => {
    return (
        <Spin spinning={loading}>
            <div className='row'>
                {
                    magazineData.map((mag, index) => (
                        <div className='mb-4 col-sm-12 col-md-4 col-lg-4'>
                            <Card className='w-100 h-100 cusCard'>
                                <Row>
                                    <Col md={8}>
                                        <img
                                            src={mag && mag.picUrl}
                                            alt="Magazine Cover"
                                            style={{
                                                height: "133px",
                                                width: "100px",
                                                objectFit: "cover",
                                                borderRadius: "6px",
                                            }}
                                        />
                                    </Col>
                                    <Col md={16}>
                                        <Row>
                                            <Col md={24}>{t(mag.title)}</Col>
                                            <Col md={24} className='mt-2'>
                                                {t(mag.year)}
                                                <Button
                                                    disabled
                                                    style={{
                                                        backgroundColor: "rgba(209, 212, 220, 0.3)",
                                                        width: "auto",
                                                        height: "20px",
                                                        borderRadius: "6px",
                                                        border: "none",
                                                        color: "#E50A47",
                                                        cursor: 'default'
                                                    }}
                                                    icon={<StarSvg />}
                                                >
                                                    {mag && mag.favorite}
                                                </Button>
                                                <Button
                                                    disabled
                                                    style={{
                                                        backgroundColor: "rgba(209, 212, 220, 0.3)",
                                                        width: "auto",
                                                        height: "20px",
                                                        borderRadius: "6px",
                                                        border: "none",
                                                        marginLeft: "10px",
                                                        color: "#E50A47",
                                                        cursor: 'default'
                                                    }}
                                                    icon={<HeartSvg />}
                                                >
                                                    {mag && mag.like}
                                                </Button>
                                            </Col>
                                            <Col md={24} className='mt-2 me-3'>
                                                {mag && mag.tags.map((tag, Index) => (
                                                    <Button
                                                        disabled
                                                        style={{
                                                            backgroundColor: "#FDECF1",
                                                            width: "auto",
                                                            height: "25px",
                                                            borderRadius: "6px",
                                                            border: "none",
                                                            color: "#E50A47",
                                                            margin: "0 8px 8px 0",
                                                            fontSize: "12px",
                                                            cursor: 'default'
                                                        }}
                                                    >
                                                        {tag.name}
                                                    </Button>
                                                ))
                                                }
                                            </Col>
                                            <Col md={24} className='mt-2'>
                                                <Space size="middle">
                                                <Switch checked={mag.status} onChange={() => hadleUpdateMagazine(mag)} />
                                                    <span style={{ cursor: "pointer" }} onClick={() => navigate(`/magazine/upload/${mag.magazineId}`)}>
                                                        <EditTableSvg />
                                                    </span>
                                                    <span style={{ cursor: "pointer" }} onClick={() => deleteMagazineData(mag)}>
                                                        <DeleteTableSvg />
                                                    </span>
                                                    <span style={{ cursor: "pointer" }}onClick={() => handleShowMagazine(mag)}>
                                                        <ViewTableSvg />
                                                    </span>
                                                </Space>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card>
                        </div>
                    ))
                }
                <PaginationFetchData
                    tableParams={tableParams}
                    setTableParams={setTableParams}
                    data={magazineData}
                    columns={tableSettings.columns}
                    loading={loading}
                    setLoading={setLoading}
                />
            </div>
        </Spin>
    );
};

export default MagazineCard;
