import * as React from 'react';

const HeartSvg = () => {
    return (
        <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.8">
                <path d="M10.92 3.20832C10.3458 2.63264 9.58469 2.28128 8.77411 2.2177C7.96352 2.15413 7.15692 2.38253 6.50001 2.86165C5.81082 2.34904 4.953 2.1166 4.0993 2.21113C3.24559 2.30567 2.45941 2.72016 1.89908 3.37114C1.33874 4.02212 1.04587 4.86123 1.07944 5.7195C1.11301 6.57776 1.47053 7.39143 2.08001 7.99665L5.44376 11.3658C5.72543 11.643 6.1048 11.7984 6.50001 11.7984C6.89521 11.7984 7.27458 11.643 7.55626 11.3658L10.92 7.99665C11.5524 7.36034 11.9074 6.49964 11.9074 5.60249C11.9074 4.70534 11.5524 3.84464 10.92 3.20832ZM10.1563 7.24915L6.79251 10.6129C6.75423 10.6516 6.70867 10.6822 6.65847 10.7032C6.60826 10.7241 6.5544 10.7349 6.50001 10.7349C6.44561 10.7349 6.39175 10.7241 6.34155 10.7032C6.29134 10.6822 6.24578 10.6516 6.20751 10.6129L2.84376 7.2329C2.41896 6.79867 2.18109 6.21536 2.18109 5.6079C2.18109 5.00044 2.41896 4.41713 2.84376 3.9829C3.27663 3.55552 3.86045 3.31588 4.46876 3.31588C5.07706 3.31588 5.66088 3.55552 6.09376 3.9829C6.14411 4.03367 6.20402 4.07397 6.27003 4.10147C6.33604 4.12897 6.40683 4.14313 6.47834 4.14313C6.54985 4.14313 6.62065 4.12897 6.68665 4.10147C6.75266 4.07397 6.81257 4.03367 6.86292 3.9829C7.2958 3.55552 7.87962 3.31588 8.48792 3.31588C9.09623 3.31588 9.68005 3.55552 10.1129 3.9829C10.5436 4.41144 10.7892 4.99159 10.7973 5.59907C10.8054 6.20654 10.5753 6.79304 10.1563 7.2329V7.24915Z" fill="#E50A47" stroke="#F2F3F5" stroke-width="0.2" />
            </g>
        </svg>
    );
}

export default HeartSvg;