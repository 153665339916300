import {
    Affix,
    Alert,
    Button,
    Card,
    Checkbox,
    Col,
    Collapse,
    Dropdown,
    Menu,
    Modal,
    Popconfirm,
    Result,
    Row,
    Tabs,
    Tag,
    Tooltip,
    Tree,
    Typography,
    Upload,
} from 'antd';
import { AddBox, ChangeCircle, DeleteSweep, UploadFile } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { FloatingLabel, Form } from 'react-bootstrap';
import JSONInput from 'react-json-editor-ajrm';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { BsCardImage, BsCardText, BsFillMenuButtonFill } from 'react-icons/bs';
import { MdErrorOutline, MdFormatListBulleted } from 'react-icons/md';
import { FaHeading } from 'react-icons/fa';
import { AiFillDatabase, AiOutlineLink } from 'react-icons/ai';
import moment from 'moment/moment';
import { FcTreeStructure } from 'react-icons/fc';
import { BiArrowToTop } from 'react-icons/bi';
import { useNavigate, useParams } from 'react-router-dom';
import Slider from '@mui/material/Slider';
import { useDispatch, useSelector } from 'react-redux';
import { AccordionSvg, ButtonSvg, CoverSvg, H1Svg, H2Svg, H3Svg, H4Svg, H5Svg, ImageSvg, LinkSvg, UnorderedListSvg } from '../../assets/images/DragSvg';
import { useTranslation } from 'react-i18next';

let localize = {
    "cn": {
        "title": "多拉·弗莱彻，89 岁，60 多年来一直致力于美发事业",
        "subtitle": "全国美发美容联合会 (NHBF) 为来自南港的 89 岁美发师 Dora Fletcher 颁发了荣誉",
        "layout": [
            {
                "label": "内容",
                "tag": "content",
                "text": "Hello",
                "textDecoration": "underline",//none, underline
                "margintop": 0,
                "marginBottom": 0,
                "marginLeft": 0,
                "marginRight": 0,
                "textAlign": "left",//center, rigth, justify
                "italicType": "none",//italic
            },
            {
                "label": "无序列表",
                "tag": "bullet",
                "text": "Hello",
                "textDecoration": "underline",
                "margintop": 0,
                "marginBottom": 0,
                "marginLeft": 0,
                "marginRight": 0,
                "textAlign": "left",
                "italicType": "none",//italic
            },
            {
                "label": "按钮",
                "tag": "button",
                "text": "confirm",
                "navigation": {
                    "routerName": "",
                    "screenName": "",
                    "params": {}
                },
                "textDecoration": "underline",
                "margintop": 0,
                "marginBottom": 0,
                "marginLeft": 0,
                "marginRight": 0,
                "textAlign": "left",
                "italicType": "none",//italic
            },
            {
                "label": "H1",
                "tag": "h1",
                "text": "Hello",
                "textDecoration": "underline",
                "margintop": 0,
                "marginBottom": 0,
                "marginLeft": 0,
                "marginRight": 0,
                "textAlign": "left",
                "italicType": "none",//italic
            },
            {
                "label": "H2",
                "tag": "h2",
                "text": "Hello",
                "textDecoration": "underline",
                "margintop": 0,
                "marginBottom": 0,
                "marginLeft": 0,
                "marginRight": 0,
                "textAlign": "left",
                "italicType": "none",//italic
            },
            {
                "label": "H3",
                "tag": "h3",
                "text": "Hello",
                "textDecoration": "underline",
                "margintop": 0,
                "marginBottom": 0,
                "marginLeft": 0,
                "marginRight": 0,
                "textAlign": "left",
                "italicType": "none",//italic
            },
            {
                "label": "H4",
                "tag": "h4",
                "text": "Hello",
                "textDecoration": "underline",
                "margintop": 0,
                "marginBottom": 0,
                "marginLeft": 0,
                "marginRight": 0,
                "textAlign": "left",
                "italicType": "none",//italic
            },
            {
                "label": "H5",
                "tag": "h5",
                "text": "Hello",
                "textDecoration": "underline",
                "margintop": 0,
                "marginBottom": 0,
                "marginLeft": 0,
                "marginRight": 0,
                "textAlign": "left",
                "italicType": "none",//italic
            },
            {
                "label": "链接",
                "tag": "link",
                "link": "https://w...",
                "text": "Hello",
                "textDecoration": "underline",
                "margintop": 0,
                "marginBottom": 0,
                "marginLeft": 0,
                "marginRight": 0,
                "textAlign": "left",
                "italicType": "none",//italic
            },
            {
                "label": "图片",
                "tag": "image",
                "text": "Hello",
                "url": "http://esipro-dev.oss-cn-shanghai.aliyuncs.com/public/1732153454621-9kb.jpg",
                "textDecoration": "underline",
                "margintop": 0,
                "marginBottom": 0,
                "marginLeft": 0,
                "marginRight": 0,
                "textAlign": "left",
                "italicType": "none",//italic
            },
            {
                "label": "手风琴",
                "tag": "accordion",
                "text": "Hello",
                "accordion": [
                    {
                        "label": "内容",
                        "tag": "content",
                        "text": "Hello",
                        "textDecoration": "underline",
                        "margintop": 0,
                        "marginBottom": 0,
                        "marginLeft": 0,
                        "marginRight": 0,
                        "textAlign": "left",
                        "italicType": "none",//italic
                    },
                    {
                        "label": "无序列表",
                        "tag": "bullet",
                        "text": "Hello",
                        "textDecoration": "underline",
                        "margintop": 0,
                        "marginBottom": 0,
                        "marginLeft": 0,
                        "marginRight": 0,
                        "textAlign": "left",
                        "italicType": "none",//italic
                    },
                    {
                        "label": "按钮",
                        "tag": "button",
                        "text": "confirm",
                        "navigation": {
                            "routerName": "",
                            "screenName": "",
                            "params": {}
                        },
                        "textDecoration": "underline",
                        "margintop": 0,
                        "marginBottom": 0,
                        "marginLeft": 0,
                        "marginRight": 0,
                        "textAlign": "left",
                        "italicType": "none",//italic
                    },
                    {
                        "label": "H1",
                        "tag": "h1",
                        "text": "Hello",
                        "textDecoration": "underline",
                        "margintop": 0,
                        "marginBottom": 0,
                        "marginLeft": 0,
                        "marginRight": 0,
                        "textAlign": "left",
                        "italicType": "none",//italic
                    },
                    {
                        "label": "H2",
                        "tag": "h2",
                        "text": "Hello",
                        "textDecoration": "underline",
                        "margintop": 0,
                        "marginBottom": 0,
                        "marginLeft": 0,
                        "marginRight": 0,
                        "textAlign": "left",
                        "italicType": "none",//italic
                    },
                    {
                        "label": "H3",
                        "tag": "h3",
                        "text": "Hello",
                        "textDecoration": "underline",
                        "margintop": 0,
                        "marginBottom": 0,
                        "marginLeft": 0,
                        "marginRight": 0,
                        "textAlign": "left",
                        "italicType": "none",//italic
                    },
                    {
                        "label": "H4",
                        "tag": "h4",
                        "text": "Hello",
                        "textDecoration": "underline",
                        "margintop": 0,
                        "marginBottom": 0,
                        "marginLeft": 0,
                        "marginRight": 0,
                        "textAlign": "left",
                        "italicType": "none",//italic
                    },
                    {
                        "label": "H5",
                        "tag": "h5",
                        "text": "Hello",
                        "textDecoration": "underline",
                        "margintop": 0,
                        "marginBottom": 0,
                        "marginLeft": 0,
                        "marginRight": 0,
                        "textAlign": "left",
                        "italicType": "none",//italic
                    },
                    {
                        "label": "链接",
                        "tag": "link",
                        "link": "https://w...",
                        "text": "Hello",
                        "textDecoration": "underline",
                        "margintop": 0,
                        "marginBottom": 0,
                        "marginLeft": 0,
                        "marginRight": 0,
                        "textAlign": "left",
                        "italicType": "none",//italic
                    },
                    {
                        "label": "图片",
                        "tag": "image",
                        "text": "Hello",
                        "url": "http://esipro-dev.oss-cn-shanghai.aliyuncs.com/public/1732153454621-9kb.jpg",
                        "textDecoration": "underline",
                        "margintop": 0,
                        "marginBottom": 0,
                        "marginLeft": 0,
                        "marginRight": 0,
                        "textAlign": "left",
                        "italicType": "none",//italic
                    },
                ]
            },
            {
                "label": "手风琴",
                "tag": "accordion",
                "text": "Hello",
                "accordion": [
                    {
                        "label": "内容",
                        "tag": "content",
                        "text": "Hello",
                        "textDecoration": "underline",
                        "margintop": 0,
                        "marginBottom": 0,
                        "marginLeft": 0,
                        "marginRight": 0,
                        "textAlign": "left",
                        "italicType": "none",//italic
                    },
                    {
                        "label": "无序列表",
                        "tag": "bullet",
                        "text": "Hello",
                        "textDecoration": "underline",
                        "margintop": 0,
                        "marginBottom": 0,
                        "marginLeft": 0,
                        "marginRight": 0,
                        "textAlign": "left",
                        "italicType": "none",//italic
                    },
                    {
                        "label": "按钮",
                        "tag": "button",
                        "text": "confirm",
                        "navigation": {
                            "routerName": "",
                            "screenName": "",
                            "params": {}
                        },
                        "textDecoration": "underline",
                        "margintop": 0,
                        "marginBottom": 0,
                        "marginLeft": 0,
                        "marginRight": 0,
                        "textAlign": "left",
                        "italicType": "none",//italic
                    },
                    {
                        "label": "H1",
                        "tag": "h1",
                        "text": "Hello",
                        "textDecoration": "underline",
                        "margintop": 0,
                        "marginBottom": 0,
                        "marginLeft": 0,
                        "marginRight": 0,
                        "textAlign": "left",
                        "italicType": "none",//italic
                    },
                    {
                        "label": "H2",
                        "tag": "h2",
                        "text": "Hello",
                        "textDecoration": "underline",
                        "margintop": 0,
                        "marginBottom": 0,
                        "marginLeft": 0,
                        "marginRight": 0,
                        "textAlign": "left",
                        "italicType": "none",//italic
                    },
                    {
                        "label": "H3",
                        "tag": "h3",
                        "text": "Hello",
                        "textDecoration": "underline",
                        "margintop": 0,
                        "marginBottom": 0,
                        "marginLeft": 0,
                        "marginRight": 0,
                        "textAlign": "left",
                        "italicType": "none",//italic
                    },
                    {
                        "label": "H4",
                        "tag": "h4",
                        "text": "Hello",
                        "textDecoration": "underline",
                        "margintop": 0,
                        "marginBottom": 0,
                        "marginLeft": 0,
                        "marginRight": 0,
                        "textAlign": "left",
                        "italicType": "none",//italic
                    },
                    {
                        "label": "H5",
                        "tag": "h5",
                        "text": "Hello",
                        "textDecoration": "underline",
                        "margintop": 0,
                        "marginBottom": 0,
                        "marginLeft": 0,
                        "marginRight": 0,
                        "textAlign": "left",
                        "italicType": "none",//italic
                    },
                    {
                        "label": "链接",
                        "tag": "link",
                        "link": "https://w...",
                        "text": "Hello",
                        "textDecoration": "underline",
                        "margintop": 0,
                        "marginBottom": 0,
                        "marginLeft": 0,
                        "marginRight": 0,
                        "textAlign": "left",
                        "italicType": "none",//italic
                    },
                    {
                        "label": "图片",
                        "tag": "image",
                        "text": "Hello",
                        "url": "http://esipro-dev.oss-cn-shanghai.aliyuncs.com/public/1732153454621-9kb.jpg",
                        "textDecoration": "underline",
                        "margintop": 0,
                        "marginBottom": 0,
                        "marginLeft": 0,
                        "marginRight": 0,
                        "textAlign": "left",
                        "italicType": "none",//italic
                    },
                ]
            },
        ]
    }
}


const LeftDragTextComponent = ({ compose, edit, template, types }) => {
    const { i18n, t } = useTranslation();

    const typeDraggingStyle = (isDragging, draggableStyle) => ({
        ...draggableStyle,
        background: isDragging ? 'lightgreen' : 'white',
        transform: isDragging ? draggableStyle?.transform : 'translate(0px, 0px)',
    });

    return <div className="w-100">
        <div className="w-100"
        style={{
            minHeight: "10rem", 
            maxHeight: "14rem",
            overflow: "auto"
        }}
        >
            {/* <Affix offsetTop={65}> */}
            <div style={{ cursor: 'pointer' }} onClick={() => window.scrollTo(0, 0)}>
                {/* <FcTreeStructure /> 布局 <BiArrowToTop /> */}
                布局
            </div>
            <Tree
                showLine={{ showLeafIcon: false }}
                showIcon={true}
                showLeafIcon={false}
                treeData={(() => {
                    const generateTree = (elements, indexList) => {
                        return elements.map((el, i) => ({
                            title: `${el.text}`,
                            key: `${"cn"}-content-draggable@${indexList.join('-')}-${i}`,
                            // icon: `icon`,
                            children: el.accordion ? generateTree(el.accordion, [...indexList, i]) : [],
                        }));
                    };

                    return generateTree(localize['cn'].layout, []);
                })()}
                onSelect={(selectedKeys) => {
                    const el = document.querySelector(
                        `div[data-rbd-drag-handle-draggable-id="${selectedKeys[0]}"]`
                    );
                    el.scrollIntoView({ behavior: 'smooth' });
                }}
            />
            {/* </Affix> */}
        </div>
        <div className="w-100"><hr /></div>
        <div className="w-100">
            <div className='w-100 d-flex justify-content-between'>
                <span>工具栏</span>
                <span style={{ opacity: "0.4", fontSize: "12px" }}>拖动工具放入画布中</span>
            </div>
            <div className='w-100'>
                <Droppable key={'types'} droppableId={'types'} isDropDisabled={true}>
                    {(typesProvided, typesSnapshot) => {
                        // console.log("Draggable = ", typesProvided);
                        // console.log("Draggable = ", typesSnapshot);
                        return <>
                            <div
                                className="row"
                                ref={typesProvided.innerRef}
                                style={{ padding: 8 }}
                                {...typesProvided.droppableProps}
                            >
                                {types.map((type, index) => {
                                    return <Draggable  key={type.tag} draggableId={type.tag} index={index}>
                                        {(provided, snapshot) => {
                                            // console.log("Draggable = ", provided);
                                            // console.log("Draggable = ", snapshot);
                                            return <div className='col-4'>
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={{
                                                        userSelect: "none",
                                                        ...typeDraggingStyle(snapshot.isDragging, provided.draggableProps.style),
                                                    }}
                                                >
                                                    <div className="cursor-grab d-flex flex-wrap align-content-center justify-content-center mt-2 flex-column text-center">
                                                    <div>{type.icon || ''}</div>
                                                    <div>{t(type.text)}</div>
                                                    </div>
                                                </div>
                                                {snapshot.isDragging && (
                                                    <div
                                                        style={{ userSelect: "none" }}
                                                    >
                                                        <div className="cursor-grab d-flex flex-wrap align-content-center justify-content-center mt-2 flex-column text-center">
                                                            <div>{type.icon || ''}</div>
                                                            <div>{t(type.text)}</div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        }}
                                    </Draggable>
                                })}
                            </div>
                        </>
                    }}
                </Droppable>
            </div>
        </div>
    </div>
}
export default LeftDragTextComponent;