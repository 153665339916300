import React, { useState, useRef } from 'react';
import { Upload, message, Image, Spin } from 'antd';
import EmptyImageSvg from '../../assets/images/EmptyImageSvg';
import ChangeCoverSvg from '../../assets/images/ChangeCoverSvg';
import { useTranslation } from 'react-i18next';
import axios from 'axios'; // Import axios
import { ADMIN_ENDPOINT } from '../../config/api'; // Your API endpoint
import { postDataRequest } from '../../api/serviceMethods';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { UPLOAD_PUBLIC_URL } from '../../api/URLs';


const UploadComponent = ({ size = 1, fileList, setFileList, isError = false, isRequire = false, uploadavatarTitle="", changeAvatarTitle="", hideAvatarTitle=false, hideuploadavatarTitle=false, className="", classNameDiv="",
    allowType = ["image/jpeg", "image/png",  "image/jpg"]
 }) => {
    const { t } = useTranslation();
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    // const [fileList, setFileList] = useState([]);
    const uploadRef = useRef(null);
    const [loading, setLoading] = useState(false);


    // Convert image file to base64
    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    // Before upload validation
    const beforeUpload = (file) => {
        if (!allowType.includes(file.type)) {
            message.error('You cannot allow upload file!');
            return false;
        }
        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
            message.error('Image must smaller than 5MB!');
            return false;
        }
        return true;
    };

    // Handle preview for image files
    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await new Promise((resolve, reject) => {
                getBase64(file.originFileObj, resolve);
            });
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
    };

    // Custom upload logic using axios and FormData
    const handleCustomUpload = async (options) => {
        const { file, onSuccess, onError } = options;

        const formData = new FormData();
        formData.append('file', file);

        try {
            setLoading(true)
            // Use the custom postDataRequest method for upload
            const response = await postDataRequest(UPLOAD_PUBLIC_URL, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data', // Ensure the correct header for file upload
                },
            });

            // Check the response and use the fileUrl from the response
            if (response.fileUrl) {
                onSuccess(response);

                if (size === 1) {
                    setFileList({
                        uid: file.uid,
                        name: file.name,
                        status: 'done',
                        url: response.fileUrl, // Use the fileUrl from the response
                    });
                } else {
                    setFileList((prev) => [
                        ...prev,
                        {
                            uid: file.uid,
                            name: file.name,
                            status: 'done',
                            url: response.fileUrl, // Use the fileUrl from the response
                        },
                    ]);
                }
                setLoading(false)

            } else {
                setLoading(false)
                throw new Error('Invalid response structure');
            }
        } catch (error) {
            setLoading(false)
            onError(error);
            message.error('Upload failed. Please try again.');
        }
    };


    // Handle file removal
    const handleRemove = (file) => {
        const updatedFileList = fileList.filter((item) => item.uid !== file.uid);
        setFileList(updatedFileList);
    };

    // Handle change for fileList
    const handleChange = (info) => {
        if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    };

    // Change cover handler
    const handleChangeCover = () => {
        uploadRef.current.click();
    };

    const uploadButton = (
        <button
            style={{
                border: 0,
                background: 'none',
                display: fileList.length >= size ? 'none' : 'block',
            }}
            ref={uploadRef}
            type="button"
            className={fileList.length >= size ? '' : 'uploadCover'}
        >
            <EmptyImageSvg />
            {!hideuploadavatarTitle ? <div style={{ marginTop: 8 }}>
                {isRequire && <span className="span-danger">*</span>}
                <span className='avatar-upload'> {uploadavatarTitle ? uploadavatarTitle : t('Upload avatar')}</span>
            </div> : ""}
        </button>
    );

    return (
        <>
            <Spin spinning={loading}>
                <div className={`${classNameDiv}`}>
                <Upload
                    customRequest={handleCustomUpload}
                    listType="picture-card"
                    fileList={fileList}
                    onPreview={handlePreview}
                    beforeUpload={beforeUpload}
                    onChange={handleChange}
                    onRemove={handleRemove}
                    className={`uploadCoverCus ${className ? className : ""} ${fileList.length >= size ? 'uploadCoverCusNone' : ''} ${isError ? "uploadCoverError" : ""}`}
                >
                    {uploadButton}
                </Upload>
                {fileList.length >= 1 && (
                    !hideAvatarTitle ? <div className="mt-3 text-center d-flex align-items-center">
                        <ChangeCoverSvg className="me-3" />
                        <a
                            href="#!"
                            onClick={handleChangeCover}
                            style={{ marginLeft: '10px' }}
                        >
                            <span className="span-danger">*</span> {changeAvatarTitle ? changeAvatarTitle : t('Change avatar')}
                        </a>
                    </div> : ""
                )}
                {previewImage && (
                    <Image
                        wrapperStyle={{
                            display: 'none',
                        }}
                        preview={{
                            visible: previewOpen,
                            onVisibleChange: (visible) => setPreviewOpen(visible),
                            afterOpenChange: (visible) =>
                                !visible && setPreviewImage(''),
                        }}
                        src={previewImage}
                    />
                )}
                </div>
            </Spin>
        </>
    );
};

export default UploadComponent;
