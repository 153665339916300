import * as React from 'react';

const EditSvg = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.8">
                <path d="M3.75 13.5H6.93C7.0287 13.5006 7.12655 13.4817 7.21793 13.4443C7.30931 13.407 7.39242 13.352 7.4625 13.2825L12.6525 8.08502L14.7825 6.00002C14.8528 5.93029 14.9086 5.84734 14.9467 5.75595C14.9847 5.66455 15.0043 5.56652 15.0043 5.46752C15.0043 5.36851 14.9847 5.27048 14.9467 5.17908C14.9086 5.08769 14.8528 5.00474 14.7825 4.93502L11.6025 1.71752C11.5328 1.64722 11.4498 1.59142 11.3584 1.55335C11.267 1.51527 11.169 1.49567 11.07 1.49567C10.971 1.49567 10.873 1.51527 10.7816 1.55335C10.6902 1.59142 10.6072 1.64722 10.5375 1.71752L8.4225 3.84002L3.2175 9.03752C3.14799 9.1076 3.093 9.19071 3.05567 9.28209C3.01835 9.37346 2.99943 9.47131 3 9.57002V12.75C3 12.9489 3.07902 13.1397 3.21967 13.2803C3.36032 13.421 3.55109 13.5 3.75 13.5ZM11.07 3.30752L13.1925 5.43002L12.1275 6.49502L10.005 4.37252L11.07 3.30752ZM4.5 9.87752L8.9475 5.43002L11.07 7.55252L6.6225 12H4.5V9.87752ZM15.75 15H2.25C2.05109 15 1.86032 15.079 1.71967 15.2197C1.57902 15.3603 1.5 15.5511 1.5 15.75C1.5 15.9489 1.57902 16.1397 1.71967 16.2803C1.86032 16.421 2.05109 16.5 2.25 16.5H15.75C15.9489 16.5 16.1397 16.421 16.2803 16.2803C16.421 16.1397 16.5 15.9489 16.5 15.75C16.5 15.5511 16.421 15.3603 16.2803 15.2197C16.1397 15.079 15.9489 15 15.75 15Z" fill="#636E88" />
            </g>
        </svg>
    );
}

export default EditSvg;