import React, { useEffect, useRef, useState } from 'react';
import './style.css';
import { Button, Col, Input, message, Row, Select, Spin, } from 'antd';
import InputComponent from '../../components/input/InputComponent';
import DateComponent from '../../components/date/DateComponent';
import SelectComponent from '../../components/select/SelectComponent';
import ButtontSubmitComponent from '../../components/buttonAnt/ButtontSubmitComponent';
import InputMask from 'react-input-mask';
import { MEMBER_URL } from '../../api/URLs';
import MessageConfirmModal from '../../components/messageModal/MessageConfirmModal';
import { useLocation, useNavigate } from 'react-router-dom';
import { getDataRequest, postDataRequest, putDataRequest } from '../../api/serviceMethods';

function MemberCreatePage() {
  const { TextArea } = Input;
  const [isSubmit, setIsSubmit] = useState(false)
  const [loading, setLoading] = useState(false);
  const [isChange, setIsChange] = useState(false);
  const messageModalRef = useRef("");
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');
  const genderData = [
    {
      label: 'Male',
      value: 'male',
    },
    {
      label: 'Female',
      value: 'female',
    },
  ]

  const [dataObject, setDataObject] = useState({
    firstName: '',
    lastName: '',
    gender: '',
    date: '',
    phone: {
      countryCode: "852",
      number: ""
    },
    email: '',
    description: ''
  });

  const checkError = () => {
    if (!dataObject.firstName || !dataObject.lastName || !dataObject.gender || !dataObject.date || !dataObject.phone.countryCode || !dataObject.phone.number || !dataObject.email || !dataObject.description) {
      return true
    }
    return false
  }

  const phoneData = [
    {
      label: "+852",
      value: "852"
    },
    {
      label: "+86",
      value: "86"
    }
  ]

  const addNewValue = async (data) => {
    setDataObject({
      firstName: data.firstName,
      lastName: data.lastName,
      gender: data.gender,
      date: data.dob,
      phone: {
        countryCode: `${data.phone.countryCode}`,
        number: data.phone.number,
      },
      email: data.email,
      description: data.bio
    })
  }

  // get member Id
  const getMemberById = async () => {
    try {
      setLoading(true);
      await getDataRequest(`${MEMBER_URL}/${id && id}`)
        .then((res) => {
          if (res.status === 200 && res.data) {
            addNewValue(res.data)
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
      message.error('Server Error!');
    }
  }
  useEffect(() => {
    if (id && id) { getMemberById() }
  }, []);

  // update Member
  const hadleUpdateMember = async (e) => {
    e.preventDefault();
    setIsSubmit(true);
    try {
      const memberPayload = {
        phone: {
          countryCode: dataObject && dataObject.phone && dataObject.phone.countryCode,
          number: isChange ? dataObject?.phone?.number.replace(/[^0-9]/g, '') || '' : dataObject?.phone?.number || '',
        },
        email: dataObject.email,
        firstName: dataObject.firstName,
        lastName: dataObject.lastName,
        dob: dataObject.date,
        gender: dataObject.gender,
        bio: dataObject.description
      }
      if (checkError()) {
        return false;
      }
      setLoading(true);
      await putDataRequest(`${MEMBER_URL}/${id && id} `, memberPayload)
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          messageModalRef.current.showSuccessConfirmsAutoClose(res && res.message, () => { }, "", true);
          setTimeout(() => {
            navigate("/member")
          }, 2000);
        } else {
          messageModalRef.current.showWarningConfirmsAutoClose(res && res.message, () => { }, "", true);
        }
        setLoading(false);
      })
        .catch((err) => {
          setLoading(false);
          messageModalRef.current.showWarningConfirmsAutoClose(err && err.response && err.response.data && err.response.data.message, () => { }, "", true);
        });
    } catch (error) {
      console.log("error", error)
      setLoading(false);
      message.error('Server Error!');
    }
  }
  // Save member
  const handleSubmit = async () => {
    setIsSubmit(true)
    if (checkError()) {
      return false
    }
    setLoading(true);
    const memberPayload = {
      phone: {
        countryCode: dataObject && dataObject.phone && dataObject.phone.countryCode,
        number: dataObject?.phone?.number.replace(/[^0-9]/g, '') || '',
      },
      email: dataObject.email,
      firstName: dataObject.firstName,
      lastName: dataObject.lastName,
      dob: dataObject.date,
      gender: dataObject.gender,
      bio: dataObject.description
    }
    try {
      const res = await postDataRequest(MEMBER_URL, memberPayload);
      if (res?.status === 200) {
        messageModalRef.current.showSuccessConfirmsAutoClose(res.data?.message || 'Saved Successfully', () => { }, "", true);
        setTimeout(() => {
          navigate("/member")
        }, 2000);
      } else {
        messageModalRef.current.showWarningConfirmsAutoClose(res.data?.message || res.message || 'An error occurred.', () => { }, "", true);
      }
    } catch (err) {
      messageModalRef.current.showWarningConfirmsAutoClose(err && err.response && err.response.data && err.response.data.message, () => { }, "", true);
    } finally {
      setLoading(false);
      setIsSubmit(false);
    }
  }

  return (
    <div className="container box-shadow h-100 mymain"
      style={{
        // background: "0% 0% no-repeat padding-box padding-box rgb(240, 242, 245)",
        padding: "32px",
        // overflow: "auto"
      }}
    >
      {/* <div className="w-100">
        <h2 className="text-start">{id && id ? "Edit Member Management" : "Create Member Management"}</h2>
      </div> */}
      <Spin spinning={loading}>
        <div className="w-100 bluediv">
          <div className="w-100 h-100 p-2 pt-3 ps-5 pe-5 d-flex flex-column justify-content-between" style={{ background: "#fff", borderRadius: "4px" }}>
            <div className='w-100'>
              <Row className='mt-2 d-flex align-items-center'>
                <Col span={6}>
                  <div>
                    <label htmlFor=''><span className='text-danger'>*</span>First Name: </label>
                  </div>
                </Col>
                <Col span={12}>
                  <InputComponent status={isSubmit && !dataObject.firstName && `error`} value={dataObject.firstName} setValue={(value) => setDataObject({ ...dataObject, firstName: value })} placeholder={`Enter first name`} />
                </Col>
              </Row>
              <Row className='mt-3 d-flex align-items-center'>
                <Col span={6}>
                  <div>
                    <label htmlFor=''><span className='text-danger'>*</span>Last Name: </label>
                  </div>
                </Col>
                <Col span={12}>
                  <InputComponent status={isSubmit && !dataObject.lastName && `error`} value={dataObject.lastName} setValue={(value) => setDataObject({ ...dataObject, lastName: value })} placeholder={`Enter last name`} />
                </Col>
              </Row>
              <Row className='mt-3 d-flex align-items-center'>
                <Col span={6}>
                  <div>
                    <label htmlFor=''><span className='text-danger'>*</span>Gender: </label>
                  </div>
                </Col>
                <Col span={12}>
                  <SelectComponent status={isSubmit && !dataObject.gender && `error`} options={genderData} value={dataObject.gender} setValue={(value) => setDataObject({ ...dataObject, gender: value })} placeholder={`Select gender`} />
                </Col>
              </Row>
              <Row className='mt-3 d-flex align-items-center'>
                <Col span={6}>
                  <div>
                    <label htmlFor=''><span className='text-danger'>*</span>Date of Birth: </label>
                  </div>
                </Col>
                <Col span={12}>
                  <DateComponent width="100%" status={isSubmit && !dataObject.date && `error`} value={dataObject.date ? dataObject.date : null} setValue={(value) => setDataObject({ ...dataObject, date: value })} />
                </Col>
              </Row>
              <Row className='mt-3 d-flex align-items-center'>
                <Col span={6}>
                  <div>
                    <label htmlFor=''><span className='text-danger'>*</span>Phone</label>
                  </div>
                </Col>
                <Col span={12}>
                  <Select
                    className="selectCus"
                    value={dataObject.phone.countryCode}
                    size="large"
                    style={{
                      width: 100,
                    }}
                    onChange={(value) => setDataObject({
                      ...dataObject,
                      phone: {
                        ...dataObject.phone,
                        countryCode: value,
                      },
                    })}
                    options={phoneData}
                  />
                  <InputMask
                    key={dataObject.phone.countryCode}
                    value={dataObject.phone.number}
                    onChange={(event) => {
                      setDataObject({
                        ...dataObject,
                        phone: {
                          ...dataObject.phone,
                          number: event.target.value,
                        }
                      });
                      setIsChange(true);
                    }}
                    className={`inputCustomPho ${isSubmit && !dataObject.phone.number ? 'input-error-cus' : ''}`}
                    mask={dataObject.phone.countryCode === "852" ? "9999-9999" : "999-9999-9999"}
                  />
                </Col>
              </Row>
              <Row className='mt-3 d-flex align-items-center'>
                <Col span={6}>
                  <div>
                    <label htmlFor=''><span className='text-danger'>*</span>Email: </label>
                  </div>
                </Col>
                <Col span={12}>
                  <InputComponent type="email" status={isSubmit && !dataObject.email && `error`} value={dataObject.email} setValue={(value) => setDataObject({ ...dataObject, email: value })} placeholder={`Enter email`} />
                </Col>
              </Row>
              <Row className='mt-3 d-flex'>
                <Col span={6}>
                  <div>
                    <label htmlFor=''><span className='text-danger'>*</span>Description: </label>
                  </div>
                </Col>
                <Col span={12} className='mb-3'>
                  <TextArea
                    value={dataObject.description}
                    status={isSubmit && !dataObject.description && `error`}
                    onChange={(e) => {
                      setDataObject({ ...dataObject, description: e.target.value })
                    }}
                    placeholder="Enter description"
                    autoSize={{
                      minRows: 5,
                      maxRows: 7,
                    }}
                  />
                </Col>
              </Row>
            </div>
            <Row className='mt-2'>
              <Col span={24}>
                <div className='text-center pb-3'>
                  <Button
                    type=""
                    size="large"
                    style={{ minWidth: "8rem", paddingLeft: "5px", paddingRight: "5px" }}
                    className={`me-5`}
                    onClick={() => {
                      navigate(`/member`) 
                    }}
                  >Back</Button>
                  <ButtontSubmitComponent handle={id && id ? hadleUpdateMember : handleSubmit} title={`Submit`} />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Spin>
      <MessageConfirmModal textCentered ref={messageModalRef} />
    </div>
  );
}

export default MemberCreatePage;
