import { Row } from "antd";
import { useTranslation } from "react-i18next";
import { permissionsHave } from "../../utils/permissionsHave";

const PermissionTextComponent = ({haveAccess}) => {
    const {t} = useTranslation()
    // let haveAccess = ["用户管理", "收款管理", "杂志管理", "资讯管理", "账户管理"]
    const permissions = permissionsHave()
    const checkAcess = (access) => {
        let findAccess = permissions.find(it => it.value === access)
        return findAccess && findAccess.label ? findAccess.label : access
    }

    return <Row >
        {
            haveAccess && haveAccess.length ? haveAccess.map((it, i) => (
                <div className="mb-1 pe-1"><div className="textPermission">{t(checkAcess(it))}</div></div>
            )) : ''
        }
    </Row>
}
export default PermissionTextComponent;