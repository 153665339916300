import { Row } from "antd";

const TagTextComponent = ({textData=[], classNameTag}) => {
    // textData=['Tag1, Tag2]

    return <Row >
        {
            textData && textData.length ? textData.map((it, i) => (
                <div className="mb-1 pe-1"><div className={`textTag ${classNameTag ? classNameTag : ""}`}>{it}</div></div>
            )) : ''
        }
    </Row>
}
export default TagTextComponent;