import { useState } from "react";
import FormsPreviewPhoneComponent from "./FormsPreviewPhoneComponent";
import FormsPreviewPhoneTextDragComponent from "./FormsPreviewPhoneTextDragComponent";

const MobileViewTextDragComponent = ({data, contentDroppableAreaRender}) => {
    const [dataItem, setDataItem] = useState({
        // _id: "",
        scanCount: 0,
        submissionCount: 0,
        eventName: "",
        eventNameES: "",
        eventNumber: "",
        eventCenter: "",
        startDate: null,
        endDate: null,
        description: ``,
        eventDescriptionES: '',
        images: [
            // {
            //     "size_type": "bytes",
            //     "size_img": 19383,
            //     "size": 19383,
            //     "name": "DonationTips.png",
            //     url: "https://store.storeimages.cdn-apple.com/4668/as-images.apple.com/is/MTJV3?wid=1144&hei=1144&fmt=jpeg&qlt=90&.v=1694014871985",
            // },
        ],
        formLink: `${`API_ENDPOINT_CSL_PLASMA`}/ShareFormPage`
    })
    let tabPosition = `mobile`


    return<>
        <div className="h-100- mt-3 mb-3 div-create-preview" style={{ maxWidth: "55rem", margin: "0px auto" }} >
            {tabPosition == "mobile" ? <div className="row mt-3">
                <div className='col-sm-12 col-md-12 col-lg-12'>
                    <div className="w-100 m-auto"
                        style={{ maxWidth: "55rem" }}
                    // style={{maxWidth: "1920px", maxHeight: "34rem"}}
                    >
                        <FormsPreviewPhoneTextDragComponent
                            data={data ? data : null}
                            isDownloadNo={true}
                            isPrintNo={true}
                            contentDroppableAreaRender={contentDroppableAreaRender}
                        />
                    </div>
                </div>
            </div> : ""}
        </div>
    </>
}
export default MobileViewTextDragComponent;