import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Col, DatePicker, Input, message, Row, Select, Spin, Switch } from 'antd';
import MessageConfirmModal from '../../components/messageModal/MessageConfirmModal';
import { useNavigate, useParams } from 'react-router-dom';
import UploadComponent from '../upload/UploadComponent';
import DragUploadPrivateComponentPdf from '../upload/DragUploadPrivateComponentPdf';
import { getDataRequest, postDataRequest, putDataRequest } from '../../api/serviceMethods';
import { MAGAZINES_URL, TAGS_URL } from '../../api/URLs';
import HeaderMagazineComponent from '../header/HeaderMagazineComponent';
import dayjs from 'dayjs';
import _ from 'lodash';

function MagazineUpload() {
    const { t } = useTranslation();
    const messageModalRef = useRef("");
    const navigate = useNavigate();
    const { id } = useParams();
    const [picUrl, setpicUrl] = useState("")
    const [loading, setLoading] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false)
    const [oldValue, setOldValue] = useState({
        title: "",
        status: false,
        tags: [],
        picUrl: "", // cover
        releaseYear: "",
        privateFile: {
            url: "",
            size: "",
            fileName: "",
            page: 0
        }
        //Preview Files is optional
        // "previewFile": {
        //     "url": "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
        //     "size": "12309",
        //     "fileName": "1732153454621-9kb.jpg",
        //     "page": 10
        // }
    });
    const [dataObject, setDataObject] = useState({
        title: "",
        status: false,
        tags: [],
        picUrl: "", // cover
        releaseYear: "",
        privateFile: {
            url: "",
            size: "",
            fileName: "",
            page: 0
        }
        //Preview Files is optional
        // "previewFile": {
        //     "url": "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
        //     "size": "12309",
        //     "fileName": "1732153454621-9kb.jpg",
        //     "page": 10
        // }
    });
    const [privateFile, setPrivateFiles] = useState([]);

    const [tag, setTags] = useState([]);

    const fetchTags = async () => {
        return await getDataRequest(`${TAGS_URL}`, {}).then((res) => {
            if (res.status === 200) {
                let datas = res?.data ? res?.data.map((it, i) => ({
                    ...it,
                    name: it.name || "",
                    label: it.name || "",
                    value: it._id + "/" + it.name || ""
                })) : []
                setTags(datas);
            }
        })
    }

    const addNewValue = async (data) => {
        setpicUrl(data.picUrl);
        let objectValue = {
            ...dataObject,
            title: data.title,
            status: data.status,
            tags: data.tags.map(i => i._id + "/" + i.name),
            picUrl: data.picUrl,
            releaseYear: data.releaseYear,
            privateFile: {
                url: data.privateFile?.url,
                size: data.privateFile?.size,
                fileName: data.privateFile?.fileName,
                page: data.privateFile?.page
            }
            //Preview Files is optional
            // "previewFile": {
            //     "url": "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
            //     "size": "12309",
            //     "fileName": "1732153454621-9kb.jpg",
            //     "page": 10
            // }
        };

        setDataObject({
            ...objectValue,
            tags: data.tags.map(i => i._id + "/" + i.name),
        })

        setOldValue({
            ...objectValue,
            tags: data.tags.map(i => i._id),
        });

        setPrivateFiles([{
            "url": data.privateFile?.url ?? "",
            "size": data.privateFile?.size ?? "",
            "name": data.privateFile?.fileName ?? "",
            "uid": data.privateFile?.uploadedTS,
            "page": data.privateFile?.page ?? undefined
        }]);
    }

    const getDetailById = async () => {
        if (!id) { return; }
        return await getDataRequest(`${MAGAZINES_URL}/${id && id}`)
            .then((res) => {
                if (res.status === 200) {
                    addNewValue(res.data)
                }
            })
    }

    const getDataRequestAll = async (value) => {
        try {
            setLoading(true);
            await Promise.all([fetchTags(), getDetailById()]);
        } catch (error) {
            setLoading(false);
            message.error('Server Error!');
        } finally {
            setLoading(false);
        }
    }
    useEffect(() => {
        getDataRequestAll()
    }, [])

    const checkError = () => {
        if (
            !dataObject.picUrl ||
            !dataObject.privateFile.url ||
            !dataObject.title ||
            !dataObject.releaseYear ||
            dataObject.tags.length === 0
        ) {
            return true
        }
        return false
    }

    //not support array type yet
    const insertChangedValue = (data, oldData) => {
        let changedValue = {};
        Object.keys(data).forEach((key) => {
            if (_.isArray(data[key])) {
                if (_.difference(data[key], oldData[key]).length > 0 || _.difference(oldData[key], data[key]).length > 0) {
                    changedValue[key] = data[key];
                }
            } else if (_.isObject(data[key])) {
                if (Object.keys(insertChangedValue(data[key], oldData[key])).length > 0) {
                    changedValue[key] = data[key];
                }
            } else if (!_.isEqual(data[key], oldData[key])) {
                changedValue[key] = data[key];
            }
        })
        return changedValue;
    }

    const insertParams = () => {
        return {
            "title": dataObject.title,
            "status": dataObject.status,
            "tags": dataObject.tags.map(i => `${i}`.split("/")[0]),
            "picUrl": dataObject.picUrl, // cover
            "releaseYear": dataObject.releaseYear,
            "privateFile": {
                "url": dataObject.privateFile?.url,
                "size": dataObject.privateFile?.size,
                "fileName": dataObject.privateFile?.fileName,
                "page": dataObject.privateFile?.page
            }
            //Preview Files is optional
            // "previewFile": {
            //     "url": "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
            //     "size": "12309",
            //     "fileName": "1732153454621-9kb.jpg",
            //     "page": 10
            // }
        }
    }
    const handleSubmit = async () => {
        setIsSubmit(true)
        if (checkError()) {
            return false
        }
        setIsSubmit(false)
        setLoading(true);
        const params = insertParams()

        try {
            const res = await postDataRequest(MAGAZINES_URL, params);
            if (res?.status === 200) {
                messageModalRef.current.showSuccessConfirmsAutoClose(res.data?.message || t("Saved Successfully"), () => { }, "", true);
                setTimeout(() => {
                    navigate(`/magazine`)
                }, 2000);
            } else {
                messageModalRef.current.showWarningConfirmsAutoClose(res.data?.message || res.message || t("An error occurred."), () => { }, "", true);
            }
        } catch (err) {
            messageModalRef.current.showWarningConfirmsAutoClose(err && err.response && err.response?.data && err.response?.data?.message, () => { }, "", true);
        } finally {
            setLoading(false);
        }
    }

    const hadleUpdate = async () => {
        setIsSubmit(true)
        if (checkError()) {
            return false
        }
        setIsSubmit(false)
        setLoading(true);
        let params = insertParams();
        params = insertChangedValue(params, oldValue);
        try {
            if (!_.isEmpty(params)) {
                const res = await putDataRequest(`${MAGAZINES_URL}/${id}`, params);
                if (res?.status === 200) {
                    messageModalRef.current.showSuccessConfirmsAutoClose(res.data?.message || t("Saved Successfully"), () => { }, "", true);
                    setTimeout(() => {
                        navigate(`/magazine`)
                    }, 2000);
                } else {
                    messageModalRef.current.showWarningConfirmsAutoClose(res.data?.message || res.message || t("An error occurred."), () => { }, "", true);
                }
            } else {
                messageModalRef.current.showSuccessConfirmsAutoClose(t("No changes to Data!"), () => { }, "", true);
                setTimeout(() => {
                    navigate(`/magazine`)
                }, 2000);
            }
        } catch (err) {
            messageModalRef.current.showWarningConfirmsAutoClose(err && err.response && err.response.data && err.response.data.message, () => { }, "", true);
        } finally {
            setLoading(false);
        }
    }

    const handleChangeValue = (e) => {
        setDataObject((v) => ({ ...v, [e.target.name]: e.target.value ?? "" }));
    }

    const handleChangeStatus = (value) => {
        setDataObject((v) => ({ ...v, status: value }));
    }

    const handleSelect = (value) => {
        setDataObject((v) => ({ ...v, tags: [...dataObject.tags, value] }));
    };

    const handleDeselect = (value) => {
        setDataObject((v) => ({ ...v, tags: [...dataObject.tags.filter(i => i !== value)] }));
    }

    const handleChangeDate = (value) => {
        setDataObject((v) => ({ ...v, releaseYear: value !== null ? dayjs(value).format("YYYY") : "" }));
    }

    const handleChangePrivateFile = (value) => {
        setPrivateFiles(value);
        setDataObject((v) => ({
            ...v,
            privateFile: {
                "url": value[0]?.url ?? "",
                "size": value[0]?.size ?? "",
                "fileName": value[0]?.name ?? "",
                "page": value[0]?.page ?? 0
            }
        }));
    }

    const handleChangeCover = (value) => {
        setpicUrl(value.url)
        setDataObject((v) => ({
            ...v,
            picUrl: value.url
        }));
    }


    return (
        <>
            <HeaderMagazineComponent
                backgroundColor="#E50A47"
                border="1px solid #e72158"
                isHide="view"
                onClick={() => navigate('/magazine')}
                backTitle={t("Upload Magazine")}
                hideTitle="userDetails"
                bntTitle1={t("Submit")}
                opacity="0.5px"
                color="#FFFFFF"
                onClick1={() => {
                    !id ? handleSubmit() : hadleUpdate();
                }}
            >
                <Spin spinning={loading} fullscreen></Spin>
                <div className='container newDetail'>
                    <Row>
                        <Col md={5}>
                            <UploadComponent
                                fileList={picUrl ? [{
                                    "uid": "1",
                                    "name": "",
                                    "status": "done",
                                    "url": picUrl
                                }] : []}
                                setFileList={handleChangeCover}
                            />
                        </Col>
                        <Col md={19}>
                            <Row>
                                <Card className='w-100'>
                                    <DragUploadPrivateComponentPdf
                                        privateFile={true}
                                        previewUrl={picUrl}
                                        fileList={privateFile}
                                        setFileList={handleChangePrivateFile}
                                    />
                                    <Row align="middle" className='mt-3'>
                                        <Col md={6} span={5} className='userLabel'>{t('Magazine Name')}:</Col>
                                        <Col md={18} className="text-capitalize">
                                            <Input
                                                value={dataObject.title}
                                                placeholder=""
                                                size="large"
                                                name='title'
                                                status={isSubmit && !dataObject.title && `error`}
                                                onChange={handleChangeValue}
                                                style={{
                                                    width: "100%"
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row align="middle" className='mt-3'>
                                        <Col md={6} span={5} className='userLabel'>{t('Magazine Year')}:</Col>
                                        <Col md={18} className="text-capitalize">
                                            <DatePicker
                                                placeholder=''
                                                picker="year"
                                                onChange={handleChangeDate}
                                                format="YYYY年"
                                                value={dataObject.releaseYear ? dayjs().year(dataObject.releaseYear) : null}
                                                status={isSubmit && !dataObject.releaseYear && `error`}
                                                size="large"
                                                style={{ width: "50%" }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row align="middle" className='mt-3'>
                                        <Col md={6} span={5} className='userLabel'>{t('Label')}:</Col>
                                        <Col md={18} className="text-capitalize">
                                            <Select
                                                className='multipleCus'
                                                mode="multiple"
                                                size="large"
                                                status={isSubmit && dataObject.tags.length === 0 && `error`}
                                                placeholder=""
                                                onDeselect={handleDeselect}
                                                onSelect={handleSelect}
                                                style={{
                                                    width: '100%',
                                                }}
                                                options={tag}
                                                value={dataObject.tags}
                                            />
                                        </Col>
                                    </Row>
                                    <Row align="middle" className='mt-3'>
                                        <Col md={6} span={5} className='userLabel'>{t('Status')}:</Col>
                                        <Col md={18} className="text-capitalize">
                                            <Switch
                                                onChange={handleChangeStatus}
                                                value={dataObject.status}
                                            />
                                        </Col>
                                    </Row>
                                </Card>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </HeaderMagazineComponent>
            <MessageConfirmModal textCentered ref={messageModalRef} />
        </>
    );
}

export default MagazineUpload;
