import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MEMBER_REPORT_URL } from "../../api/URLs";
import { getDataRequest } from "../../api/serviceMethods";

const DashboardComponent = () => {
    const { t } = useTranslation();
    const [dashboardMemberData, setDashboardMemberData] = useState(null);
const getDashboard = async () => {
    try {
      const res = await getDataRequest(`${MEMBER_REPORT_URL}`);
      if (res.status === 200) {
        setDashboardMemberData(res.data);
      }
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
    }
  };

  useEffect(() => {
    getDashboard();
  }, [])
    const dashboardData = [
        {
            title: t("Total Users"),
            total: dashboardMemberData && dashboardMemberData.total,
            rate: ""
        },
        {
            title: t("Paid Membership"),
            total: dashboardMemberData && dashboardMemberData.pro,
            rate: ""
        },
        {
            title: t("New Users Per Day"),
            total: dashboardMemberData && dashboardMemberData.today,
            rate: ""
        },
        {
            title: t("Member Conversion Rate"),
            total: dashboardMemberData && dashboardMemberData.proRate + "%",
            rate: ""
        }
    ]
    return (
        <Row >
            {
                dashboardData.map((item, ind) => (
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <div className="d-flex" style={{ padding: "1.5rem 3rem", justifyContent: "space-between" }}>
                            <Row
                                className="text-center"
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-between",
                                    alignItems: "flex-start"
                                }}
                            >
                                <Col className="titleCardNew14400">{item.title}</Col>
                                <Col className="totalCard28500">{item.total}</Col>
                            </Row>
                            {
                                item.title === "Membership Rate" || item.title === "会员转化率" ? "" :
                                    <div className="vr" style={{ height: "70px", borderLeft: "1px solid #000" }}></div>
                            }
                        </div>
                    </Col>
                ))
            }
        </Row>
    );
};

export default DashboardComponent;
