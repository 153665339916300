import React from 'react'
import './NotFoundPage.css'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { menus } from '../../components/navigations/menu';

const NotFoundPage = () => {
    const user = useSelector((state) => state.authenticatedUser);
    const navigate = useNavigate()
    return (
        <div className='notfound_body'>
            <div className='notfound_content'>
            <h1 style={{fontSize: '11rem', fontWeight: '900', color: '#3f86c7'}}>404</h1>
                <div className='text_content container'>
                    <p id='pagenotfound'>Page not found</p>
                    <p id='sorrynotfound'>Sorry, we can't find the page that you are looking for...</p>
                    <button className='btnReturn' onClick={() => {
                        let menusAccess = user ? menus(user) : null
                        if (menusAccess && menusAccess[0] && menusAccess[0].pathname) {
                          navigate(menusAccess[0].pathname);
                        } else {
                          navigate('/');
                        }
                    }} >
                        Return to Homepage
                    </button>
                </div>
            </div>
        </div>
    )
}

export default NotFoundPage