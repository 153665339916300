import {
  Affix,
  Alert,
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  Dropdown,
  Menu,
  Modal,
  Popconfirm,
  Result,
  Row,
  Tabs,
  Tag,
  Tooltip,
  Tree,
  Typography,
  Upload,
} from 'antd';
import { AddBox, ChangeCircle, DeleteSweep, UploadFile } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { FloatingLabel, Form } from 'react-bootstrap';
import JSONInput from 'react-json-editor-ajrm';
import esiApi from '../../../../api/esiApi';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { BsCardImage, BsCardText, BsFillMenuButtonFill } from 'react-icons/bs';
import { MdErrorOutline, MdFormatListBulleted } from 'react-icons/md';
import { FaHeading } from 'react-icons/fa';
import { AiFillDatabase, AiOutlineLink } from 'react-icons/ai';
import moment from 'moment/moment';
import { FcTreeStructure } from 'react-icons/fc';
import { BiArrowToTop } from 'react-icons/bi';
import { useNavigate, useParams } from 'react-router-dom';
import Slider from '@mui/material/Slider';
import { useDispatch, useSelector } from 'react-redux';
import { NEWS_POSTS_URL, POST_EXPOSURE_FILTER_URL, UPLOAD_IMAGE_URL } from '../../../../api/URLs';
import { fileToBase64 } from '../../../../share/StringHelper';
import SelectLanguageComponent from '../../../../components/select-language/Selectlanguage.component';
import { languages } from '../../../AddLanguageModal';
import DatePickerWithMoment from '../../../../components/date/DatePickerWithMoment';
import NewPostPreviewPage from '../NewPostPreviewPage';
import { addPost, updatePost } from '../../../../stores/news-posts.store';

const NewsPostCreateUpdatePage = ({ compose, edit, template }) => {
  // console.log(`skhsk-compose, edit, template: `, compose, edit, template)
  const { postId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const newsPosts = useSelector((state) => state.newsPosts);

  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [currentPost, setCurrentPost] = useState({});
  const [disabledDropzone, setDisabledDropzone] = useState(null);
  const [currentEditLanguage, setCurrentEditingLanguage] = useState('');
  const [currentPostSupportLanguages, setCurrentPostSupportLanguages] = useState([]);
  console.log(`skhsk-currentPostSupportLanguages-currentEditLanguage-${currentEditLanguage}: `, currentPostSupportLanguages)
  console.log(`skhsk-currentPost: `, currentPost)
  const [imagePreviewModal, setImagePreviewModal] = useState(null);
  const [showMarketingAudienceSetting, setShowMarketingAudienceSetting] = useState(false);
  const [locationData, setLocationData] = useState([]);
  const [patientImpact, setPatientImpact] = useState({ amount: 0, show: false, loading: false });
  const [finalConfirm, setFinalConfirm] = useState({ show: false, title: '', message: '' });
  const [resultModal, setResultModal] = useState({ show: false, message: <></> });

  console.log(`skhsk-currentPostSupportLanguages: `, currentPostSupportLanguages)
  console.log(`skhsk-currentPost: `, currentPost)
  const onSupportLanguageChange = (mode, lang) => {
    if (!currentPost.localize) {
      currentPost.localize = {}; // Initialize localize if it's undefined
    }
  
    if (mode === 'select') {
      setCurrentPostSupportLanguages([...currentPostSupportLanguages, lang]);
      setCurrentEditingLanguage(lang);
      currentPost.localize[lang] = {
        layout: [],
        title: '',
        subtitle: '',
      };
    } else {
      const newSupported = currentPostSupportLanguages.filter((sl) => sl !== lang);
      setCurrentPostSupportLanguages(newSupported);
      setCurrentEditingLanguage(newSupported[0]);
      delete currentPost.localize[lang];
    }
  
    setCurrentPost({ ...currentPost });
  };
  

  const createOrUpdate = async () => {
    setSubmitting(true);

    const type = compose || template ? 'create' : 'update';
    const reassignIndex = (arr) => {
      arr.forEach((el, i) => {
        el.index = i;
        if (el.tag === 'accordion') {
          reassignIndex(el.accordion);
        }
      });
    };

    let url = NEWS_POSTS_URL;

    if (type === 'update') {
      url = url + currentPost._id;
    }

    let success = false;
    let message = '';

    try {
      Object.values(currentPost.localize).forEach((content) => {
        reassignIndex(content.layout);
      });
      const response = ""
      console.log(`skhsk-currentPost: `, currentPost)
      return false
      await esiApi.post(url, currentPost);
      if (response.status !== 200) {
        message = `Unable to ${type} post!`;
      } else {
        success = true;
        if (compose || template) {
          message = `Successfully created post! This post is now available for the targeting app users.`;

          setCurrentPost({ ...response.data.data });
          dispatch(addPost(response.data.data));
        } else {
          message = `Successfully updated post!`;

          dispatch(updatePost(currentPost));
        }
      }
    } catch (err) {
      message = `Systematic error happens when ${type} post: ${err.response?.data?.message}`;
    } finally {
      console.log(`skhsk1-finalConfirm: `, finalConfirm)
      setFinalConfirm({ ...finalConfirm, show: false });
      setResultModal({
        show: true,
        message: (
          <Result
            status={success ? 'success' : 'error'}
            title={success ? 'Success' : 'Error'}
            subTitle={message}
            extra={
              success
                ? [
                    <Button
                      type="primary"
                      key="leave"
                      onClick={() => {
                        navigate('/news-posts');
                        setResultModal({ show: false, message: <></> });
                      }}
                    >
                      Go to news posts list
                    </Button>,
                  ]
                : [
                    <Button key="modify" onClick={() => setResultModal({ show: false, message: <></> })}>
                      OK
                    </Button>,
                  ]
            }
          />
        ),
      });

      setLoading(false);
      setSubmitting(false);
    }
  };

  const reorder = (contents, startIndex, endIndex) => {
    const result = Array.from(contents);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const copyMove = (destination, droppableSource, droppableDestination) => {
    const destClone = Array.from(destination);
    const copying = { ...types[droppableSource.index].default };

    destClone.splice(droppableDestination.index, 0, copying);

    return destClone;
  };

  const onDragStart = (start) => {
    setDisabledDropzone(start.source.droppableId);
  };

  const onDragEnd = (result) => {
    setDisabledDropzone(null);
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }
    const sInd = source.droppableId;
    const dInd = destination.droppableId;

    const droppingArea = dInd.split('@')[1];

    if (droppingArea === '') {
      if (sInd === dInd) {
        currentPost.localize[currentEditLanguage].layout = [
          ...reorder(currentPost.localize[currentEditLanguage]?.layout || [], source.index, destination.index),
        ];
      } else {
        currentPost.localize[currentEditLanguage].layout = [
          ...copyMove(currentPost.localize[currentEditLanguage]?.layout || [], source, destination),
        ];
      }
    } else {
      const positionList = droppingArea.split('-');
      let theDroppingArray = currentPost.localize[currentEditLanguage].layout[positionList[0]];
      for (let i = 1; i < positionList.length; ++i) {
        theDroppingArray = theDroppingArray.accordion[positionList[i]];
      }

      if (sInd === dInd) {
        theDroppingArray.accordion = [...reorder(theDroppingArray.accordion, source.index, destination.index)];
      } else {
        theDroppingArray.accordion = [...copyMove(theDroppingArray.accordion, source, destination)];
      }
    }

    setCurrentPost({ ...currentPost });
  };

  const contentCardHeadRender = (content) => {
    let result;
    switch (content.tag.toLowerCase()) {
      case 'bullet':
      case 'body':
        result = (
          <select
            onChange={(e) => {
              content.tag = e.target.value;
              setCurrentPost({ ...currentPost });
            }}
          >
            <option value="bullet" selected={content.tag.toLowerCase() === 'bullet'}>
              Bullet
            </option>
            <option value="body" selected={content.tag.toLowerCase() === 'body'}>
              Body
            </option>
          </select>
        );
        break;
      case 'h1':
      case 'h2':
      case 'h3':
      case 'h4':
      case 'h5':
        result = (
          <select
            onChange={(e) => {
              content.tag = e.target.value;
              setCurrentPost({ ...currentPost });
            }}
          >
            <option value="h1" selected={content.tag.toLowerCase() === 'h1'}>
              H1
            </option>
            <option value="h2" selected={content.tag.toLowerCase() === 'h2'}>
              H2
            </option>
            <option value="h3" selected={content.tag.toLowerCase() === 'h3'}>
              H3
            </option>
            <option value="h4" selected={content.tag.toLowerCase() === 'h4'}>
              H4
            </option>
            <option value="h5" selected={content.tag.toLowerCase() === 'h5'}>
              H5
            </option>
          </select>
        );
        break;
      case 'button':
        result = <span>Button</span>;
        break;
      case 'linkbutton':
        result = <span>Link Button</span>;
        break;
      case 'img':
        result = <span>Image</span>;
        break;
      case 'accordion':
        result = <span>Accordion</span>;
        break;
    }

    return (
      <>
        <span style={{ marginRight: 5, position: 'relative', top: -2 }}>
          {types.find((t) => t.tag === content.tag).icon}
        </span>
        {result}
      </>
    );
  };

  const bulletAndBodyRender = (lang, content, indexList) => (
    <>
      <Row gutter={1}>
        <Col span={6}>
          <FloatingLabel
            controlId={`${lang}-content-fontWeight-${indexList.join('-')}`}
            label={`Font Weight`}
            className="mb-1"
          >
            <Form.Select
              aria-label="fontWeight"
              onChange={(event) => {
                content.fontWeight = event.target.value;
                setCurrentPost({ ...currentPost });
              }}
            >
              <option value={'regular'} selected>
                Regular
              </option>
              <option value={'bold'} selected={'bold' === content.fontWeight}>
                Bold
              </option>
            </Form.Select>
          </FloatingLabel>
        </Col>
        <Col span={6}>
          <FloatingLabel controlId={`${lang}-content-size-${indexList.join('-')}`} label={`Size`} className="mb-1">
            <Form.Select
              aria-label="Small"
              onChange={(event) => {
                content.size = event.target.value;
                setCurrentPost({ ...currentPost });
              }}
            >
              <option value={'regular'}>Regular</option>
              <option value={'small'} selected={'small' === content.size}>
                Small
              </option>
              <option value={'xsmall'} selected={'xsmall' === content.size}>
                xSmall
              </option>
            </Form.Select>
          </FloatingLabel>
        </Col>
        <Col span={6}>
          <FloatingLabel
            controlId={`${lang}-content-textDecoration-${indexList.join('-')}`}
            label={`Text Decoration`}
            className="mb-1"
          >
            <Form.Select
              aria-label="textDecoration"
              onChange={(event) => {
                content.textDecoration = event.target.value;
                setCurrentPost({ ...currentPost });
              }}
            >
              <option value={'none'} selected>
                None
              </option>
              <option value={'underline'} selected={'underline' === content.textDecoration}>
                Underline
              </option>
            </Form.Select>
          </FloatingLabel>
        </Col>
        <Col span={6}>
          <FloatingLabel
            controlId={`${lang}-content-marginBottom-${indexList.join('-')}`}
            label={`Margin Bottom`}
            className="mb-1"
          >
            <Form.Control
              type="number"
              placeholder={`Please set Margin Bottom`}
              value={content.marginBottom || 0}
              onChange={(e) => {
                content.marginBottom = Number(e.target.value);
                setCurrentPost({ ...currentPost });
              }}
            />
          </FloatingLabel>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <FloatingLabel controlId={`${lang}-content-text-${indexList.join('-')}`} label={`Text`} className="mb-1">
            <Form.Control
              type="input"
              as="textarea"
              style={{ maxHeight: '120px', minHeight: '120px' }}
              placeholder={`Please set Text`}
              value={content.text || ''}
              onChange={(e) => {
                content.text = e.target.value;
                setCurrentPost({ ...currentPost });
              }}
            />
          </FloatingLabel>
        </Col>
      </Row>
    </>
  );

  const headingRender = (lang, content, indexList) => (
    <>
      <Row gutter={1}>
        <Col span={8}>
          <FloatingLabel
            controlId={`${lang}-content-textDecoration-${indexList.join('-')}`}
            label={`Text Decoration`}
            className="mb-1"
          >
            <Form.Select
              aria-label="textDecoration"
              onChange={(event) => {
                content.textDecoration = event.target.value;
                setCurrentPost({ ...currentPost });
              }}
            >
              <option value={'none'} selected>
                None
              </option>
              <option value={'underline'} selected={'underline' === content.textDecoration}>
                Underline
              </option>
            </Form.Select>
          </FloatingLabel>
        </Col>
        <Col span={8}>
          <FloatingLabel
            controlId={`${lang}-content-marginBottom-${indexList.join('-')}`}
            label={`Margin Bottom`}
            className="mb-1"
          >
            <Form.Control
              type="number"
              placeholder={`Please set Margin Bottom`}
              value={content.marginBottom || 0}
              onChange={(e) => {
                content.marginBottom = Number(e.target.value);
                setCurrentPost({ ...currentPost });
              }}
            />
          </FloatingLabel>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <FloatingLabel controlId={`${lang}-content-text-${indexList.join('-')}`} label={`Text`} className="mb-1">
            <Form.Control
              type="input"
              as="textarea"
              style={{ maxHeight: '120px', minHeight: '120px' }}
              placeholder={`Please set Text`}
              value={content.text || ''}
              onChange={(e) => {
                content.text = e.target.value;
                setCurrentPost({ ...currentPost });
              }}
            />
          </FloatingLabel>
        </Col>
      </Row>
    </>
  );

  const buttonRender = (lang, content, indexList) => (
    <>
      <Row gutter={1}>
        <Col span={8}>
          <FloatingLabel controlId={`${lang}-content-text-${indexList.join('-')}`} label={`Title`} className="mb-1">
            <Form.Control
              type="input"
              placeholder={`Please set button title`}
              value={content.title || ''}
              onChange={(e) => {
                content.title = e.target.value;
                setCurrentPost({ ...currentPost });
              }}
            />
          </FloatingLabel>
        </Col>
        <Col span={8}>
          <FloatingLabel
            controlId={`${lang}-content-marginBottom-${indexList.join('-')}`}
            label={`Margin Bottom`}
            className="mb-1"
          >
            <Form.Control
              type="number"
              placeholder={`Please set Margin Bottom`}
              value={content.marginBottom || 0}
              onChange={(e) => {
                content.marginBottom = Number(e.target.value);
                setCurrentPost({ ...currentPost });
              }}
            />
          </FloatingLabel>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="d-flex flex-row align-items-center mb-1">
            <span style={{ width: '100px' }}>
              Navigation (JSON):
              <p style={{ fontStyle: 'italic', fontSize: 'small' }}>Ask Developer for the value</p>
            </span>
            <JSONInput
              placeholder={
                typeof content.navigation !== 'object'
                  ? types.find((t) => t.tag === 'button').default.navigation
                  : content.navigation
              }
              theme={'light_mitsuketa_tribute'}
              width={'100%'}
              height="auto"
              style={{
                contentBox: { cursor: 'text' },
                body: { border: '1px lightgrey solid', borderRadius: '4px' },
              }}
              onBlur={(e) => {
                if (!e.error) {
                  content.navigation = e.jsObject;
                  setCurrentPost({ ...currentPost });
                }
              }}
            />
          </div>
        </Col>
      </Row>
    </>
  );

  const linkButtonRender = (lang, content, indexList) => (
    <Row gutter={1}>
      <Col span={8}>
        <FloatingLabel controlId={`${lang}-content-link-${indexList.join('-')}`} label={`Link`} className="mb-1">
          <Form.Control
            type="input"
            placeholder={`Please set link`}
            value={content.link || ''}
            onChange={(e) => {
              content.link = e.target.value;
              setCurrentPost({ ...currentPost });
            }}
          />
        </FloatingLabel>
      </Col>
      <Col span={8}>
        <FloatingLabel controlId={`${lang}-content-title-${indexList.join('-')}`} label={`Title`} className="mb-1">
          <Form.Control
            type="input"
            placeholder={`Please set title`}
            value={content.title || ''}
            onChange={(e) => {
              content.title = e.target.value;
              setCurrentPost({ ...currentPost });
            }}
          />
        </FloatingLabel>
      </Col>
      <Col span={8}>
        <FloatingLabel
          controlId={`${lang}-content-marginBottom-${indexList.join('-')}`}
          label={`Margin Bottom`}
          className="mb-1"
        >
          <Form.Control
            type="number"
            placeholder={`Please set Margin Bottom`}
            value={content.marginBottom || 0}
            onChange={(e) => {
              content.marginBottom = Number(e.target.value);
              setCurrentPost({ ...currentPost });
            }}
          />
        </FloatingLabel>
      </Col>
    </Row>
  );

  const imgRender = (lang, content, indexList) => (
    <Row gutter={1}>
      <Col span={10}>
        <div className="d-flex flex-row align-items-start">
          <span style={{ width: 100 }}>Image: </span>
          <Upload
            action={async (file) => {
              const base64 = await fileToBase64(file);

              const response = await esiApi.post(UPLOAD_IMAGE_URL, {
                folderType: 'news-post-content-image',
                image: base64.split(',')[1],
              });
              if (response.data.result) {
                content.url = response.data.data;
                setCurrentPost({ ...currentPost });
                return response.data.data;
              }
              return '';
            }}
            listType="picture-card"
            maxCount={1}
            fileList={
              content.url
                ? [
                    {
                      uid: '-1',
                      name: content.url,
                      status: 'done',
                      url: content.url,
                    },
                  ]
                : []
            }
            onPreview={() =>
              setImagePreviewModal({
                src: content.url,
                title: 'Content Image',
              })
            }
            onChange={(upload) => {
              if (upload.file.status === 'removed') {
                content.url = '';
                setCurrentPost({ ...currentPost });
              }
            }}
          >
            <div>
              {currentPost.coverimage ? <ChangeCircle /> : <UploadFile />}
              <div style={{ marginTop: 8 }}>{currentPost.url ? 'Replace' : 'Upload'}</div>
            </div>
          </Upload>
        </div>
      </Col>
      <Col span={4}>
        <FloatingLabel controlId={`${lang}-content-ratio-${indexList.join('-')}`} label={`Ratio`} className="mb-1">
          <Form.Control
            type="number"
            placeholder={`Please set ratio`}
            value={content.ratio || ''}
            onChange={(e) => {
              content.ratio = Number(e.target.value);
              setCurrentPost({ ...currentPost });
            }}
          />
        </FloatingLabel>
      </Col>
      <Col span={4}>
        <FloatingLabel
          controlId={`${lang}-content-marginBottom-${indexList.join('-')}`}
          label={`Margin Bottom`}
          className="mb-1"
        >
          <Form.Control
            type="number"
            placeholder={`Please set Margin Bottom`}
            value={content.marginBottom || 0}
            onChange={(e) => {
              content.marginBottom = Number(e.target.value);
              setCurrentPost({ ...currentPost });
            }}
          />
        </FloatingLabel>
      </Col>
      <Col span={6}>
        <FloatingLabel
          controlId={`${lang}-content-imgWidth-${indexList.join('-')}`}
          label={`Image Width (Percentage)`}
          className="mb-1"
        >
          <Form.Control
            type="number"
            placeholder={`Please set image width`}
            value={content.imgwidth || 0}
            onChange={(e) => {
              content.imgwidth =
                Number(e.target.value) < 0 ? 0 : Number(e.target.value) > 100 ? 100 : Number(e.target.value);
              setCurrentPost({ ...currentPost });
            }}
          />
        </FloatingLabel>
      </Col>
    </Row>
  );

  const addToAccordion = (content, type) => {
    const newEl = { ...types.find((t) => t.tag === type).default };
    content.accordion = [...content.accordion, newEl];
    setCurrentPost({ ...currentPost });
  };

  const deleteContent = (parentContents, currentIndex) => {
    parentContents.splice(currentIndex, 1);
    setCurrentPost({ ...currentPost });
  };

  const contentDroppableAreaRender = (lang, contents, indexList) => (
    console.log(`skhsk-old-lang, contents, indexList: `, lang, contents, indexList),
    <Droppable
      key={`${lang}-content-droppable@${indexList.join('-')}`}
      isDropDisabled={
        disabledDropzone === 'types' ? false : disabledDropzone !== `${lang}-content-droppable@${indexList.join('-')}`
      }
      droppableId={`${lang}-content-droppable@${indexList.join('-')}`}
    >
      {(dropProvided, dropSnapshot) => (
        <div
          ref={dropProvided.innerRef}
          style={
            indexList.length
              ? accordionDroppableStyle(dropSnapshot, indexList)
              : contentDroppableStyle(dropSnapshot.isDraggingOver)
          }
          {...dropProvided.droppableProps}
        >
          {contents.map((content, cIndex) => (
            <Draggable
              key={`${lang}-content-draggable@${indexList.join('-')}-${cIndex}`}
              draggableId={`${lang}-content-draggable@${indexList.join('-')}-${cIndex}`}
              index={cIndex}
            >
              {(dragProvided, dragSnapshot) => (
                <div
                  ref={dragProvided.innerRef}
                  {...dragProvided.draggableProps}
                  {...dragProvided.dragHandleProps}
                  style={contentDraggableStyle(dragSnapshot.isDragging, dragProvided.draggableProps.style)}
                >
                  {content.tag.toLowerCase() === 'accordion' ? (
                    <>
                      <Collapse defaultActiveKey={['accordion']}>
                        <Collapse.Panel
                          header={contentCardHeadRender(content)}
                          key="accordion"
                          extra={
                            <Popconfirm
                              title={`Delete this accordion and all its children?`}
                              onConfirm={() => deleteContent(contents, cIndex)}
                              onCancel={(e) => e.stopPropagation()}
                            >
                              <Button
                                type="danger"
                                icon={<DeleteSweep />}
                                shape="circle"
                                size="small"
                                onClick={(e) => e.stopPropagation()}
                              />
                            </Popconfirm>
                          }
                        >
                          <Alert
                            message="Accordion will not be able to accept drag and drop from left panel under certain conditions. When it happens, please use the button on the bottom to add new content to this level of accordion"
                            type="info"
                            style={{ marginBottom: 5 }}
                          />
                          <Row>
                            <Col span={24}>
                              <FloatingLabel
                                controlId={`${lang}-content-text-${indexList.join('-')}`}
                                label={`Header`}
                                className="mb-1"
                              >
                                <Form.Control
                                  type="input"
                                  placeholder={`Please set accordion header`}
                                  value={content.header || ''}
                                  onChange={(e) => {
                                    content.header = e.target.value;
                                    setCurrentPost({ ...currentPost });
                                  }}
                                />
                              </FloatingLabel>
                            </Col>
                          </Row>
                          {types
                            .find((t) => t.tag === content.tag)
                            .render(lang, content.accordion, [...indexList, cIndex])}
                          <Dropdown
                            overlay={() => (
                              <Menu>
                                {types.map((type) => (
                                  <Menu.Item key={type.tag} onClick={({ key }) => addToAccordion(content, key)}>
                                    <div className="d-flex align-items-center justify-content-evenly">
                                      {type.icon}
                                      {type.text}
                                    </div>
                                  </Menu.Item>
                                ))}
                              </Menu>
                            )}
                            trigger={['click']}
                          >
                            <Button
                              className="mt-1"
                              type="primary"
                              size={'small'}
                              icon={<AddBox />}
                              onClick={(e) => e.preventDefault()}
                            >
                              &nbsp;Add New Element Into This Accordion
                            </Button>
                          </Dropdown>
                        </Collapse.Panel>
                      </Collapse>
                    </>
                  ) : (
                    <Card
                      size="small"
                      title={contentCardHeadRender(content)}
                      extra={
                        <Popconfirm
                          title={`Delete this ${content.tag}?`}
                          onConfirm={() => deleteContent(contents, cIndex)}
                        >
                          <Button
                            type="danger"
                            icon={<DeleteSweep />}
                            shape="circle"
                            size="small"
                            onClick={(e) => e.stopPropagation()}
                          />
                        </Popconfirm>
                      }
                    >
                      {types.find((t) => t.tag === content.tag).render(lang, content, [...indexList, cIndex])}
                    </Card>
                  )}
                </div>
              )}
            </Draggable>
          ))}
          {dropProvided.placeholder}
        </div>
      )}
    </Droppable>
  );

  const types = [
    {
      text: 'Body',
      tag: 'body',
      icon: <BsCardText />,
      render: bulletAndBodyRender,
      default: {
        tag: 'body',
        index: -1,
        fontWeight: 'regular',
        size: 'regular',
        textDecoration: 'none',
        marginBottom: 0,
      },
    },
    {
      text: 'Bullet',
      tag: 'bullet',
      icon: <MdFormatListBulleted />,
      render: bulletAndBodyRender,
      default: {
        tag: 'bullet',
        index: -1,
        fontWeight: 'regular',
        size: 'regular',
        textDecoration: 'none',
        marginBottom: 0,
      },
    },
    {
      text: 'H1',
      tag: 'h1',
      icon: <FaHeading />,
      render: headingRender,
      default: {
        tag: 'h1',
        index: -1,
        textDecoration: 'none',
        marginBottom: 0,
      },
    },
    {
      text: 'H2',
      tag: 'h2',
      icon: <FaHeading />,
      render: headingRender,
      default: {
        tag: 'h2',
        index: -1,
        textDecoration: 'none',
        marginBottom: 0,
      },
    },
    {
      text: 'H3',
      tag: 'h3',
      icon: <FaHeading />,
      render: headingRender,
      default: {
        tag: 'h3',
        index: -1,
        textDecoration: 'none',
        marginBottom: 0,
      },
    },
    {
      text: 'H4',
      tag: 'h4',
      icon: <FaHeading />,
      render: headingRender,
      default: {
        tag: 'h4',
        index: -1,
        textDecoration: 'none',
        marginBottom: 0,
      },
    },
    {
      text: 'H5',
      tag: 'h5',
      icon: <FaHeading />,
      render: headingRender,
      default: {
        tag: 'h5',
        index: -1,
        textDecoration: 'none',
        marginBottom: 0,
      },
    },
    {
      text: 'Button',
      tag: 'button',
      icon: <BsFillMenuButtonFill />,
      render: buttonRender,
      default: {
        tag: 'button',
        index: -1,
        navigation: {
          routerName: '',
          screenName: '',
          params: {},
        },
        title: '',
        marginBottom: 0,
      },
    },
    {
      text: 'Link Button',
      tag: 'linkbutton',
      icon: <AiOutlineLink />,
      render: linkButtonRender,
      default: {
        tag: 'linkbutton',
        index: -1,
        link: '',
        title: '',
        marginBottom: 0,
      },
    },
    {
      text: 'Image',
      tag: 'img',
      icon: <BsCardImage />,
      render: imgRender,
      default: {
        tag: 'img',
        index: -1,
        url: '',
        ratio: 1,
        marginBottom: 0,
        imgwidth: 100,
      },
    },
    {
      text: 'Accordion',
      tag: 'accordion',
      icon: <AiFillDatabase />,
      render: contentDroppableAreaRender,
      default: {
        tag: 'accordion',
        index: -1,
        header: '',
        accordion: [],
        marginBottom: 0,
      },
    },
  ];

  const typeStaticStyle = {
    userSelect: 'none',
    padding: 16,
    margin: `0 0 8px 0`,
    textAlign: 'center',
    width: '100%',
  };

  const typeDraggingStyle = (isDragging, draggableStyle) => ({
    ...draggableStyle,
    width: isDragging ? '200px' : '100%',
    background: isDragging ? 'lightgreen' : 'white',
    transform: isDragging ? draggableStyle?.transform : 'translate(0px, 0px)',
  });

  const contentDraggableStyle = (isDragging, draggableStyle) => ({
    ...draggableStyle,
    marginBottom: 5,
  });

  const contentDroppableStyle = (isDraggingOver) => ({
    minHeight: '150px',
    padding: '10px',
    border: isDraggingOver ? '4px dashed lightgreen' : 'none',
  });

  const accordionDroppableStyle = (isDraggingOver, indexList) => {
    return {
      minHeight: '50px',
      padding: '8px',
      border: isDraggingOver.isDraggingOver ? '2px dashed lightgreen' : 'none',
      background: indexList.length % 2 !== 0 ? 'rgba(128, 128, 128, 0.08)' : 'rgba(128, 128, 128, 0.24)',
    };
  };

  useEffect(() => {
    if (newsPosts && newsPosts.initialized) {
      if (edit || template) {
        if (!newsPosts && newsPosts.error) {
          const corresponding = newsPosts.data.find((p) => p.newsId === postId);
          const postDeepCopy = JSON.parse(JSON.stringify(corresponding));
          let postData;
          if (template) {
            postData = {
              expiryTS: postDeepCopy.expiryTS,
              coverimage: postDeepCopy.coverimage,
              localize: postDeepCopy.localize,
              audienceFilter: postDeepCopy.audienceFilter,
            };
          } else {
            postData = postDeepCopy;
          }
          setCurrentPost(postData);
          setCurrentPostSupportLanguages(Object.keys(corresponding?.localize || {}));
          setCurrentEditingLanguage(Object.keys(corresponding?.localize || {})[0]);
          if (template) {
            setFinalConfirm({
              ...finalConfirm,
              title: `Create New Post [Based on post id ${corresponding.newsId}]`,
              message: `You are going to create a new post based on an existing post: ${corresponding.newsId}. It will be published and pushed to target patients immediately. If you are ready, click OK to proceed.`,
            });
          } else {
            setFinalConfirm({
              ...finalConfirm,
              title: `Update This Post (id: ${corresponding.newsId})`,
              message: `You are going to update this post's content. If you are ready, click OK to proceed.`,
            });
          }
        }
      } else if (compose) {
        setCurrentPost({
          coverimage: '',
          localize: {},
          audienceFilter: { ageFrom: 0, ageTo: 100, genders: ['Male', 'Female'] },
        });
        setLoading(false);
        setFinalConfirm({
          ...finalConfirm,
          title: 'Create New Post',
          message:
            'You are going to create a new post and it will be published and pushed to target patients immediately. If you are ready, click OK to proceed.',
        });
      }
      setLocationData(newsPosts.locationData);
      setLoading(false);
    }
  }, [newsPosts]);

  return (
    <>
      <Modal
        open={showMarketingAudienceSetting}
        title="Marketing Target Audience Settings"
        onOk={() => setShowMarketingAudienceSetting(false)}
        onCancel={() => setShowMarketingAudienceSetting(false)}
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        <table style={{ width: '100%', marginTop: 40 }}>
          <tbody>
            <tr style={{ height: 40 }}>
              <td width={100}>Age Range:</td>
              <td>
                {/* <Slider
                  disabled={!(compose || template)}
                  getAriaLabel={() => 'Age range'}
                  value={[currentPost.audienceFilter?.ageFrom, currentPost.audienceFilter?.ageTo]}
                  onChange={(event, newValue) => {
                    currentPost.audienceFilter.ageFrom = newValue[0];
                    currentPost.audienceFilter.ageTo = newValue[1];
                    setCurrentPost({ ...currentPost });
                    setPatientImpact({ amount: -1, loading: false, show: false });
                  }}
                  valueLabelDisplay="on"
                /> */}
                <Slider
                  disabled={!(compose || template)}
                  getAriaLabel={() => 'Age range'}
                  value={[
                    currentPost.audienceFilter?.ageFrom ?? 0,  // Set default values if undefined
                    currentPost.audienceFilter?.ageTo ?? 100,
                  ]}
                  onChange={(event, newValue) => {
                    if (!currentPost.audienceFilter) {
                      currentPost.audienceFilter = {}; // Initialize audienceFilter if undefined
                    }
                    currentPost.audienceFilter.ageFrom = newValue[0];
                    currentPost.audienceFilter.ageTo = newValue[1];
                    setCurrentPost({ ...currentPost });
                    setPatientImpact({ amount: -1, loading: false, show: false });
                  }}
                  valueLabelDisplay="on"
                />

              </td>
            </tr>
            <tr style={{ height: 40 }}>
              <td>Gender(s):</td>
              <td>
              <Checkbox.Group
                  options={['Male', 'Female']}
                  disabled={!(compose || template)}
                  value={currentPost.audienceFilter?.genders}
                  onChange={(checkedValues) => {
                    if (!currentPost.audienceFilter) {
                      currentPost.audienceFilter = {}; // Initialize audienceFilter if undefined
                    }
                    currentPost.audienceFilter.genders = checkedValues;
                    setCurrentPost({ ...currentPost });
                    setPatientImpact({ amount: -1, loading: false, show: false });
                  }}
                />

              </td>
            </tr>
            <tr style={{ height: 60 }}>
              <td colSpan={2} style={{ textAlign: 'center' }}>
                {compose || template ? (
                  <>
                    <Button
                      type="dashed"
                      loading={patientImpact.loading}
                      onClick={async () => {
                        setPatientImpact({ ...patientImpact, loading: true });
                        const impactUrl =
                          POST_EXPOSURE_FILTER_URL +
                          `?ageFrom=${currentPost.audienceFilter.ageFrom}&ageTo=${
                            currentPost.audienceFilter.ageTo
                          }&genders=${currentPost.audienceFilter.genders.join(',')}`;
                        try {
                          const impact = await esiApi.get(impactUrl);
                          setPatientImpact({
                            amount: impact.data.data,
                            loading: false,
                            show: true,
                          });
                        } catch (e) {
                          setPatientImpact({ amount: -1, loading: false, show: true });
                        }
                      }}
                    >
                      Click here to see how many patients will be posted
                    </Button>
                    {patientImpact.show ? (
                      <p>
                        This news will be sent to <strong>{patientImpact.amount}</strong> patients
                      </p>
                    ) : null}
                  </>
                ) : (
                  <strong>Target Audience Settings cannot be changed because this post has been created</strong>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </Modal>
      <Modal
        open={!!imagePreviewModal}
        title={imagePreviewModal?.title}
        footer={null}
        onCancel={() => setImagePreviewModal(null)}
      >
        <img alt={imagePreviewModal?.title} style={{ width: '100%' }} src={imagePreviewModal?.src} />
      </Modal>
      <Modal
        open={finalConfirm.show}
        title={finalConfirm.title}
        onOk={() => createOrUpdate()}
        okButtonProps={{
          disabled: submitting,
        }}
        onCancel={() => setFinalConfirm({ ...finalConfirm, show: false })}
      >
        <Typography.Text strong mark>
          {finalConfirm.message}
        </Typography.Text>
      </Modal>

      <Modal open={resultModal.show} closable={false} footer={null}>
        {resultModal.message}
      </Modal>

      {!compose && loading ? (
        <div> loading </div>
      ) : (
        <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
          <Row>
            <Col span={24}>
              <Card className="news-settings">
                <Card.Grid style={{ width: '20%', height: 160, overflow:'auto' }}>
                  <SelectLanguageComponent
                    supportedLanguage={currentPostSupportLanguages}
                    onSupportLanguageChange={onSupportLanguageChange}
                  />
                </Card.Grid>
                <Card.Grid style={{ width: '29%', height: 160 }}>
                  {currentPostSupportLanguages.length !== 0 ? (
                    <>
                      <div className="d-flex flex-row align-items-center mb-1">
                        <span style={{ width: '100px' }}>{languages[currentEditLanguage]} Title: </span>
                          <Form.Control
                            as="input"
                            placeholder="Please give title"
                            value={currentPost.localize?.[currentEditLanguage]?.title || ''}
                            onChange={(e) => {
                              if (!currentPost.localize) {
                                currentPost.localize = {}; // Initialize localize if it's undefined
                              }
                              if (!currentPost.localize[currentEditLanguage]) {
                                currentPost.localize[currentEditLanguage] = {}; // Initialize the language object if undefined
                              }
                              currentPost.localize[currentEditLanguage].title = e.target.value;
                              setCurrentPost({ ...currentPost });
                            }}
                          />

                      </div>
                      <div className="d-flex flex-row align-items-center">
                        <span style={{ width: '100px' }}>{languages[currentEditLanguage]} Subtitle: </span>
                          <Form.Control
                            as="textarea"
                            placeholder="Please give subtitle"
                            value={currentPost.localize?.[currentEditLanguage]?.subtitle || ''}
                            style={{ maxHeight: 80, minHeight: 80 }}
                            onChange={(e) => {
                              if (!currentPost.localize) {
                                currentPost.localize = {}; // Initialize localize if undefined
                              }
                              if (!currentPost.localize[currentEditLanguage]) {
                                currentPost.localize[currentEditLanguage] = {}; // Initialize the language object if undefined
                              }
                              currentPost.localize[currentEditLanguage].subtitle = e.target.value;
                              setCurrentPost({ ...currentPost });
                            }}
                          />

                      </div>
                    </>
                  ) : (
                    <Alert
                      message="Add Supported Language"
                      description="Config Supported Language for further settings"
                      type="warning"
                    />
                  )}
                </Card.Grid>
                <Card.Grid style={{ width: '12%', height: 160 }}>
                  <div>
                    <p>Post Expires At:</p>
                    <DatePickerWithMoment
                      allowClear={false}
                      status={currentPost.expiryTS ? '' : 'error'}
                      defaultValue={currentPost.expiryTS ? moment(currentPost.expiryTS) : null}
                      onChange={(value) => {
                        currentPost.expiryTS = value;
                        setCurrentPost({ ...currentPost });
                      }}
                    />
                  </div>
                </Card.Grid>
                <Card.Grid style={{ width: '19%', height: 160 }}>
                  <div>Cover Image:</div>
                  <div className="d-flex flex-row justify-content-start align-items-center">
                    <Upload
                      action={async (file) => {
                        const base64 = await fileToBase64(file);

                        const response = await esiApi.post(UPLOAD_IMAGE_URL, {
                          folderType: 'news-post-cover-image',
                          image: base64.split(',')[1],
                        });
                        if (response.data.result) {
                          currentPost.coverimage = response.data.data;
                          setCurrentPost({ ...currentPost });
                          return response.data.data;
                        }
                        return '';
                      }}
                      listType="picture-card"
                      maxCount={1}
                      fileList={
                        currentPost.coverimage
                          ? [
                              {
                                uid: '-1',
                                name: currentPost.coverimage,
                                status: 'done',
                                url: currentPost.coverimage,
                              },
                            ]
                          : []
                      }
                      onPreview={() =>
                        setImagePreviewModal({
                          src: currentPost.coverimage,
                          title: 'Cover Image',
                        })
                      }
                      onChange={(upload) => {
                        if (upload.file.status === 'removed') {
                          currentPost.coverimage = '';
                          setCurrentPost({ ...currentPost });
                        }
                      }}
                    >
                      <div>
                        {currentPost.coverimage ? <ChangeCircle /> : <UploadFile />}
                        <div style={{ marginTop: 8 }}>{currentPost.coverimage ? 'Replace' : 'Upload'}</div>
                      </div>
                    </Upload>
                  </div>
                </Card.Grid>
                <Card.Grid
                  style={{ width: '20%', height: 160, cursor: 'pointer' }}
                  onClick={() => setShowMarketingAudienceSetting(true)}
                >
                  <div>
                    <Typography.Title level={5}>Marketing Target Audience</Typography.Title>
                    <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                      <tbody>
                        <tr style={{ borderBottom: '1px solid lightgray', height: 32 }}>
                          <td width={80}>Age Range</td>
                          {currentPost?.audienceFilter?.ageFrom === undefined ||
                          currentPost?.audienceFilter?.ageTo === undefined ? (
                            <td style={{ color: 'red' }}>
                              <Tooltip color={'red'} title="PLEASE SET TARGET AUDIENCE AGE RANGE">
                                <MdErrorOutline style={{ color: 'red' }} />
                              </Tooltip>
                            </td>
                          ) : (
                            <td>
                              From <Tag color="cyan">{currentPost.audienceFilter.ageFrom}</Tag>To{' '}
                              <Tag color="blue">{currentPost.audienceFilter.ageTo}</Tag>
                            </td>
                          )}
                        </tr>
                        <tr style={{ borderBottom: '1px solid lightgray', height: 32 }}>
                          <td>Gender(s)</td>
                          {!currentPost?.audienceFilter?.genders?.length ? (
                            <Tooltip color={'red'} title="PLEASE SET TARGET AUDIENCE GENDER(S)">
                              <MdErrorOutline style={{ color: 'red' }} />
                            </Tooltip>
                          ) : (
                            <td>
                              {currentPost.audienceFilter.genders?.map((gender) => (
                                <Tag color="orange">{gender}</Tag>
                              ))}
                            </td>
                          )}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Card.Grid>
              </Card>
            </Col>
          </Row>
          <Row className="news-posts-page-content-details" align="top" style={{ marginTop: 5 }}>
            <Col span={18}>
              <Row align="top" style={{ marginTop: 5 }}>
                <Col span={4}>
                  <Affix offsetTop={10}>
                    <div className="ant-card ant-card-bordered">
                      <div className="ant-card-head" style={{ padding: 0, textAlign: 'center' }}>
                        <div className="ant-card-head-wrapper">
                          <div className="ant-card-head-title">Drag n Drop To Content</div>
                        </div>
                      </div>
                      <Droppable key={'types'} droppableId={'types'} isDropDisabled={disabledDropzone === 'types'}>
                        {(typesProvided, typesSnapshot) => (
                          <>
                            <div
                              className="ant-card-body"
                              ref={typesProvided.innerRef}
                              style={{ padding: 8 }}
                              {...typesProvided.droppableProps}
                            >
                              {types.map((type, index) => (
                                <Draggable key={type.tag} draggableId={type.tag} index={index}>
                                  {(provided, snapshot) => (
                                    <>
                                      <div
                                        className="ant-card-grid ant-card-grid-hoverable"
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={{
                                          ...typeStaticStyle,
                                          ...typeDraggingStyle(snapshot.isDragging, provided.draggableProps.style),
                                        }}
                                      >
                                        <div className="d-flex align-items-center justify-content-evenly">
                                          {type.icon}
                                          {type.text} skhsk
                                        </div>
                                      </div>
                                      {snapshot.isDragging && (
                                        <div
                                          className="ant-card-grid ant-card-grid-hoverable"
                                          style={{ ...typeStaticStyle }}
                                        >
                                          <div className="d-flex align-items-center justify-content-evenly">
                                            {type.icon}
                                            {type.text}
                                          </div>
                                        </div>
                                      )}
                                    </>
                                  )}
                                </Draggable>
                              ))}
                            </div>
                          </>
                        )}
                      </Droppable>
                    </div>
                  </Affix>
                </Col>
                <Col span={20}>
                  <div
                    style={{
                      background: 'rgba(128, 128, 128, 0.08)',
                      marginLeft: 5,
                      padding: '12px',
                    }}
                  >
                    <Tabs activeKey={currentEditLanguage} onChange={(key) => setCurrentEditingLanguage(key)}>
                        {currentPostSupportLanguages.map((lang) => {
                          if (!currentPost.localize) {
                            currentPost.localize = {};
                          }
                          if (!currentPost.localize[lang]) {
                            currentPost.localize[lang] = { layout: [] };
                          }

                          return (
                            <Tabs.TabPane tab={languages[lang]} key={lang} style={{ padding: '0 10px' }}>
                              <Row>
                                <Col span={20}>
                                skhsk1
                                  {contentDroppableAreaRender(lang, currentPost.localize[lang].layout, [])}
                                </Col>
                                <Col span={4}>
                                  <Affix offsetTop={20}>
                                    <div style={{ cursor: 'pointer' }} onClick={() => window.scrollTo(0, 0)}>
                                      <FcTreeStructure /> Wireframe <BiArrowToTop />
                                    </div>
                                    <Tree
                                      showLine={{ showLeafIcon: false }}
                                      showIcon={true}
                                      showLeafIcon={false}
                                      treeData={(() => {
                                        const generateTree = (elements, indexList) => {
                                          return elements.map((el, i) => ({
                                            title: types.find((type) => type.tag === el.tag).text,
                                            key: `${lang}-content-draggable@${indexList.join('-')}-${i}`,
                                            icon: types.find((type) => type.tag === el.tag).icon,
                                            children: el.accordion ? generateTree(el.accordion, [...indexList, i]) : [],
                                          }));
                                        };

                                        return generateTree(currentPost.localize[lang].layout, []);
                                      })()}
                                      onSelect={(selectedKeys) => {
                                        const el = document.querySelector(
                                          `div[data-rbd-drag-handle-draggable-id="${selectedKeys[0]}"]`
                                        );
                                        el.scrollIntoView({ behavior: 'smooth' });
                                      }}
                                    />
                                  </Affix>
                                </Col>
                              </Row>
                            </Tabs.TabPane>
                          );
                        })}

                    </Tabs>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col span={6}>
              <NewPostPreviewPage currentPost={currentPost} currentLanguage={currentEditLanguage} />
            </Col>
          </Row>
        </DragDropContext>
      )}

      <Affix offsetBottom={50}>
        <Button
          type="primary"
          size={'large'}
          className="float-end"
          shape="round"
          loading={submitting}
          style={{ backgroundColor: compose || template ? '#52c41a' : '#1890ff' }}
          onClick={() => setFinalConfirm({ ...finalConfirm, show: true })}
        >
          {compose || template ? 'Create New Post' : 'Update This Post'}
        </Button>
      </Affix>
    </>
  );
};

export default NewsPostCreateUpdatePage;
