import React from 'react';
import { Spin } from 'antd';

export default function OnLoad() {
    return (
        <div
            role="status"
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flex: 1,
                maxHeight: "80vh"
            }}
        >
            <div className="spinner-border text-primary" role="status">
                <span className="sr-only d-none">Loading...</span>
            </div>
        </div>
    );
}
