import { Avatar, Spin, Typography } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import {
  MdAssignmentInd,
  MdDriveFileRenameOutline,
  MdLanguage,
  MdOutlineDescription,
  MdOutlineEmail,
  MdPhone,
} from 'react-icons/md';
import SettingInput from '../../components/setting/SettingInput';
import './staff.css';
import { STAFF_DETAIL_URL } from '../../api/URLs';
import { getDataRequest } from '../../api/serviceMethods';
import { ImageComponent } from '../../components/image/ImageComponent';
import PermissionTextComponent from './PermissionTextComponent';
import MessageConfirmModal from '../../components/messageModal/MessageConfirmModal';

const StaffDetailsModal = (props) => {
  const { staffId, show, handleClose } = props;
  const [loading, setLoading] = useState(false);
  const [currentStaff, setCurrentStaff] = useState(null);
  const { Text } = Typography;
  const messageModalRef = useRef('')


  useEffect(() => {
    if (!!staffId) {
      (async () => {
        try {
          setLoading(true)
          const staffData = await getDataRequest(`${STAFF_DETAIL_URL}/${staffId && staffId}`)
          setCurrentStaff(staffData);
          setLoading(false);
        } catch (err) {
          setLoading(false);
          messageModalRef.current.showWarningConfirmsAutoClose(`Fail to get employee!`, () => { }, "", true);
          console.log(err);
        }
      })();
    }
  }, [staffId]);

  return (
    <>
      {/* <Spin spinning={loading}> */}
      {currentStaff && (
        <Modal show={!loading && show} onHide={handleClose} size="lg" centered>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <Modal.Title id="staff-modal-title">
              <Row className="justify-content-center">
              <ImageComponent 
              width={"100px"}
              height={"75px"}
              scr={currentStaff && currentStaff.picUrl && currentStaff.picUrl} />
              </Row>
              <Row className="justify-content-center">{currentStaff.name}</Row>
            </Modal.Title>
            <Row className="section-content">
              <Col>
                <div className="staff-detail">
                  <MdDriveFileRenameOutline className="staff-detail-icon" />
                  <SettingInput
                    key={0}
                    label={'Name'}
                    value={currentStaff.name}
                    editingRender={{
                      elementType: 'text',
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row className="section-content">
              <Col>
                <div className="staff-detail">
                  <MdOutlineEmail className="staff-detail-icon" />
                  <SettingInput
                    key={3}
                    label={'Email'}
                    value={currentStaff.email}
                    editingRender={{
                      elementType: 'text',
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row className="section-content">
              <Col>
                <div className="staff-detail">
                  <MdAssignmentInd className="staff-detail-icon" />
                  <SettingInput
                    key={4}
                    label={'Permission'}
                    value={<PermissionTextComponent haveAccess={currentStaff && currentStaff.permissions ? currentStaff.permissions : []} />}
                    editingRender={{
                      elementType: 'text',
                    }}
                  />
                </div>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      )}
      {/* </Spin> */}
      <MessageConfirmModal textCentered ref={messageModalRef} />
    </>
  );
};

export default StaffDetailsModal;
