import * as React from 'react';

const ChangeCoverSvg = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.5 18.9583H7.49999C2.97499 18.9583 1.04166 17.025 1.04166 12.5V7.49996C1.04166 2.97496 2.97499 1.04163 7.49999 1.04163H12.5C17.025 1.04163 18.9583 2.97496 18.9583 7.49996V12.5C18.9583 17.025 17.025 18.9583 12.5 18.9583ZM7.49999 2.29163C3.65832 2.29163 2.29166 3.65829 2.29166 7.49996V12.5C2.29166 16.3416 3.65832 17.7083 7.49999 17.7083H12.5C16.3417 17.7083 17.7083 16.3416 17.7083 12.5V7.49996C17.7083 3.65829 16.3417 2.29163 12.5 2.29163H7.49999Z" fill="#0072F9" />
            <path d="M14.5833 9.99996C14.5833 12.5333 12.5333 14.5833 9.99999 14.5833C7.46666 14.5833 5.92498 12.0333 5.92498 12.0333M5.92498 12.0333H7.99165M5.92498 12.0333V14.325M5.41666 9.99996C5.41666 7.46663 7.44999 5.41663 9.99999 5.41663C13.0583 5.41663 14.5833 7.96662 14.5833 7.96662M14.5833 7.96662V5.67496M14.5833 7.96662H12.55" stroke="#0072F9" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
}

export default ChangeCoverSvg;