import * as React from 'react';

const AddSvg = () => {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 1V11" stroke="#E50A47" stroke-width="1.5" stroke-linecap="round" />
            <path d="M1 6L11 6" stroke="#E50A47" stroke-width="1.5" stroke-linecap="round" />
        </svg>
    );
}

export default AddSvg;