import * as React from 'react';

const HistorySvg = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.8">
                <path d="M14.1667 2.75002C10.9167 0.916687 6.91675 1.50002 4.25008 4.00002V2.50002C4.25008 2.00002 3.91675 1.66669 3.41675 1.66669C2.91675 1.66669 2.58341 2.00002 2.58341 2.50002V6.25002C2.58341 6.75002 2.91675 7.08335 3.41675 7.08335H7.16675C7.66675 7.08335 8.00008 6.75002 8.00008 6.25002C8.00008 5.75002 7.66675 5.41669 7.16675 5.41669H5.16675C6.41675 4.08335 8.16675 3.33335 10.0001 3.33335C13.6667 3.33335 16.6667 6.33335 16.6667 10C16.6667 13.6667 13.6667 16.6667 10.0001 16.6667C6.33342 16.6667 3.33341 13.6667 3.33341 10C3.33341 9.50002 3.00008 9.16669 2.50008 9.16669C2.00008 9.16669 1.66675 9.50002 1.66675 10C1.66675 14.5834 5.41675 18.3334 10.0001 18.3334C13.0001 18.3334 15.7501 16.75 17.2501 14.1667C19.5001 10.1667 18.1668 5.08335 14.1667 2.75002ZM10.0001 6.66669C9.50008 6.66669 9.16675 7.00002 9.16675 7.50002V10C9.16675 10.5 9.50008 10.8334 10.0001 10.8334H11.6667C12.1667 10.8334 12.5001 10.5 12.5001 10C12.5001 9.50002 12.1667 9.16669 11.6667 9.16669H10.8334V7.50002C10.8334 7.00002 10.5001 6.66669 10.0001 6.66669Z" fill="#636E88" />
            </g>
        </svg>

    );
}

export default HistorySvg;