import { Alert, Button, Card, Col, Collapse, Dropdown, Form, Menu, Popconfirm, Row } from "antd";
import LeftDragTextComponent from "./LeftDragTextComponent";
import RightDragTextComponent from "./RightDragTextComponent";
import MobileViewDragComponent from "./MobileViewDragComponent";
import MobileViewTextComponent from "./MobileViewTextComponent";
import MobileViewTextDragComponent from "./MobileViewTextDragComponent";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { AccordionSvg, ButtonSvg, CoverSvg, H1Svg, H2Svg, H3Svg, H4Svg, H5Svg, ImageSvg, LinkSvg, UnorderedListSvg } from '../../assets/images/DragSvg';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { AddBox, DeleteSweep } from "@mui/icons-material";
import { FloatingLabel } from "react-bootstrap";
import { putDataRequest } from "../../api/serviceMethods";
import MessageConfirmModal from "../messageModal/MessageConfirmModal";
import { NEWS_GET_URL } from "../../api/URLs";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const DragViewMobileComponent = ({
  datalocalize, setDatalocalize, loading, setLoading
}, ref) => {
  const { i18n, t } = useTranslation();
  const [currentPostSupportLanguages, setCurrentPostSupportLanguages] = useState(
    {
      "localize": {
        "cn": {
          "layout": [
            {
              "tag": "h1",
              "index": -1,
              "textDecoration": "none",
              "marginBottom": 0,
              "text": "H1 Hello"
            },
            {
              "tag": "h2",
              "index": -1,
              "textDecoration": "none",
              "marginBottom": 0,
              "text": "H2 Hello 2"
            }
          ],
          "title": "",
          "subtitle": ""
        },
        // "ca": {
        //   "layout": [],
        //   "title": "",
        //   "subtitle": ""
        // }
      }
    }
  );
  const [currentEditLanguage, setCurrentEditingLanguage] = useState('cn');
  const [disabledDropzone, setDisabledDropzone] = useState(null);
  const [draggableId ,setDraggableId] = useState("")
  const [activeIndex, setActiveIndex] = useState(null);
  const [activeDroppable, setActiveDroppable] = useState(null);
  const [isSubmit, setIsSubmit] = useState(false)
  const messageModalRef = useRef("");
  const navigate = useNavigate();

  let condeLang = "cn"//i18n.language ? i18n.language : "cn"
  console.log(`skhsk-data-${condeLang}: `, datalocalize)
  let dataLang = datalocalize && datalocalize.localize && datalocalize.localize[condeLang] ? datalocalize.localize[condeLang] : null
  
  // console.log(`skhsk-dataLang: `, dataLang)
  const [currentPost, setCurrentPost] = useState({
    "localize": {
      "cn": {
        "layout": dataLang && dataLang.layout ? dataLang.layout : [],
        // [
        //   {
        //     "tag": "h1",
        //     "index": -1,
        //     "textDecoration": "none",
        //     "marginBottom": 0,
        //     "text": "H1 Hello"
        //   },
        //   {
        //     "tag": "h2",
        //     "index": -1,
        //     "textDecoration": "none",
        //     "marginBottom": 0,
        //     "text": "H2 Hello 2"
        //   }
        // ],
        "title": dataLang && dataLang.title ? dataLang.title : "",
        "subtitle": dataLang && dataLang.subtitle ? dataLang.subtitle : ""
      },
      // "ca": {
      //   "layout": [],
      //   "title": "",
      //   "subtitle": ""
      // }
    }
  });
  // console.log(`skhsk-currentPost: `, currentPost)
  let lang = 'cn'

  useEffect(() => {
    setCurrentPost({
      "localize": {
        "cn": {
          "layout": dataLang && dataLang.layout ? dataLang.layout : [],
          "title": dataLang && dataLang.title ? dataLang.title : "",
          "subtitle": dataLang && dataLang.subtitle ? dataLang.subtitle : ""
        },
      }
    })
  }, [dataLang])
  
  const [previewOpen, setPreviewOpen] = useState(false);
  const handlePreviewImage = async (url) => {
    setPreviewOpen(url);
  };

  const handleItemClick = (index) => {
    setActiveIndex(index); // Set the clicked item's index as active
  };

  const capitalizeFirstLetter = (str) => {
    return str ? str.charAt(0).toUpperCase() + str.slice(1) : 'NA';
  };
  const styleCheck = (it) => {
    let styleNew = {
      textDecoration: it.textDecoration ? it.textDecoration : "",
      marginTop: it.marginTop ? `${it.marginTop}px` : "0px",
      marginBottom: it.marginBottom ? `${it.marginBottom}px` : "0px",
      marginLeft: it.marginLeft ? `${it.marginLeft}px` : "0px",
      marginRight: it.marginRight ?`${it.marginRight}px` : "0px",
      textAlign: it.textAlign ? it.textAlign : "",
      fontStyle: it.italicType ? it.italicType : "",
    }
    return styleNew
  }
  const _RenderPreview = (items) => {
    if (!items) {
      return ''
    }
    let arr = []
    let i = 0
    let item = items ? items : {}
    let result = []
    let tag = item && item.tag ? item.tag : ''
    // console.log(`skhsk-tag: `, tag)
    switch (tag) {
      case `content`:
        result.push(
          // <div style={{ marginBottom: item.marginBottom || 0, ...fontStyle(item, false) }}>{item.text}</div>
          <div className="w-100 content-cus-body"
            style={styleCheck(item)}
          >
            <div style={styleCheck(item)}>{item.text ? item.text : "NA"}</div>
          </div>
        );
        break;
      case `bullet`:
        const lis = [
          <li
          // style={{ marginBottom: item.marginBottom || 0, ...fontStyle(item, false) }}
          >
            {capitalizeFirstLetter(item.text)}
          </li>,
        ];
        while (arr[i + 1]) {
          if (arr[i + 1].tag === 'bullet') {
            ++i;
            lis.push(
              <li
              // style={{ marginBottom: item.marginBottom || 0, ...fontStyle(item, false) }}
              >
                {capitalizeFirstLetter(item.text)}
              </li>
            );
          } else {
            break;
          }
        }
        result.push(<ul style={styleCheck(item)} className="bullet-cus">{lis}</ul>);
        break;
      case `button`:
        result.push(
          <div className="w-10 button-cus" style={styleCheck(item)}>
            {/* <Button style={styleCheck(item)}>{capitalizeFirstLetter(item.text)}</Button> */}
            <Button
              className='b-danger'
              type="primary"
              onClick={() => {

              }}
              style={{
                ...styleCheck(item),
                backgroundColor: "#E50A47",
                border: "1px solid #e72158",
              }}
              danger
            >
              {capitalizeFirstLetter(item.text)}
            </Button>
          </div>
        );
        break;
      case `h1`:
        result.push(
          <div className="w-100 h1-div">
            <h1 className="h1" style={styleCheck(item)}>{capitalizeFirstLetter(item.text)}</h1>
          </div>
        );
        break;
      case `h2`:
        result.push(
          <div className="w-100 h1=2-div">
            <h1 className="h2" style={styleCheck(item)}>{capitalizeFirstLetter(item.text)}</h1>
          </div>
        );
        break;
      case `h3`:
        result.push(
          <div className="w-100 h3-div">
            <h1 className="h3" style={styleCheck(item)}>{capitalizeFirstLetter(item.text)}</h1>
          </div>
        );
        break;
      case `h4`:
        result.push(
          <div className="w-100 h4-div">
            <h1 className="h4" style={styleCheck(item)}>{capitalizeFirstLetter(item.text)}</h1>
          </div>
        );
        break;
      case `h5`:
        result.push(
          <div className="w-100 h5-div">
            <h1 className="h5" style={styleCheck(item)}>{capitalizeFirstLetter(item.text)}</h1>
          </div>
        );
        break;
      case `link`:
        result.push(
          <div className="w-100 link-div" style={styleCheck(item)}>
            <a className="link-mobile" style={styleCheck(item)}>{capitalizeFirstLetter(item.text)}</a>
          </div>
        );
        break;
      case `image`:
        result.push(
          <div className="w-100 image-div" >
            <img
              onClick={() => handlePreviewImage(item.url)}
              src={item.url}
              alt={item.url}
              style={{ ...styleCheck(item), cursor: "pointer" }}
              className="image-mobile"
            />
          </div>
        );
        break;
      case `accordion`:
        const children = _RenderPreview(item.accordion);
        const rgn = Math.random();
        const wrapper = (
          <div className="mock-accordion">
            <div>
              <input type="checkbox" id={'section' + rgn} className="mock-accordion-input" />
              <label htmlFor={'section' + rgn} className="mock-accordion-label">
                {item.text}
              </label>
              <div className="mock-accordion-content">{children}</div>
            </div>
          </div>
        );
        result.push(<div className="w-100">
          <div className="w-100" style={styleCheck(item)}>
            {wrapper}
          </div>
        </div>);
        break;

      default:
        break;
    }
    return result
  }


  const accordionDroppableStyle = (isDraggingOver, indexList) => {
    return {
      minHeight: '50px',
      padding: '8px',
      border: isDraggingOver.isDraggingOver ? '2px dashed lightgreen' : 'none',
      background: indexList.length % 2 !== 0 ? 'rgba(128, 128, 128, 0.08)' : 'rgba(128, 128, 128, 0.24)',
    };
  };
  const contentDroppableStyle = (isDraggingOver) => ({
    minHeight: '150px',
    padding: '10px',
    border: isDraggingOver ? '4px dashed lightgreen' : 'none',
  });
  const contentDraggableStyle = (isDragging, draggableStyle) => ({
    ...draggableStyle,
    marginBottom: 5,
  });

  const contentCardHeadRender = (content) => {
    let result;
    switch (content.tag.toLowerCase()) {
      case 'bullet':
      case 'content':
        result = (
          <select
            onChange={(e) => {
              content.tag = e.target.value;
              setCurrentPost({ ...currentPost });
            }}
          >
            <option value="bullet" selected={content.tag.toLowerCase() === 'bullet'}>
              Bullet
            </option>
            <option value="content" selected={content.tag.toLowerCase() === 'content'}>
              Body
            </option>
          </select>
        );
        break;
      case 'h1':
      case 'h2':
      case 'h3':
      case 'h4':
      case 'h5':
        result = (
          <select
            onChange={(e) => {
              content.tag = e.target.value;
              setCurrentPost({ ...currentPost });
            }}
          >
            <option value="h1" selected={content.tag.toLowerCase() === 'h1'}>
              H1
            </option>
            <option value="h2" selected={content.tag.toLowerCase() === 'h2'}>
              H2
            </option>
            <option value="h3" selected={content.tag.toLowerCase() === 'h3'}>
              H3
            </option>
            <option value="h4" selected={content.tag.toLowerCase() === 'h4'}>
              H4
            </option>
            <option value="h5" selected={content.tag.toLowerCase() === 'h5'}>
              H5
            </option>
          </select>
        );
        break;
      case 'button':
        result = <span>Button</span>;
        break;
      case 'link':
        result = <span>Link Button</span>;
        break;
      case 'img':
        result = <span>Image</span>;
        break;
      case 'image':
        result = <span>Image</span>;
        break;
      case 'accordion':
        result = <span className="w-100 d-block" 
        // style={styleCheck(content)}
        >{content.text || "NA"}</span>;
        break;
    }

    return (
      <>
        {/* <span style={{ marginRight: 5, position: 'relative', top: -2 }}>
          {types.find((t) => t.tag === content.tag).icon}
        </span> */}
        {result}
      </>
    );
  };

  const addToAccordion = (content, type) => {
    const newEl = { ...types.find((t) => t.tag === type).default };
    content.accordion = [...content.accordion, newEl];
    setCurrentPost({ ...currentPost });
  };

  const deleteContent = (parentContents, currentIndex) => {
    parentContents.splice(currentIndex, 1);
    setCurrentPost({ ...currentPost });
  };

  const contentDroppableAreaRender = (lang, contents, indexList) => {
    return (
      <Droppable
      key={`${lang}-content-droppable@${indexList.join('-')}`}
      // isDropDisabled={
      //   disabledDropzone === 'types' ? false : disabledDropzone !== `${lang}-content-droppable@${indexList.join('-')}`
      // }
      droppableId={`${lang}-content-droppable@${indexList.join('-')}`}
    >
      {(dropProvided, dropSnapshot) => (
        <div
          ref={dropProvided.innerRef}
          style={
            indexList.length
              ? accordionDroppableStyle(dropSnapshot, indexList)
              : contentDroppableStyle(dropSnapshot.isDraggingOver)
          }
          {...dropProvided.droppableProps}

          // style={{
          //   padding: 10,
          //   border: dropSnapshot.isDraggingOver
          //     ? "2px solid red"
          //     : activeDroppable === `${lang}-content-droppable@${indexList.join("-")}`
          //     ? "2px solid blue"
          //     : "1px solid gray",
          //   borderRadius: 4,
          //   marginBottom: 10,
          // }}
          // onMouseEnter={() => setActiveDroppable(`${lang}-content-droppable@${indexList.join("-")}`)}
          // onMouseLeave={() => setActiveDroppable(null)}
        >
          {contents.map((content, cIndex) => (
            // console.log(`skhsk-content-${cIndex}: `, content),
            <Draggable
              key={`${lang}-content-draggable@${indexList.join("-")}-${cIndex}`}
              draggableId={`${lang}-content-draggable@${indexList.join("-")}-${cIndex}`}
              index={cIndex}

              // key={`${lang}-content-draggable@${indexList.join('-')}-${cIndex}`}
              // draggableId={`${lang}-content-draggable@${indexList.join('-')}-${cIndex}`}
              // index={cIndex}
            >
              {(dragProvided, dragSnapshot) => (
                <div
                  ref={dragProvided.innerRef}
                  {...dragProvided.draggableProps}
                  {...dragProvided.dragHandleProps}
                  // style={contentDraggableStyle(dragSnapshot.isDragging, dragProvided.draggableProps.style)}
                  // new active
                  // style={{
                  //   padding: 10,
                  //   marginBottom: 10,
                  //   border: activeIndex === cIndex ? "2px solid red" : "1px solid gray",
                  //   borderRadius: 4,
                  //   backgroundColor: dragSnapshot.isDragging ? "#f0f0f0" : "#fff",
                  //   ...dragProvided.draggableProps.style,
                  // }}
                  style={{
                    ...contentDraggableStyle(dragSnapshot.isDragging, dragProvided.draggableProps.style),
                    border: activeIndex === `${lang}-content-draggable@${indexList.join("-")}-${cIndex}` ? "2px solid red" : "none",
                    borderRadius: 4,
                  }}
                  onClick={() => {
                    setActiveIndex(`${lang}-content-draggable@${indexList.join("-")}-${cIndex}`)
                  }}
                  // onMouseEnter={() => setActiveIndex(`${lang}-content-draggable@${indexList.join("-")}-${cIndex}`)}
                >
                  {content.tag.toLowerCase() === 'accordion' ? (
                    <>
                    {/* hello-according */}
                      <Collapse defaultActiveKey={['accordion']}
                      style={styleCheck(content)}
                      >
                        <Collapse.Panel
                          header={contentCardHeadRender(content)}
                          key="accordion"
                          // extra={
                          //   <Popconfirm
                          //     title={`Delete this accordion and all its children?`}
                          //   onConfirm={() => deleteContent(contents, cIndex)}
                          //   onCancel={(e) => e.stopPropagation()}
                          //   >
                          //     <Button
                          //       type="danger"
                          //       icon={<DeleteSweep />}
                          //       shape="circle"
                          //       size="small"
                          //       onClick={(e) => e.stopPropagation()}
                          //     />
                          //   </Popconfirm>
                          // }
                        >
                          {/* <Alert
                            message="Accordion will not be able to accept drag and drop from left panel under certain conditions. When it happens, please use the button on the bottom to add new content to this level of accordion"
                            type="info"
                            style={{ marginBottom: 5 }}
                          /> */}
                          <Row>
                            <Col span={24}>
                              <FloatingLabel
                                controlId={`${lang}-content-text-${indexList && indexList.join('-')}`}
                                // label={`Header`}
                                className="mb-1"
                              >
                                {/* Hearder1 */}
                                {/* <Form.Control
                                  type="input"
                                  placeholder={`Please set accordion header`}
                                  value={content && content.header || ''}
                                  onChange={(e) => {
                                    content.header = e.target.value;
                                    setCurrentPost({ ...currentPost });
                                  }}
                                /> */}
                              </FloatingLabel>
                            </Col>
                          </Row>
                          {types
                            .find((t) => t.tag === content.tag)
                            .render(lang, content.accordion, [...indexList, cIndex])}
                          <Dropdown
                            overlay={() => (
                              <Menu>
                                {types.map((type) => (
                                  <Menu.Item key={type.tag} onClick={({ key }) => addToAccordion(content, key)}>
                                    <div className="d-flex align-items-center justify-content-evenly">
                                      {type.icon}
                                      {type.text}
                                    </div>
                                  </Menu.Item>
                                ))}
                              </Menu>
                            )}
                            trigger={['click']}
                          >
                            <span>Add New</span>
                            {/* <Button
                              className="mt-1"
                              type="primary"
                              size={'small'}
                              icon={<AddBox />}
                              onClick={(e) => e.preventDefault()}
                            >
                              &nbsp;Add New Element Into This Accordion
                            </Button> */}
                          </Dropdown>
                        </Collapse.Panel>
                      </Collapse>
                    </>
                  ) : (
                      <>
                        {types.find((t) => t.tag === content.tag)?.render(lang, content, [...indexList, cIndex])}
                      </>
                  )}
                </div>
              )}
            </Draggable>
          ))}
          {dropProvided.placeholder}
        </div>
      )}
    </Droppable>
    )
  };

  const h1Render = (lang, content, indexList) => {

    return (
      <>
        {_RenderPreview(content)}
      </>
    )
  };
  const h2Render = (lang, content, indexList) => {
    return (
      <>
        {_RenderPreview(content)}
      </>
    )
  };
  const h3Render = (lang, content, indexList) => {
    return (
      <>
        {_RenderPreview(content)}
      </>
    )
  };
  const h4Render = (lang, content, indexList) => {
    return (
      <>
        {_RenderPreview(content)}
      </>
    )
  };
  const h5Render = (lang, content, indexList) => {
    return (
      <>
        {_RenderPreview(content)}
      </>
    )
  };
  const _Render = (lang, content, indexList) => {
    return (
      <>
        {_RenderPreview(content)}
      </>
    )
  };
  const _RenderContent = (lang, content, indexList) => {

    return (
      <>
        {_RenderPreview(content)}
      </>
    )
  };
  const imageRender = (lang, content, indexList) => {
    return (
      <>
        {_RenderPreview(content)}
      </>
    )
  };

  const bulletAndBodyRender = (lang, content, indexList) => (
    <>
      <Row gutter={1}>
        skhsk-body
        {content.text || ''}
        {/* <Col span={6}>
          <FloatingLabel
            controlId={`${lang}-content-fontWeight-${indexList.join('-')}`}
            label={`Font Weight`}
            className="mb-1"
          >
            <Form.Select
              aria-label="fontWeight"
              onChange={(event) => {
                content.fontWeight = event.target.value;
                setCurrentPost({ ...currentPost });
              }}
            >
              <option value={'regular'} selected>
                Regular
              </option>
              <option value={'bold'} selected={'bold' === content.fontWeight}>
                Bold
              </option>
            </Form.Select>
          </FloatingLabel>
        </Col>
        <Col span={6}>
          <FloatingLabel controlId={`${lang}-content-size-${indexList.join('-')}`} label={`Size`} className="mb-1">
            <Form.Select
              aria-label="Small"
              onChange={(event) => {
                content.size = event.target.value;
                setCurrentPost({ ...currentPost });
              }}
            >
              <option value={'regular'}>Regular</option>
              <option value={'small'} selected={'small' === content.size}>
                Small
              </option>
              <option value={'xsmall'} selected={'xsmall' === content.size}>
                xSmall
              </option>
            </Form.Select>
          </FloatingLabel>
        </Col>
        <Col span={6}>
          <FloatingLabel
            controlId={`${lang}-content-textDecoration-${indexList.join('-')}`}
            label={`Text Decoration`}
            className="mb-1"
          >
            <Form.Select
              aria-label="textDecoration"
              onChange={(event) => {
                content.textDecoration = event.target.value;
                setCurrentPost({ ...currentPost });
              }}
            >
              <option value={'none'} selected>
                None
              </option>
              <option value={'underline'} selected={'underline' === content.textDecoration}>
                Underline
              </option>
            </Form.Select>
          </FloatingLabel>
        </Col>
        <Col span={6}>
          <FloatingLabel
            controlId={`${lang}-content-marginBottom-${indexList.join('-')}`}
            label={`Margin Bottom`}
            className="mb-1"
          >
            <Form.Control
              type="number"
              placeholder={`Please set Margin Bottom`}
              value={content.marginBottom || 0}
              onChange={(e) => {
                content.marginBottom = Number(e.target.value);
                setCurrentPost({ ...currentPost });
              }}
            />
          </FloatingLabel>
        </Col> */}
      </Row>
      {/* <Row>
        <Col span={24}>
          <FloatingLabel controlId={`${lang}-content-text-${indexList.join('-')}`} label={`Text`} className="mb-1">
            <Form.Control
              type="input"
              as="textarea"
              style={{ maxHeight: '120px', minHeight: '120px' }}
              placeholder={`Please set Text`}
              value={content.text || ''}
              onChange={(e) => {
                content.text = e.target.value;
                setCurrentPost({ ...currentPost });
              }}
            />
          </FloatingLabel>
        </Col>
      </Row> */}
    </>
  );

  const buttonRender = (lang, content, indexList) => (
    <>
      <Row gutter={1}>
        skhsk-button

        {/* <Col span={8}>
          <FloatingLabel controlId={`${lang}-content-text-${indexList.join('-')}`} label={`Title`} className="mb-1">
            <Form.Control
              type="input"
              placeholder={`Please set button title`}
              value={content.title || ''}
              onChange={(e) => {
                content.title = e.target.value;
                setCurrentPost({ ...currentPost });
              }}
            />
          </FloatingLabel>
        </Col>
        <Col span={8}>
          <FloatingLabel
            controlId={`${lang}-content-marginBottom-${indexList.join('-')}`}
            label={`Margin Bottom`}
            className="mb-1"
          >
            <Form.Control
              type="number"
              placeholder={`Please set Margin Bottom`}
              value={content.marginBottom || 0}
              onChange={(e) => {
                content.marginBottom = Number(e.target.value);
                setCurrentPost({ ...currentPost });
              }}
            />
          </FloatingLabel>
        </Col> */}
      </Row>
      {/* <Row>
        <Col span={24}>
          <div className="d-flex flex-row align-items-center mb-1">
            <span style={{ width: '100px' }}>
              Navigation (JSON):
              <p style={{ fontStyle: 'italic', fontSize: 'small' }}>Ask Developer for the value</p>
            </span>
            <JSONInput
              placeholder={
                typeof content.navigation !== 'object'
                  ? types.find((t) => t.tag === 'button').default.navigation
                  : content.navigation
              }
              theme={'light_mitsuketa_tribute'}
              width={'100%'}
              height="auto"
              style={{
                contentBox: { cursor: 'text' },
                body: { border: '1px lightgrey solid', borderRadius: '4px' },
              }}
              onBlur={(e) => {
                if (!e.error) {
                  content.navigation = e.jsObject;
                  setCurrentPost({ ...currentPost });
                }
              }}
            />
          </div>
        </Col>
      </Row> */}
    </>
  );

  const linkButtonRender = (lang, content, indexList) => (
    <Row gutter={1}>
      skhsk-link-button
      {content.title || ''}
      {/* <Col span={8}>
        <FloatingLabel controlId={`${lang}-content-link-${indexList.join('-')}`} label={`Link`} className="mb-1">
          <Form.Control
            type="input"
            placeholder={`Please set link`}
            value={content.link || ''}
            onChange={(e) => {
              content.link = e.target.value;
              setCurrentPost({ ...currentPost });
            }}
          />
        </FloatingLabel>
      </Col>
      <Col span={8}>
        <FloatingLabel controlId={`${lang}-content-title-${indexList.join('-')}`} label={`Title`} className="mb-1">
          <Form.Control
            type="input"
            placeholder={`Please set title`}
            value={content.title || ''}
            onChange={(e) => {
              content.title = e.target.value;
              setCurrentPost({ ...currentPost });
            }}
          />
        </FloatingLabel>
      </Col>
      <Col span={8}>
        <FloatingLabel
          controlId={`${lang}-content-marginBottom-${indexList.join('-')}`}
          label={`Margin Bottom`}
          className="mb-1"
        >
          <Form.Control
            type="number"
            placeholder={`Please set Margin Bottom`}
            value={content.marginBottom || 0}
            onChange={(e) => {
              content.marginBottom = Number(e.target.value);
              setCurrentPost({ ...currentPost });
            }}
          />
        </FloatingLabel>
      </Col> */}
    </Row>
  );

  const types = [
    {
      text: 'Body',
      tag: 'content',
      icon: <CoverSvg />,
      render: _RenderContent,
      // render: bulletAndBodyRender,
      default: {
        tag: 'content',
        index: -1,
        fontWeight: 'regular',
        size: 'regular',
        textDecoration: 'none',
        marginBottom: 0,
      },
    },
    {
      text: 'Bullet',
      tag: 'bullet',
      icon: <UnorderedListSvg />,
      render: _Render,
      default: {
        tag: 'bullet',
        index: -1,
        fontWeight: 'regular',
        size: 'regular',
        textDecoration: 'none',
        marginBottom: 0,
      },
    },
    {
      text: 'Button',
      tag: 'button',
      icon: <ButtonSvg />,
      render: _Render,
      default: {
        tag: 'button',
        index: -1,
        navigation: {
          routerName: '',
          screenName: '',
          params: {},
        },
        title: '',
        marginBottom: 0,
      },
    },
    {
      text: 'H1',
      tag: 'h1',
      icon: <H1Svg />,
      render: h1Render,
      default: {
        tag: 'h1',
        index: -1,
        textDecoration: 'none',
        marginBottom: 0,
      },
    },
    {
      text: 'H2',
      tag: 'h2',
      icon: <H2Svg />,
      render: h2Render,
      default: {
        tag: 'h2',
        index: -1,
        textDecoration: 'none',
        marginBottom: 0,
      },
    },
    {
      text: 'H3',
      tag: 'h3',
      icon: <H3Svg />,
      render: h3Render,
      default: {
        tag: 'h3',
        index: -1,
        textDecoration: 'none',
        marginBottom: 0,
      },
    },
    {
      text: 'H4',
      tag: 'h4',
      icon: <H4Svg />,
      render: h4Render,
      default: {
        tag: 'h4',
        index: -1,
        textDecoration: 'none',
        marginBottom: 0,
      },
    },
    {
      text: 'H5',
      tag: 'h5',
      icon: <H5Svg />,
      render: h5Render,
      default: {
        tag: 'h5',
        index: -1,
        textDecoration: 'none',
        marginBottom: 0,
      },
    },
    {
      text: 'Link Button',
      tag: 'link',
      icon: <LinkSvg />,
      render: _Render,
      default: {
        tag: 'link',
        index: -1,
        link: '',
        title: '',
        marginBottom: 0,
      },
    },
    {
      text: 'Image',
      tag: 'image',
      icon: <ImageSvg />,
      render: imageRender,
      default: {
        tag: 'image',
        index: -1,
        url: '',
        ratio: 1,
        marginBottom: 0,
        imgwidth: 100,
      },
    },
    {
      text: 'Accordion',
      tag: 'accordion',
      icon: <AccordionSvg />,
      render: contentDroppableAreaRender,
      // render: _Render,
      default: {
        tag: 'accordion',
        index: -1,
        header: '',
        accordion: [],
        marginBottom: 0,
      },
    },
  ];

  const reorder = (contents, startIndex, endIndex) => {
    const result = Array.from(contents);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const copyMove = (destination, droppableSource, droppableDestination) => {
    const destClone = Array.from(destination);
    const copying = { ...types[droppableSource.index].default };
    destClone.splice(droppableDestination.index, 0, copying);
    return destClone;
  };

  const onDragStart = (start) => {
    setDisabledDropzone(start.source.droppableId);
  };

  const onDragEnd = (result) => {
    console.log(`skhsk2-result: `, result)

    setDisabledDropzone(null);
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }
    const sInd = source.droppableId;
    const dInd = destination.droppableId;

    const droppingArea = dInd.split('@')[1];

    if (droppingArea === '') {
      if (sInd === dInd) {
        currentPost.localize[currentEditLanguage].layout = [
          ...reorder(currentPost.localize[currentEditLanguage]?.layout || [], source.index, destination.index),
        ];
      } else {
        currentPost.localize[currentEditLanguage].layout = [
          ...copyMove(currentPost.localize[currentEditLanguage]?.layout || [], source, destination),
        ];
      }
    } else {
      const positionList = droppingArea.split('-');
      let theDroppingArray = currentPost.localize[currentEditLanguage].layout[positionList[0]];
      for (let i = 1; i < positionList.length; ++i) {
        theDroppingArray = theDroppingArray.accordion[positionList[i]];
      }

      if (sInd === dInd) {
        theDroppingArray.accordion = [...reorder(theDroppingArray.accordion, source.index, destination.index)];
      } else {
        theDroppingArray.accordion = [...copyMove(theDroppingArray.accordion, source, destination)];
      }
    }

    // if(droppingArea) {
      let drop = `${destination.droppableId}`
      let dropNew = drop.replace("droppable", "draggable")
      // console.log(`skhsk: `, `${destination.droppableId}-${destination.index}` )
      setActiveIndex(`${dropNew}-${destination.index}`)
      // setActiveIndex(`${result.draggableId}`)
    // }

    // console.log(`skhsk-drop-currentPost: `, currentPost)
    setCurrentPost({ ...currentPost });
  };

  const handleSubmitContent = async () => {
    const reassignIndex = (arr) => {
      arr.forEach((el, i) => {
        el.index = i;
        if (el.tag === 'accordion') {
          reassignIndex(el.accordion);
        }
      });
    };

    Object.values(currentPost.localize).forEach((content) => {
      reassignIndex(content.layout);
    });
    console.log(`skhsk-currentPost: `, currentPost)
    setIsSubmit(true)
    setLoading(true);
    try {
      const res = await putDataRequest(`${NEWS_GET_URL}/${datalocalize.newsId}`, currentPost);
      if (res?.status === 200) {
        messageModalRef.current.showSuccessConfirmsAutoClose(res.data?.message || t("Saved Successfully"), () => { 
          navigate(`/news-posts`)
        }, "", true);
      } else {
        messageModalRef.current.showWarningConfirmsAutoClose(res.data?.message || res.message || t("An error occurred."), () => { }, "", true);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      messageModalRef.current.showWarningConfirmsAutoClose(err && err.response && err.response.data && err.response.data.message, () => { }, "", true);
    } finally {
      setLoading(false);
      setIsSubmit(false);
    }
  }

  useImperativeHandle(ref, () => {
    return { handleSubmitContent: handleSubmitContent }
})

  let itemData = {}
  let droppingArea = ''
  let positionList = []
  if(currentPost && currentPost.localize && currentPost.localize[lang] && currentPost.localize[lang].layout) {
    // currentPost.localize[lang].layout
    console.log(`skhsk-active-${activeIndex}: `, currentPost.localize[lang].layout[activeIndex])
  }
  if(activeIndex) {
    droppingArea = activeIndex.split('@')[1];
    console.log(`skhsk-droppingArea: `, droppingArea)
    if (droppingArea === '') {
    }
    else {
      positionList = droppingArea.split('-');
      console.log(`skhsk-positionList-split: `, positionList)
      if(positionList[0] === "") {
        itemData =  currentPost.localize[lang].layout[positionList[1]]
      } else {
        let theDroppingArray = currentPost.localize[currentEditLanguage].layout[positionList[0]];
        for (let i = 1; i < positionList.length; ++i) {
          theDroppingArray = theDroppingArray.accordion[positionList[i]];
        }
        itemData = theDroppingArray
        console.log(`skhsk-theDroppingArray: `, theDroppingArray)
      }
    }
  }
  // console.log(`skhsk-itemData: `, itemData)

  return <>
    <div className="w-100 h-100">
      <div className="row w-100">
        {/* <Button onClick={() => {
          // if (positionList[0] === "") {
          //   itemData.text = "hello I love u"
          //   currentPost.localize[lang].layout[positionList[1]] = itemData
          // }
          // setCurrentPost({ ...currentPost });
          handleSubmitContent()
        }}>currentPost</Button> */}
        <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
          <div className="col-3 pe-4 ps-4 pt-2 pb-2" style={{ background: "#ffffff" }}>
            <LeftDragTextComponent types={types} />
          </div>
          <div className="col-6 pe-4 ps-4" style={{ overflow: "auto" }}>
            <div className='mt-3 w-100 mb-3'>
              <MobileViewTextDragComponent
                contentDroppableAreaRender={contentDroppableAreaRender(lang, currentPost.localize[lang].layout, [])}
                data={datalocalize ? datalocalize : null}
              />
            </div>
            {/* <div className='mt-3 w-100 mb-3'>
                        <MobileViewDragComponent />
                    </div> */}
          </div>
        </DragDropContext>
        <div className="col-3 pe-4 ps-4 pt-4 pb-4">
          <RightDragTextComponent 
          itemData={itemData}
          setItemData={(item) => {
            if (positionList[0] === "") {
              currentPost.localize[lang].layout[positionList[1]] = item
            }
            setCurrentPost({ ...currentPost });
          }}
          types={types}
          />
        </div>
      </div>
    </div>
    <MessageConfirmModal textCentered ref={messageModalRef} />
  </>
}
export default forwardRef(DragViewMobileComponent);