import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './style.css';
import { Button, Col, Input, message, Row, Upload, } from 'antd';
import InputComponent from '../../components/input/InputComponent';
import ButtontSubmitComponent from '../../components/buttonAnt/ButtontSubmitComponent';
import { UploadOutlined } from '@mui/icons-material';
import InputNumberComponent from '../../components/input/InputNumberComponent';
import MessageConfirmModal from '../../components/messageModal/MessageConfirmModal';
import { getDataRequest, postDataRequest, putDataRequest } from '../../api/serviceMethods';
import { MAGAZINES_URL } from '../../api/URLs';

function MagazineCreatePage() {
  const { TextArea } = Input;
  const navigate = useNavigate();
  const [isSubmit, setIsSubmit] = useState(false)
  const [file, setFile] = useState(null);
  const [fileImg, setFileImg] = useState(null);
  const [loading, setLoading] = useState(false);
  const messageModalRef = useRef("");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');
  const [dataObject, setDataObject] = useState({
    title: "",
    description: "",
    tags: [],
    author: "",
    publisher: "",
    catalog: {
      number: "",
      type: "",
      section: "",
      description: ""
    },
    picUrl: "",
    fileUrl: "",
    filename: "",
    fileSize: "",
    previewPage: ""
  });

  const checkError = () => {
    if (!dataObject.title || !dataObject.author ||
      !dataObject.publisher ||
      !dataObject.catalog.number ||
      !dataObject.catalog.type ||
      !dataObject.catalog.section ||
      !dataObject.description
    ) {
      return true
    }
    return false
  }

  const uploadProps = {
    name: "file",
    multiple: true,
    accept: "application/pdf",
    beforeUpload: (file) => {
      setFile(file);
      return false;
    },
    async onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} upload failed.`);
      }
    },
    withCredentials: true,
  };

  const uploadPropsImage = {
    name: "file",
    accept: "image/*",
    multiple: false,
    beforeUpload: (file) => {
      setFileImg(file);
      return false;
    },
    onChange(info) {
      info.fileList = info.fileList.slice(-1);

      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} upload failed.`);
      }
    },
    withCredentials: true,
  };

  const addNewValue = async (data) => {
    setDataObject({
      title: data && data.title,
      description: data && data.description,
      tags: data && data.tags,
      author: data && data.author,
      publisher: data && data.publisher,
      catalog: {
        number: data && data.catalog && data.catalog.number,
        type: data && data.catalog && data.catalog.type,
        section: data && data.catalog && data.catalog.section,
        description: data && data.catalog && data.catalog.description
      },
      picUrl: data && data.picUrl,
      fileUrl: data && data.filename,
      filename: data && data.filename,
      fileSize: data && data.fileSize,
      previewPage: data && data.previewPage
    })
  }

  // get member Id
  const getMagazineById = async () => {
    try {
      setLoading(true);
      await getDataRequest(`${MAGAZINES_URL}/${id && id}`)
        .then((res) => {
          if (res.status === 200 && res.data) {
            addNewValue(res.data)
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
      message.error('Server Error!');
    }
  }
  useEffect(() => {
    if (id && id) { getMagazineById() }
  }, []);

  // update Magazine
  const hadleUpdateMagazine = async (e) => {
    e.preventDefault();
    setIsSubmit(true);
    try {
      const magazinePayload = {
        title: dataObject.title,
        description: dataObject.description,
        tags: dataObject.tags,
        author: dataObject.author,
        publisher: dataObject.publisher,
        catalog: {
          number: dataObject.catalog.number,
          type: dataObject.catalog.type,
          section: dataObject.catalog.section,
          description: dataObject.catalog.description,
        },
        picUrl: fileImg && fileImg.name || dataObject && dataObject.picUrl,
        fileUrl: file && file.name || dataObject && dataObject.fileUrl,
        filename: file && file.name || dataObject && dataObject.filename,
        fileSize: file && file.size || dataObject && dataObject.fileSize,
        previewPage: dataObject.previewPage
      }
      if (checkError()) {
        return false;
      }
      setLoading(true);
      await putDataRequest(`${MAGAZINES_URL}/${id && id} `, magazinePayload)
        .then((res) => {
          if (res.status === 200) {
            setLoading(false);
            messageModalRef.current.showSuccessConfirmsAutoClose(res && res.message, () => { }, "", true);
            setTimeout(() => {
              navigate("/magazine")
            }, 2000);
          } else {
            messageModalRef.current.showWarningConfirmsAutoClose(res && res.message, () => { }, "", true);
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          messageModalRef.current.showWarningConfirmsAutoClose(err && err.response && err.response.data && err.response.data.message || err.message, () => { }, "", true);
        });
    } catch (error) {
      console.log("error", error)
      setLoading(false);
      message.error('Server Error!');
    }
  }

  // Save Magazine
  const handleSubmit = async () => {
    setIsSubmit(true)
    if (checkError()) {
      return false
    }
    setLoading(true);
    const magazinePayload = {
      title: dataObject.title,
      description: dataObject.description,
      tags: dataObject.tags,
      author: dataObject.author,
      publisher: dataObject.publisher,
      catalog: {
        number: dataObject.catalog.number,
        type: dataObject.catalog.type,
        section: dataObject.catalog.section,
        description: dataObject.catalog.description,
      },
      picUrl: fileImg && fileImg.name,
      fileUrl: file && file.name,
      filename: file && file.name,
      fileSize: file && file.size,
      previewPage: dataObject.previewPage
    }
    try {
      const res = await postDataRequest(MAGAZINES_URL, magazinePayload);
      if (res?.status === 200) {
        messageModalRef.current.showSuccessConfirmsAutoClose(res.data?.message || 'Saved Successfully', () => { }, "", true);
        setTimeout(() => {
          navigate("/magazine")
        }, 2000);
      } else {
        messageModalRef.current.showWarningConfirmsAutoClose(res.data?.message || res.message || 'An error occurred.', () => { }, "", true);
      }
    } catch (err) {
      messageModalRef.current.showWarningConfirmsAutoClose(err && err.response && err.response.data && err.response.data.message || err.message, () => { }, "", true);
    } finally {
      setLoading(false);
      setIsSubmit(false);
    }
  }

  return (
    <div className="container box-shadow h-100 mymain"
      style={{
        background: "0% 0% no-repeat padding-box padding-box rgb(240, 242, 245)",
        padding: "32px",
        overflow: "auto"
      }}
    >

      <div className="w-100">
        <h2 className="text-start">{id && id ? "Edit Magazine Management" : "Create Magazine Management"}</h2>
      </div>
      <div className="w-100 bluediv">
        <div className="w-100 h-100 p-2 pt-3 ps-5 pe-5 d-flex flex-column justify-content-between" style={{ background: "#fff", borderRadius: "4px" }}>
          <div className='w-100'>
            <Row className='mt-2 d-flex align-items-center'>
              <Col span={6}>
                <div>
                  <label htmlFor=''><span className='text-danger'>*</span>Title: </label>
                </div>
              </Col>
              <Col span={12}>
                <InputComponent status={isSubmit && !dataObject.title && `error`} value={dataObject.title} setValue={(value) => setDataObject({ ...dataObject, title: value })} placeholder={`Enter title`} />
              </Col>
            </Row>
            <Row className='mt-3 d-flex align-items-start'>
              <Col span={6}>
                <div>
                  <label htmlFor=''><span className='text-danger'>*</span>Files: </label>
                </div>
              </Col>
              <Col sm={10} className="create-row d-flex justify-content-start align-items-end upload">
                <Upload {...uploadProps}>
                  <Button size="large" icon={<UploadOutlined />}>
                    Upload
                  </Button>
                </Upload>
              </Col>
            </Row>
            <Row className='mt-3 d-flex align-items-center'>
              <Col span={6}>
                <div>
                  <label htmlFor=''><span className='text-danger'>*</span>Catalog Number:</label>
                </div>
              </Col>
              <Col span={12}>
                <InputComponent
                  status={isSubmit && !dataObject.catalog.number && `error`}
                  value={dataObject.catalog.number}
                  setValue={(value) => setDataObject({
                    ...dataObject,
                    catalog: { ...dataObject.catalog, number: value }
                  })}
                  placeholder={`Enter catalog number`}
                />
              </Col>
            </Row>

            <Row className='mt-3 d-flex align-items-center'>
              <Col span={6}>
                <div>
                  <label htmlFor=''><span className='text-danger'>*</span>Catalog Type:</label>
                </div>
              </Col>
              <Col span={12}>
                <InputComponent
                  status={isSubmit && !dataObject.catalog.type && `error`}
                  value={dataObject.catalog.type}
                  setValue={(value) => setDataObject({
                    ...dataObject,
                    catalog: { ...dataObject.catalog, type: value }
                  })}
                  placeholder={`Enter catalog type`}
                />
              </Col>
            </Row>

            <Row className='mt-3 d-flex align-items-center'>
              <Col span={6}>
                <div>
                  <label htmlFor=''><span className='text-danger'>*</span>Catalog Section:</label>
                </div>
              </Col>
              <Col span={12}>
                <InputComponent
                  status={isSubmit && !dataObject.catalog.section && `error`}
                  value={dataObject.catalog.section}
                  setValue={(value) => setDataObject({
                    ...dataObject,
                    catalog: { ...dataObject.catalog, section: value }
                  })}
                  placeholder={`Enter catalog section`}
                />
              </Col>
            </Row>

            <Row className='mt-3 d-flex align-items-center'>
              <Col span={6}>
                <div>
                  <label htmlFor=''><span className='text-danger'>*</span>Catalog Description:</label>
                </div>
              </Col>
              <Col span={12}>
                <InputComponent
                  status={isSubmit && !dataObject.catalog.description && `error`}
                  value={dataObject.catalog.description}
                  setValue={(value) => setDataObject({
                    ...dataObject,
                    catalog: { ...dataObject.catalog, description: value }
                  })}
                  placeholder={`Enter catalog description`}
                />
              </Col>
            </Row>

            <Row className='mt-3 d-flex align-items-center'>
              <Col span={6}>
                <div>
                  <label htmlFor=''><span className='text-danger'>*</span>Author: </label>
                </div>
              </Col>
              <Col span={12}>
                <InputComponent status={isSubmit && !dataObject.author && `error`} value={dataObject.author} setValue={(value) => setDataObject({ ...dataObject, author: value })} placeholder={`Enter author`} />
              </Col>
            </Row>
            <Row className='mt-3 d-flex align-items-center'>
              <Col span={6}>
                <div>
                  <label htmlFor=''><span className='text-danger'>*</span>Publisher: </label>
                </div>
              </Col>
              <Col span={12}>
                <InputComponent status={isSubmit && !dataObject.publisher && `error`} value={dataObject.publisher} setValue={(value) => setDataObject({ ...dataObject, publisher: value })} placeholder={`Enter publisher`} />
              </Col>
            </Row>
            <Row className='mt-3 d-flex align-items-center'>
              <Col span={6}>
                <div>
                  <label htmlFor=''><span className='text-danger'>*</span>Preview Page: </label>
                </div>
              </Col>
              <Col span={12}>
                <InputNumberComponent status={isSubmit && !dataObject.previewPage && `error`} value={dataObject.previewPage} setValue={(value) => setDataObject({ ...dataObject, previewPage: value })} placeholder={`Enter preview page`} />
              </Col>
            </Row>
            <Row className='mt-3 d-flex align-items-center'>
              <Col span={6}>
                <div>
                  <label htmlFor=''>Tags: </label>
                </div>
              </Col>
              <Col span={12}>
                <InputComponent
                  value={Array.isArray(dataObject.tags) ? dataObject.tags : (dataObject.tags || "").split(/[, ]+/)}
                  setValue={(value) => setDataObject({ ...dataObject, tags: Array.isArray(value) ? value : value.split(/[, ]+/) })}
                  placeholder={`Enter tags, separated by comma or space`}
                />
              </Col>
            </Row>
            <Row className='mt-3 d-flex align-items-start'>
              <Col span={6}>
                <div>
                  <label htmlFor=''><span className='text-danger'>*</span>Cover Image: </label>
                </div>
              </Col>
              <Col sm={10} className="create-row d-flex justify-content-start align-items-end upload">
                <Upload {...uploadPropsImage}>
                  <Button size="large" icon={<UploadOutlined />}>
                    Upload
                  </Button>
                </Upload>
              </Col>
            </Row>
            <Row className='mt-3 d-flex align-items-start'>
              <Col span={6}>
                <div>
                  <label htmlFor=''><span className='text-danger'>*</span>Description: </label>
                </div>
              </Col>
              <Col span={12}>
                <TextArea
                  status={isSubmit && !dataObject.description && `error`}
                  value={dataObject.description}
                  onChange={(e) => {
                    setDataObject({ ...dataObject, description: e.target.value })
                  }}
                  placeholder="Enter description"
                  autoSize={{
                    minRows: 5,
                    maxRows: 7,
                  }}
                />

              </Col>
            </Row>
          </div>
          <Row className='mt-2'>
            <Col span={24}>
              <div className='text-center pb-3'>
                <ButtontSubmitComponent handle={() => navigate(`/magazine`)} className={`me-5`} isCancel={true} title={`Back`} />
                <ButtontSubmitComponent handle={id && id ? hadleUpdateMagazine : handleSubmit} title={`Submit`} />
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <MessageConfirmModal textCentered ref={messageModalRef} />
    </div>
  );
}

export default MagazineCreatePage;
