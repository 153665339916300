import * as React from 'react';

const TagsSvg = () => {
    return (
        <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="23" cy="23" r="23" fill="#F8E2E8" />
            <path d="M18.125 16.5001C17.8036 16.5001 17.4894 16.5954 17.2222 16.774C16.955 16.9525 16.7467 17.2063 16.6237 17.5032C16.5007 17.8002 16.4685 18.1269 16.5312 18.4421C16.5939 18.7573 16.7487 19.0469 16.976 19.2741C17.2032 19.5014 17.4928 19.6562 17.808 19.7189C18.1232 19.7816 18.4499 19.7494 18.7469 19.6264C19.0438 19.5034 19.2976 19.2951 19.4761 19.0279C19.6547 18.7607 19.75 18.4465 19.75 18.1251C19.75 17.6941 19.5788 17.2808 19.2741 16.9761C18.9693 16.6713 18.556 16.5001 18.125 16.5001V16.5001ZM32.88 21.6026L23.7692 12.4809C23.6679 12.3805 23.5479 12.3011 23.4159 12.2472C23.2839 12.1933 23.1426 12.1659 23 12.1668H13.25C12.9627 12.1668 12.6871 12.2809 12.484 12.4841C12.2808 12.6872 12.1667 12.9628 12.1667 13.2501V23.0001C12.1659 23.1427 12.1932 23.284 12.2471 23.416C12.301 23.548 12.3804 23.668 12.4808 23.7693L21.6025 32.8801C22.2119 33.4887 23.0379 33.8306 23.8992 33.8306C24.7604 33.8306 25.5865 33.4887 26.1958 32.8801L32.88 26.2501C33.4886 25.6407 33.8305 24.8147 33.8305 23.9534C33.8305 23.0922 33.4886 22.2661 32.88 21.6568V21.6026ZM31.3525 24.6576L24.6575 31.3418C24.4545 31.5435 24.18 31.6568 23.8938 31.6568C23.6076 31.6568 23.333 31.5435 23.13 31.3418L14.3333 22.5559V14.3334H22.5558L31.3525 23.1301C31.4529 23.2313 31.5324 23.3514 31.5863 23.4834C31.6402 23.6154 31.6675 23.7567 31.6667 23.8993C31.6655 24.1835 31.5526 24.4558 31.3525 24.6576V24.6576Z" fill="#E50A47" />
        </svg>

    );
}

export default TagsSvg;