import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import ReactDOM from 'react-dom';
import { Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

const Z_OUTSIDE_PORTAL = 1050; // Example zIndex, adjust as needed

const ModalCloseButton = (
    { space = 30, onClose = () => {}, containerRef = null },
    ref
) => {
    const [el, setEl] = useState(null);
    const [top, setTop] = useState(0);

    useEffect(() => {
        const newEl = document.createElement('div');
        document.body.appendChild(newEl);
        setEl(newEl);

        if (containerRef?.current) {
            updatePosition();
        }

        return () => {
            document.body.removeChild(newEl);
        };
    }, [containerRef]);

    useImperativeHandle(ref, () => ({
        changePosition: updatePosition,
    }));

    const updatePosition = () => {
        const clientRect = containerRef?.current?.getBoundingClientRect();
        if (clientRect) {
            const positionOfButton = clientRect.bottom + space;
            setTop(positionOfButton);
        }
    };

    if (!el) return null;

    return ReactDOM.createPortal(
        <Button
            style={{
                zIndex: Z_OUTSIDE_PORTAL,
                position: 'fixed',
                top: top,
                right: '50%',
                transform: 'translateX(50%)',
                borderRadius: '50%',
                backgroundColor:"#F8E2E8",
                color: "#E50A47",
                border: "none",
            }}
            type="danger"
            shape="circle"
            icon={<CloseOutlined />}
            onClick={onClose}
        />,
        el
    );
};

export default forwardRef(ModalCloseButton);
