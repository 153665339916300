import React, { useEffect, useState } from 'react';
import { Tabs } from 'antd';
import './tabs.css'
import TableUserMember from '../../pages/member-pages/TableUser';
import { useTranslation } from 'react-i18next';
import TableMember from '../../pages/member-pages/TableMember';
import { getDataRequest } from '../../api/serviceMethods';
import { MEMBER_URL } from '../../api/URLs';
import { useNavigate } from 'react-router-dom';
const TableUserManagerment = ({ messageModalRef,loading, setLoading }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [keyword, setKeyword] = useState('');
    const [memberData, setMemberData] = useState([])
    const [dates, setDates] = useState([null, null]);
    const [memberType, setMemberType] = useState('normal');
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
            total: 0,
        },
    });
    
    const initLoadMember = async () => {
        setLoading(true);
        await getDataRequest(`${MEMBER_URL}`, {
            keyword: keyword.trim(),
            startRegDate: dates[0] || "",
            endRegDate: dates[1] || "",
            memberType: memberType === "pro" ? "pro" : ["normal", "trial"],
            sortKeys: { [tableParams.sortField]: tableParams.sortOrder === "ascend" ? "asc" : "desc" },//descend
            pageNo: tableParams.pagination.current,
            pageSize: tableParams.pagination.pageSize,
        }).then(res => {
            setLoading(false);
            if (res.status === 200) {
                setMemberData(res?.data);
                setTableParams({
                    ...tableParams,
                    pagination: {
                        ...tableParams.pagination,
                        total: res.total,
                    },
                });
            }
        }).catch((err) => {
            setLoading(false);
            console.log("Error", err);
        });
    };
    const handleTabChange = (activeKey) => {
        if (activeKey === 'listUsers') {
            setMemberType('normal');
            setKeyword("")
        }
        else if (activeKey === 'memberList') {
            setMemberType('pro');
            setKeyword("")
        }
    };

    const handleClickEdit = (item) => {
        navigate(`/user/details?id=${item.memberId}`);
    };

    const handleClickEditPro = (item) => {
        navigate(`/member/details?id=${item.memberId}`);
    };
    useEffect(() => {
        initLoadMember();
    }, [
        tableParams.pagination?.current,
        tableParams.pagination?.pageSize,
        tableParams?.sortOrder,
        tableParams?.sortField,
        JSON.stringify(tableParams.filters), memberType]);

    return (
        <Tabs defaultActiveKey="listUsers" className="cusStyle" onChange={handleTabChange}>
            <Tabs.TabPane tab={t("User List")} key="listUsers">
                <TableUserMember
                    messageModalRef={messageModalRef}
                    memberData={memberData}
                    keyword={keyword}
                    setKeyword={setKeyword}
                    tableParams={tableParams}
                    setTableParams={setTableParams}
                    dates={dates}
                    setDates={setDates}
                    initLoadMember={initLoadMember}
                    memberType={memberType}
                    handleClickEdit={handleClickEdit}
                />
            </Tabs.TabPane>
            <Tabs.TabPane tab={t("Member List")} key="memberList">
                <TableMember
                    messageModalRef={messageModalRef}
                    memberData={memberData}
                    keyword={keyword}
                    setKeyword={setKeyword}
                    tableParams={tableParams}
                    setTableParams={setTableParams}
                    dates={dates}
                    setDates={setDates}
                    initLoadMember={initLoadMember}
                    memberType={memberType}
                    handleClickEditPro={handleClickEditPro}
                />
            </Tabs.TabPane>
        </Tabs>
    )
}
export default TableUserManagerment;