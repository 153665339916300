import React, { useEffect, useState } from "react";
import DashboardPaymentComponent from "../../components/dashboard/dashboardPayment";
import TablePaymentPage from "./TablePaymentPage";
import { DASHBOARD_PAYMENT_URL } from "../../api/URLs";
import { getDataRequest } from "../../api/serviceMethods";

const PaymentPage = () => {
  const [dashboardPaymentData, setDashboardPaymentData] = useState(null);
  const [loading, setLoading] = useState(false);

  const getDashboard = async () => {
    try {
      setLoading(true);
      const res = await getDataRequest(`${DASHBOARD_PAYMENT_URL}`);
      if (res.status === 200) {
        setDashboardPaymentData(res.data);
      }
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDashboard();
  }, [])

  return (
    <div className="box-shadow h-100 w-100">
      <div className="w-100 h-100 overflow-auto">
        <div className="w-100">
          <DashboardPaymentComponent dashboardPaymentData={dashboardPaymentData} />
          <hr />
          <TablePaymentPage
            loading={loading}
            setLoading={setLoading}
          />
        </div>
      </div>
    </div>
  )
}

export default PaymentPage