import * as React from 'react';

const WeChatSvg = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1903_393)">
                <path d="M20.001 13.692V3.845C20.0005 2.82523 19.5951 1.8474 18.8739 1.12641C18.1527 0.405415 17.1748 0.000264944 16.155 0L3.845 0C2.82541 0.000529935 1.84772 0.405797 1.12676 1.12676C0.405797 1.84772 0.000529935 2.8254 0 3.845V16.155C0.000265092 17.1747 0.405447 18.1525 1.12647 18.8735C1.84749 19.5946 2.82532 19.9997 3.845 20H16.155C17.057 19.9994 17.9301 19.682 18.622 19.1034C19.3139 18.5248 19.7808 17.7216 19.941 16.834C18.921 16.392 14.501 14.484 12.198 13.384C10.446 15.507 8.61 16.781 5.844 16.781C3.078 16.781 1.231 15.077 1.453 12.991C1.599 11.623 2.538 9.386 6.615 9.769C8.765 9.971 9.748 10.372 11.501 10.951C11.954 10.119 12.331 9.204 12.617 8.231H4.845V7.461H8.691V6.077H4V5.23H8.69V3.235C8.69 3.235 8.732 2.923 9.077 2.923H11V5.23H16V6.078H11V7.46H15.079C14.7272 8.91647 14.1692 10.3152 13.422 11.614C14.607 12.044 20 13.692 20 13.692H20.001ZM5.538 15.461C2.615 15.461 2.153 13.616 2.308 12.845C2.461 12.077 3.308 11.075 4.933 11.075C6.8 11.075 8.473 11.553 10.481 12.531C9.071 14.367 7.338 15.461 5.538 15.461Z" fill="#009FE8" />
            </g>
            <defs>
                <clipPath id="clip0_1903_393">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default WeChatSvg;