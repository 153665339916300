import { Button, Typography, Space } from 'antd';
import React from 'react';

const { Text } = Typography;

export default function OnError(props = { handleTryAgain: () => {} }) {
  const { handleTryAgain } = props;

  return (
    <div className="ant-pro-error-container">
      <Space
        direction="vertical"
        size="large"
        className="ant-pro-error-content"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '80vh',
          backgroundColor: '#fff',
          borderRadius: '8px',
          padding: '20px',
        }}
      >
        <Text type="danger" style={{ fontSize: '16px', fontWeight: 'bold', color: "#E50A47" }}>
          Failed to load Magazine
        </Text>
        <Button
          type="primary"
          size="large"
          onClick={handleTryAgain}
          style={{
            backgroundColor: "#F8E2E8",
            color: "#E50A47",
            border: 'none',
          }}
        >
          Try Again
        </Button>
      </Space>
    </div>
  );
}

