import moment from "moment";

export const formatDateTime = date => {
    return moment(date).format("YYYY-MM-DD HH:mm");
}

export const formatDate = date => {
    return moment(date).format('YYYY-MM-DD');
}
export const formatDateSecondary = date => {
    return moment(date).format('MM/DD/YYYY');
}
export const formatDateTimekkk = date => {
    return moment(date).format('YYYY-MM-DD  h:mm:ss');
}
export const formartDateMDY24hr = date => {
    return moment(date).format('MM/DD/YYYY HH:mm:ss');
}
export const formartDateMDY24hrNoSecond = date => {
    return moment(date).format('MM/DD/YYYY HH:mm');
}
export const formatDateTimes = date => {
    return moment(date).format('YYYY-MM-DD  h:mm a');
}
export const formatDateTimeSlas = date => {
    return moment(date).format('MM / DD / YYYY  h:mm a');
}
export const formatDateTimeSlasMDY = date => {
    return moment(date).format('MM/DD/YYYY  h:mm a');
}
export const formatDateTimeSlasMDYY = (date, countMonth) => {
    return moment(date).format('MMM.DD YYYY');
}
export const formatDateTimeSlasMYY = (date, countMonth) => {
    if(countMonth) {
        return moment(date).add(countMonth, 'M').format('MMM, YYYY');
    } else {
        return moment(date).format('MMM, YYYY');
    }
}
export const formatDateTimeYYYY = (date, countY) => {
    if(countY) {
        return moment(date).add(countY, 'years').format('YYYY');
    } else {
        return moment(date).format('YYYY');
    }
}
export const formatDateTimeCurent = (date, time) => {
    const today = moment(date).format('YYYY-MM-DD h:mm a')
    const todayStr = `${today}`
    let str = time ? todayStr.slice(-8) : todayStr
    let strNew = todayStr.replace(str, ` ${time}`)
    return moment(strNew).local("en")
}
export const formatDateTimeCurentNew = date => {
    return moment(date).format('h:mm a')
}

export const formatDateTimeCurentCalendar = (date, time) => {
    const today = moment(date).format('YYYY-MM-DD h:mm a')
    const todayStr = `${today}`
    let str = time ? todayStr.slice(-8) : todayStr
    let strNew = todayStr.replace(str, ` ${time}`)
    date =  moment(strNew).local("en")
    return moment(date).format('H:mm')
}

export const formatDateTimeSlasSpace = date => {
    return (
        <>
        <span>{moment(date).format('MM / DD / YYYY')}</span>&nbsp;&nbsp;&nbsp;
        <span>{moment(date).format('h:mm a')}</span>
        </>
    )
}

export const formatDateTimeSlasSpaceMMDDYYFullTime = date => {
    return (
        <>
        <span>{moment(date).format('MM / DD / YYYY')}</span>&nbsp;&nbsp;&nbsp;
        <span>{moment(date).format(' H: mm')}</span>
        </>
    )
}

export const formatDateTimeSlasSpaceMMDDYYTime = date => {
    return (
        <>
        <span>{moment(date).format('YYYY - MM - DD ')}</span>&nbsp;&nbsp;&nbsp;
        <span>{moment(date).format(' H: mm')}</span>
        </>
    )
}

export const formatDateTimeSlasSpaceMMDDYY = date => {
    return (
        <>
        <span>{moment(date).format('MM / DD / YYYY')}</span>&nbsp;&nbsp;&nbsp;
        <span>{moment(date).format('h:mm a')}</span>
        </>
    )
}

export const formatDateTimeSlasSpaceMMDDYYUpperCase = date => {
    return (
        <>
        <span>{moment(date).format('MM / DD / YYYY')}</span>&nbsp;&nbsp;&nbsp;
        <span>{moment(date).format('h:mm a').toUpperCase()}</span>
        </>
    )
}

export const formatDateTimeSlasSpaceFullTime = date => {
    return (
        <>
        <span>{moment(date).format('YYYY-MM-DD')}</span>&nbsp;&nbsp;&nbsp;
        <span>{moment(date).format(' H: mm')}</span>
        </>
    )
}

export const formatDateTimeDay = date => {
    return moment(date).format('DD/MM/YYYY');
}
export const formatDateTimeNew = date => {
    return moment(date).format('YYYY-MM-DD  H: mm');
}
export const formatTime = date => {
    return moment(date).format('H : mm a');
}

export const formatDateTimeAddTime = (date, minutes, seconds) => {
    var dateNew = moment(date)
        .add(seconds ? seconds : 0, 'seconds')
        .add(minutes ? minutes : 0, 'minutes')
    return moment(dateNew).format('YYYY-MM-DD  H: mm');
}
