import * as React from 'react';

const ViewTableSvg = () => {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.8">
                <path d="M20.0014 10.6729L20.0016 10.6733C20.0466 10.7763 20.0698 10.8875 20.0698 11C20.0698 11.1124 20.0466 11.2236 20.0016 11.3266L20.0014 11.3271C18.1608 15.6006 14.7075 18.2333 10.9999 18.2333C7.29235 18.2333 3.83908 15.6006 1.99845 11.3271L1.99826 11.3266C1.95329 11.2236 1.93008 11.1124 1.93008 11C1.93008 10.8875 1.95329 10.7763 1.99826 10.6733L1.99845 10.6729C3.83908 6.3993 7.29235 3.76663 10.9999 3.76663C14.7075 3.76663 18.1608 6.3993 20.0014 10.6729ZM3.66764 10.9577L3.64793 11L3.66764 11.0422C5.26397 14.4656 8.03376 16.6 10.9999 16.6C13.9661 16.6 16.7359 14.4656 18.3322 11.0422L18.3519 11L18.3322 10.9577C16.7359 7.53436 13.9661 5.39996 10.9999 5.39996C8.03376 5.39996 5.26397 7.53436 3.66764 10.9577ZM9.0184 8.03438C9.60494 7.64247 10.2945 7.43329 10.9999 7.43329C11.9459 7.43329 12.8531 7.80907 13.522 8.47794C14.1908 9.14682 14.5666 10.054 14.5666 11C14.5666 11.7054 14.3574 12.395 13.9655 12.9815C13.5736 13.568 13.0166 14.0252 12.3648 14.2951C11.7131 14.5651 10.996 14.6357 10.3041 14.4981C9.61225 14.3605 8.97673 14.0208 8.47792 13.522C7.97912 13.0232 7.63943 12.3876 7.5018 11.6958C7.36418 11.0039 7.43482 10.2868 7.70477 9.63506C7.97472 8.98333 8.43187 8.4263 9.0184 8.03438ZM9.92584 12.6075C10.2438 12.8199 10.6176 12.9333 10.9999 12.9333C11.5127 12.9333 12.0044 12.7296 12.367 12.367C12.7296 12.0045 12.9333 11.5127 12.9333 11C12.9333 10.6176 12.8199 10.2438 12.6074 9.92586C12.395 9.60792 12.0931 9.36012 11.7398 9.21379C11.3865 9.06746 10.9978 9.02918 10.6228 9.10377C10.2477 9.17837 9.90325 9.36251 9.63287 9.63289C9.36248 9.90327 9.17835 10.2478 9.10375 10.6228C9.02916 10.9978 9.06744 11.3865 9.21377 11.7398C9.3601 12.0931 9.6079 12.395 9.92584 12.6075Z" fill="#636E88" stroke="white" stroke-width="0.2" />
            </g>
        </svg>
    );
}

export default ViewTableSvg;