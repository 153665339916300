import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import '../../styles/magazine.css';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import OnError from './OnError';
import OnLoad from './OnLoad';
import { Document, Page, pdfjs } from 'react-pdf';
import { Modal } from 'antd';
import HTMLFlipBook from 'react-pageflip';
import ModalCloseButton from './ModalCloseButton';
import _ from 'lodash';

const pdfjsVersion = '3.11.174';
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsVersion}/pdf.worker.min.js`;

function MagazineModal(props = { handleOpenDownloadApp: () => { } }, ref) {
    const magazineContainerRef = useRef();
    const magazineRef = useRef();
    const buttonCloseRef = useRef();
    const [width, setWidth] = useState(0);
    const [showCover, setShowCover] = useState(true);
    const [open, setOpen] = useState(false);
    const [magazine, setMagazine] = useState({
        filePath: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
        name: "ESI_Knowledge.pdf",
        preview: 3,
        _id: "1",
    });

    const showModal = (item) => {
        setMagazine({ ...item });
        setOpen(true);
    }

    const closeModal = () => {
        setOpen(false)
    }


    const handleForceChangePositionButton = () => buttonCloseRef.current.changePosition();

    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [])

    useImperativeHandle(ref, () => ({ showModal: showModal }))

    return (
        open &&
        <Modal
            key={`${magazine?.magazineId}-${width}-${open}`}
            closeIcon={false}
            open={open}
            footer={null}
            rootClassName='root-magazine-modal'
            className={`magazine-modal ${magazine && magazine.privateFile && magazine.magazineId}`}
            onOk={false}
            onCancel={() => {
                setOpen(false);
                magazineRef.current?.handleUnmountMagazine();
            }}
            width={1000}
            destroyOnClose={true}
        >
            <div>
                <ModalCloseButton
                    ref={buttonCloseRef}
                    onClose={() => setOpen(false)}
                    containerRef={magazineContainerRef}
                />
                <div ref={magazineContainerRef}>
                    <Magazine
                        ref={magazineRef}
                        handleForceChangePositionButton={handleForceChangePositionButton}
                        filePath={magazine && magazine.privateFile && magazine.privateFile.url}
                        preview={magazine && magazine.privateFile && magazine.privateFile.page}
                        id={magazine && magazine.privateFile && magazine.magazineId}
                        showCover={showCover}
                        setShowCover={setShowCover}
                        closeModal={closeModal}
                        modalOpen={open}
                    />
                </div>
            </div>
        </Modal>
    )
}

const MagazinePropType = {
    closeModal: () => { },
    preview: 3,
    filePath: "",
    id: "",
    showCover: true,
    setShowCover: () => { },
    handleForceChangePositionButton: () => { },
};

const Magazine = forwardRef((props = MagazinePropType, ref) => {
    const { closeModal, preview, filePath, id, setShowCover, showCover, handleForceChangePositionButton, modalOpen } = props;
    const flipBook = useRef(null);
    const documentRef = useRef(null);
    const [url, setUrl] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const [renderPage, setRenderPage] = useState([]);
    const [opened, setOpened] = useState(true);
    const [refresh, setRefresh] = useState(false);
    const [totalPage, setTotalPage] = useState(0);

    useEffect(() => {
        if (filePath !== url) {
            setRefresh(true);
            setCurrentPage(0);
            setRenderPage([]);
            setUrl(filePath);
        }
    }, [filePath])

    useEffect(() => {
        if (modalOpen) {
            const escapedId = CSS.escape(id);
            const contentUpdate = document.querySelector(`.${escapedId}.magazine-modal.ant-modal`);
            if (contentUpdate && showCover) {
                contentUpdate.classList.add("active");
                mountCloseAbleDiv(true);
            }
            handleForceChangePositionButton();
        }
    }, [modalOpen])

    function onDocumentLoadSuccess(pdf) {
        setTotalPage(pdf.numPages);
        const arr = Array(pdf.numPages);
        const arrKeys = arr.keys();
        const currentPage = 0;
        const total = Array.from(arrKeys);
        const newPage = total.slice(0, currentPage <= pdf.numPages - 6 ? currentPage + 6 : pdf.numPages);
        setCurrentPage(currentPage);
        setRenderPage([...newPage]);
        handleForceChangePositionButton();
    }

    const handleChangeOrientation = (flipEvent) => {
        setCurrentPage(flipEvent.object.pages.currentPageIndex);
    };

    const handleUnmountMagazine = () => {
        flipBook.current?.pageFlip()?.destroy();
        closeModal();
    }

    const onChangeState = (flipEvent) => {
        const escapedId = CSS.escape(id);
        const contentUpdate = document.querySelector(`.${escapedId}.magazine-modal.ant-modal`);
        if (contentUpdate && showCover) {
            if (flipEvent.object.pages.currentPageIndex === 0 && ["read"].includes(flipEvent.data)) {
                contentUpdate.classList.add("active");
                mountCloseAbleDiv(true);
            } else {
                if (["flipping", "user_fold"].includes(flipEvent.data)) {
                    contentUpdate.classList.remove("active");
                }
                mountCloseAbleDiv(false);
            }
        }

        setCurrentPage(flipEvent.object.pages.currentPageIndex);
    };

    const handleLoopReactPDF = () => {
        const arr = Array(totalPage);
        const arrKeys = arr.keys();
        const total = Array.from(arrKeys);
        const newPage = total.slice(0, currentPage <= totalPage - 6 ? currentPage + 6 : totalPage);
        if (renderPage.length < newPage.length) {
            setRenderPage([...newPage]);
        }
    };

    const mountCloseAbleDiv = (add) => {
        const escapedId = CSS.escape(id);
        const contentUpdate = document.querySelector(`.${escapedId}.magazine-modal.ant-modal .stf__wrapper`);
        if (contentUpdate) {
            if (add) {
                const el = document.createElement("div");
                el.onclick = handleUnmountMagazine;
                el.className = "magazine-closeable-div";
                el.style.zIndex = 1200;
                contentUpdate.appendChild(el);
            } else {
                const elements = document.querySelectorAll(`.${escapedId}.magazine-modal.ant-modal .magazine-closeable-div`);
                elements.forEach(el => {
                    contentUpdate.removeChild(el);
                });
            }
        }
    }

    useEffect(() => {
        if (opened && refresh) {
            setOpened(false);
        } else if (!opened && refresh) {
            setOpened(true);
            setRefresh(false);
        }
    }, [refresh, opened]);

    useEffect(() => {
        handleLoopReactPDF();
    }, [currentPage]);

    useEffect(() => {
        if (totalPage === 2) {
            setShowCover(false);
        } else {
            setShowCover(true);
        }
    }, [totalPage])

    useImperativeHandle(ref, () => ({
        handleUnmountMagazine: handleUnmountMagazine,
    }))

    return (
        opened &&
        <Document
            key={`${url}-${modalOpen}`}
            ref={documentRef}
            file={url}
            onLoadSuccess={onDocumentLoadSuccess}
            onError={() => handleForceChangePositionButton()}
            onLoadError={() => handleForceChangePositionButton()}
            error={<OnError handleTryAgain={() => setRefresh(true)} />}
            loading={
                <div className="text-center content-center" style={{ inlineSize: "1070px" }}>
                    <OnLoad />
                </div>
            }
        >
            <div className="document-layout">
                <div className="book-layout">
                    <HTMLFlipBook
                        ref={flipBook}
                        startZIndex={1200}
                        key={`${url}-${opened}`}
                        width={550}
                        height={733}
                        size="stretch"
                        minWidth={300}
                        maxWidth={600}
                        minHeight={450}
                        maxHeight={800}
                        maxShadowOpacity={1}
                        showCover={showCover}
                        mobileScrollSupport={true}
                        onChangeOrientation={handleChangeOrientation}
                        onChangeState={onChangeState}
                        onInit={() => {
                            const escapedId = CSS.escape(id);
                            const contentUpdate = document.querySelector(`.${escapedId}.magazine-modal.ant-modal`);
                            if (contentUpdate && showCover) {
                                contentUpdate.classList.add("active");
                                mountCloseAbleDiv(true);
                            }
                            handleForceChangePositionButton();
                        }}
                        className={`esi-magazine`}
                        startPage={0}
                        drawShadow={true}
                        flippingTime={1500}
                        usePortrait={true}
                        autoSize={true}
                        clickEventForward={false}
                        useMouseEvents={true}
                        swipeDistance={0}
                        showPageCorners={true}
                        disableFlipByClick={false}
                    >
                        {renderPage.map((pg) => {
                            return <MagazinePage key={pg + 1} pageNumber={pg + 1}></MagazinePage>;
                        })}
                    </HTMLFlipBook>
                </div>
            </div>
        </Document>
    )
});

const MagazinePage = forwardRef((props = { pageNumber: 0 }, ref) => {
    const { pageNumber } = props;
    return (
        <div className="page bg-white" style={{ zIndex: 1000 }} key={pageNumber} ref={ref}>
            <div className="page-content relative">
                <Page
                    pageNumber={pageNumber}
                    renderAnnotationLayer={false}
                />
            </div>
        </div>
    );
});

export default forwardRef(MagazineModal);