import * as React from 'react';

export const TickSvg = ({width, height}) => {
    return (
        <svg width={width ? width : "26"} height={height ? height : "26"} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="26" height="26" rx="6" fill="#46B988" />
            <path d="M7 13L11 17L18 10" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
}
