import { createSlice } from '@reduxjs/toolkit';

const authenticatedUserSlice = createSlice({
  name: 'authenticated-user',
  initialState: null,
  reducers: {
    setAuthenticatedUser: (state, action) => {
      return action.payload;
    },
    userLogout: () => {
      localStorage.removeItem('user')
      return null;
    },
  },
});

export const { setAuthenticatedUser, userLogout } = authenticatedUserSlice.actions;
export default authenticatedUserSlice.reducer;
