import * as React from 'react';

// Left
export const CoverSvg = ({ width, height }) => {
    return (
        <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect opacity="0.2" x="0.5" y="0.5" width="69" height="69" rx="9.5" fill="white" stroke="#636E88" />
            <g clip-path="url(#clip0_0_1)">
                <path d="M25.9818 23.6051H51.0131V26.5142H46.0793V47.1876H43.1702V26.5142H35.6618V47.1876H32.7527V37.4727H25.9818C24.1432 37.4727 22.38 36.7424 21.0799 35.4423C19.7798 34.1422 19.0494 32.379 19.0494 30.5404C19.0494 28.7018 19.7798 26.9385 21.0799 25.6385C22.38 24.3384 24.1432 23.608 25.9818 23.608M25.9818 34.5636H32.7527V26.5142H25.9818C24.9148 26.5142 23.8914 26.9381 23.1369 27.6926C22.3824 28.4471 21.9585 29.4704 21.9585 30.5375C21.9585 31.6045 22.3824 32.6278 23.1369 33.3823C23.8914 34.1369 24.9148 34.5607 25.9818 34.5607" fill="black" />
            </g>
            <defs>
                <clipPath id="clip0_0_1">
                    <rect width="32" height="32" fill="white" transform="translate(19 19)" />
                </clipPath>
            </defs>
        </svg>

    );
}

export const UnorderedListSvg = ({ width, height }) => {
    return (
        <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect opacity="0.2" x="0.5" y="0.5" width="69" height="69" rx="9.5" fill="white" stroke="#636E88" />
            <path d="M27.7594 24.6657H49.9094V27.5747H27.7594V24.6657ZM24.4078 28.3022H20.1378C20.1119 28.3022 20.0909 28.2813 20.0909 28.2554V23.9854C20.0909 23.9594 20.1119 23.9385 20.1378 23.9385H24.4078C24.4338 23.9385 24.4547 23.9594 24.4547 23.9854V28.2554C24.4547 28.2813 24.4334 28.3022 24.4078 28.3022ZM27.7594 33.9619H49.9094V36.871H27.7594V33.9619ZM24.4078 37.5982H20.1378C20.1119 37.5982 20.0909 37.5772 20.0909 37.5513V33.2816C20.0909 33.2557 20.1119 33.2347 20.1378 33.2347H24.4078C24.4338 33.2347 24.4547 33.2557 24.4547 33.2816V37.5516C24.4547 37.5772 24.4334 37.5982 24.4078 37.5982Z" fill="#222222" />
            <path d="M27.7594 43.2581H49.9094V46.1672H27.7594V43.2581ZM24.4078 46.8944H20.1378C20.1119 46.8944 20.0909 46.8735 20.0909 46.8475V42.5775C20.0909 42.5516 20.1119 42.5306 20.1378 42.5306H24.4078C24.4338 42.5306 24.4547 42.5516 24.4547 42.5775V46.8475C24.4547 46.8735 24.4334 46.8944 24.4078 46.8944Z" fill="#222222" />
        </svg>

    );
}

export const ButtonSvg = ({ width, height }) => {
    return (
        <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect opacity="0.2" x="0.5" y="0.5" width="69" height="69" rx="9.5" fill="white" stroke="#636E88" />
            <path d="M20.4546 49.5455V20.4546H49.5455V49.5455H20.4546Z" fill="#222222" />
            <rect x="23" y="23" width="24" height="24" fill="white" />
            <path d="M26.375 35C26.375 33.906 26.8096 32.8568 27.5832 32.0832C28.3568 31.3096 29.406 30.875 30.5 30.875H39.5C40.0417 30.875 40.5781 30.9817 41.0786 31.189C41.579 31.3963 42.0338 31.7001 42.4168 32.0832C42.7999 32.4662 43.1037 32.921 43.311 33.4214C43.5183 33.9219 43.625 34.4583 43.625 35C43.625 35.5417 43.5183 36.0781 43.311 36.5786C43.1037 37.079 42.7999 37.5338 42.4168 37.9168C42.0338 38.2999 41.579 38.6037 41.0786 38.811C40.5781 39.0183 40.0417 39.125 39.5 39.125H30.5C29.406 39.125 28.3568 38.6904 27.5832 37.9168C26.8096 37.1432 26.375 36.094 26.375 35ZM30.5 33.125C30.0027 33.125 29.5258 33.3225 29.1742 33.6742C28.8225 34.0258 28.625 34.5027 28.625 35C28.625 35.4973 28.8225 35.9742 29.1742 36.3258C29.5258 36.6775 30.0027 36.875 30.5 36.875H39.5C39.9973 36.875 40.4742 36.6775 40.8258 36.3258C41.1775 35.9742 41.375 35.4973 41.375 35C41.375 34.5027 41.1775 34.0258 40.8258 33.6742C40.4742 33.3225 39.9973 33.125 39.5 33.125H30.5Z" fill="#222222" />
        </svg>

    );
}


export const H1Svg = ({ width, height }) => {
    return (
        <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect opacity="0.2" x="0.5" y="0.5" width="69" height="69" rx="9.5" fill="white" stroke="#636E88" />
            <g clip-path="url(#clip0_0_1)">
                <path d="M33.4351 47.8033V36.4226H22.5565V47.8033H19.7113V22.1967H22.5565V33.5774H33.4336V22.1967H36.2788V47.8033H33.4351Z" fill="#222222" />
                <path d="M45.7177 47.8048V47.792H42.0375V45.6581H45.7177V34.5277C44.678 35.503 43.414 36.207 42.0375 36.5777V34.118C42.8322 33.9021 43.5944 33.5806 44.3037 33.162C45.0501 32.7198 45.731 32.1754 46.3266 31.5446H48.166V45.6567H51.0112V47.7906H48.166V47.8034L45.7177 47.8048Z" fill="#222222" />
            </g>
            <defs>
                <clipPath id="clip0_0_1">
                    <rect width="32" height="32" fill="white" transform="translate(19 19)" />
                </clipPath>
            </defs>
        </svg>

    );
}

export const H2Svg = ({ width, height }) => {
    return (
        <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect opacity="0.2" x="0.5" y="0.5" width="69" height="69" rx="9.5" fill="white" stroke="#636E88" />
            <path d="M39.7084 48.0909C39.6834 46.4863 40.2653 44.9315 41.3375 43.7374C42.4489 42.5894 43.6885 41.5728 45.032 40.7076C45.8668 40.1422 46.65 39.504 47.3724 38.8007C48.0733 38.0642 48.4742 37.0928 48.4967 36.0763C48.5256 35.6935 48.4729 35.3089 48.3423 34.9478C48.2117 34.5868 48.006 34.2576 47.7389 33.9818C47.1113 33.4774 46.3167 33.2281 45.5135 33.2836C45.065 33.2542 44.6163 33.3366 44.2075 33.5235C43.7988 33.7103 43.4428 33.9958 43.1716 34.3541C42.5638 35.2538 42.2581 36.3237 42.2989 37.4087H39.7971C39.7377 35.7716 40.3041 34.1733 41.3811 32.9389C41.9064 32.345 42.5578 31.8759 43.2875 31.5659C44.0173 31.2558 44.8071 31.1127 45.5993 31.1469C47.0212 31.0862 48.4114 31.5783 49.4785 32.52C49.9672 32.9775 50.3528 33.5339 50.6097 34.1522C50.8665 34.7704 50.9886 35.4363 50.968 36.1054C50.9519 37.5726 50.3802 38.9791 49.368 40.0414C48.3219 41.038 47.1848 41.9346 45.9716 42.7192C44.6422 43.4486 43.5448 44.5374 42.8051 45.8611H51V48.0909H39.7084ZM32.9433 48.0909V36.4545H21.9091V48.0909H19V21.9091H21.9091V33.5454H32.9418V21.9091H35.8509V48.0909H32.9433Z" fill="#222222" />
        </svg>

    );
}

export const H3Svg = ({ width, height }) => {
    return (
        <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect opacity="0.2" x="0.5" y="0.5" width="69" height="69" rx="9.5" fill="white" stroke="#636E88" />
            <g clip-path="url(#clip0_0_1)">
                <path d="M41.1091 46.9506C40.5138 46.4097 40.0356 45.7527 39.7038 45.02C39.3721 44.2873 39.194 43.4944 39.1804 42.6902H41.7055C41.6937 43.1726 41.7836 43.652 41.9693 44.0973C42.155 44.5426 42.4323 44.9438 42.7833 45.2749C43.423 45.85 44.2629 46.1508 45.1222 46.1128C46.0608 46.1485 46.976 45.8142 47.6705 45.1819C47.9458 44.9124 48.1633 44.5896 48.3096 44.2332C48.4559 43.8768 48.528 43.4943 48.5215 43.1091C48.5544 42.7032 48.4903 42.2952 48.3345 41.9189C48.1787 41.5425 47.9357 41.2087 47.6255 40.9448C46.9152 40.4291 46.0456 40.1808 45.1702 40.2437H43.9775V38.3353H45.1498C45.9636 38.3922 46.7704 38.1519 47.4204 37.6589C47.6846 37.4142 47.8915 37.1142 48.0265 36.7803C48.1615 36.4464 48.2213 36.0869 48.2015 35.7273C48.2219 35.3771 48.1691 35.0266 48.0463 34.698C47.9236 34.3694 47.7337 34.07 47.4887 33.8189C46.8576 33.322 46.0627 33.0805 45.2618 33.1426C44.419 33.0914 43.5889 33.3676 42.9447 33.9135C42.3384 34.53 41.9859 35.3518 41.9571 36.216H39.5018C39.5289 35.483 39.7035 34.763 40.0151 34.099C40.3268 33.4349 40.7691 32.8405 41.3156 32.3513C42.397 31.4015 43.8053 30.9091 45.2429 30.9782C46.6672 30.9044 48.0721 31.3332 49.2124 32.1899C49.6953 32.5993 50.0786 33.1133 50.3331 33.693C50.5877 34.2727 50.7068 34.9027 50.6815 35.5353C50.7198 36.3634 50.4714 37.1794 49.978 37.8456C49.4846 38.5118 48.7764 38.9873 47.9731 39.192C48.8247 39.4268 49.5901 39.9032 50.1767 40.5637C50.7425 41.2413 51.0364 42.1049 51.0015 42.9869C51.023 43.69 50.895 44.3897 50.626 45.0396C50.3569 45.6896 49.9529 46.275 49.4407 46.7571C48.2471 47.7729 46.7161 48.3043 45.1498 48.2466C43.6904 48.318 42.2547 47.8575 41.1091 46.9506ZM32.9433 48.2597V36.2917H21.9091V47.928H19V21.7462H21.9091V33.3826H32.9433V22.072H35.8524V48.2539L32.9433 48.2597Z" fill="#222222" />
            </g>
            <defs>
                <clipPath id="clip0_0_1">
                    <rect width="32" height="32" fill="white" transform="translate(19 19)" />
                </clipPath>
            </defs>
        </svg>

    );
}

export const H4Svg = ({ width, height }) => {
    return (
        <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect opacity="0.2" x="0.5" y="0.5" width="69" height="69" rx="9.5" fill="white" stroke="#636E88" />
            <path d="M33.4351 47.8033V36.4226H22.5565V47.8033H19.7113V22.1967H22.5565V33.5774H33.4336V22.1967H36.2788V47.8033H33.4351Z" fill="#222222" />
            <path d="M42.1026 45.0597V42.2745L49.0859 31.2727H51.4872V35.1278H50.0661L45.6637 42.0948V42.2255H55.5874V45.0597H42.1026ZM50.1314 48V44.2102L50.1967 42.9769V31.2727H53.5128V48H50.1314Z" fill="black" />
        </svg>

    );
}

export const H5Svg = ({ width, height }) => {
    return (
        <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect opacity="0.2" x="0.5" y="0.5" width="69" height="69" rx="9.5" fill="white" stroke="#636E88" />
            <path d="M46.4652 48.2287C45.3108 48.2287 44.2817 48.0163 43.3778 47.5916C42.4794 47.1669 41.7661 46.5816 41.2379 45.8356C40.7098 45.0896 40.4348 44.2347 40.413 43.271H43.8434C43.8815 43.9189 44.1538 44.4444 44.6602 44.8473C45.1665 45.2502 45.7682 45.4517 46.4652 45.4517C47.0206 45.4517 47.5107 45.3292 47.9354 45.0842C48.3655 44.8337 48.7004 44.4879 48.94 44.0469C49.185 43.6004 49.3075 43.0885 49.3075 42.5114C49.3075 41.9233 49.1823 41.406 48.9318 40.9595C48.6868 40.513 48.3465 40.1645 47.9109 39.9141C47.4753 39.6636 46.977 39.5356 46.4162 39.5302C45.9261 39.5302 45.4497 39.6309 44.9869 39.8324C44.5295 40.0339 44.1728 40.3088 43.9169 40.6573L40.7724 40.0938L41.5646 31.2727H51.7905V34.1641H44.4805L44.0476 38.354H44.1456C44.4396 37.9402 44.8834 37.5972 45.4769 37.3249C46.0704 37.0527 46.7347 36.9165 47.4698 36.9165C48.4772 36.9165 49.3756 37.1534 50.1651 37.6271C50.9547 38.1009 51.5781 38.7515 52.0355 39.5792C52.4929 40.4014 52.7189 41.3488 52.7134 42.4215C52.7189 43.5487 52.4575 44.5505 51.9293 45.4272C51.4066 46.2984 50.6742 46.9845 49.7322 47.4854C48.7957 47.9809 47.7067 48.2287 46.4652 48.2287Z" fill="black" />
            <path d="M32.9433 48.0909V36.4545H21.9091V48.0909H19V21.9091H21.9091V33.5454H32.9418V21.9091H35.8509V48.0909H32.9433Z" fill="#222222" />
        </svg>

    );
}

export const LinkSvg = ({ width, height }) => {
    return (
        <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect opacity="0.2" x="0.5" y="0.5" width="69" height="69" rx="9.5" fill="white" stroke="#636E88" />
            <path d="M42.8243 19.6122L32.4472 29.99L34.504 32.0469L42.8243 23.7256L46.8087 27.71L38.4878 36.0303L40.5447 38.0872L50.9225 27.71L42.8243 19.6122ZM28.1481 46.3706L24.1647 42.3862L32.485 34.0659L30.4281 32.0091L20.0509 42.3862L28.1481 50.4844L38.5253 40.1066L36.4684 38.0497L28.1481 46.3706Z" fill="#222222" />
            <path d="M30.5289 37.9489L38.3872 30.0906L40.444 32.1474L32.5857 40.0057L30.5289 37.9489Z" fill="#222222" />
        </svg>

    );
}

export const ImageSvg = ({ width, height }) => {
    return (
        <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect opacity="0.2" x="0.5" y="0.5" width="69" height="69" rx="9.5" fill="white" stroke="#636E88" />
            <path d="M20.4546 49.5455V20.4546H49.5455V49.5455H20.4546ZM23.3637 43.7273V46.6364H46.6364V41.435L39.9455 34.3004L23.3637 43.7273ZM23.3637 40.3819L40.507 30.6364L46.6364 37.1819V23.3637H23.3637V40.3819ZM26.4662 28.6248C26.4662 27.9389 26.7387 27.2811 27.2237 26.7961C27.7087 26.3111 28.3665 26.0386 29.0524 26.0386C29.392 26.0386 29.7283 26.1055 30.0421 26.2355C30.3559 26.3654 30.641 26.5559 30.8811 26.7961C31.1213 27.0362 31.3118 27.3213 31.4417 27.6351C31.5717 27.9489 31.6386 28.2851 31.6386 28.6248C31.6388 28.9645 31.572 29.301 31.4421 29.6149C31.3123 29.9288 31.1218 30.2141 30.8816 30.4544C30.6415 30.6947 30.3563 30.8853 30.0424 31.0154C29.7286 31.1455 29.3922 31.2124 29.0524 31.2124C28.7125 31.2122 28.3759 31.145 28.0619 31.0147C27.748 30.8844 27.4628 30.6935 27.2226 30.453C26.9824 30.2124 26.792 29.9269 26.6622 29.6127C26.5324 29.2985 26.4658 28.9618 26.4662 28.6219V28.6248Z" fill="#222222" />
        </svg>

    );
}

export const AccordionSvg = ({ width, height }) => {
    return (
        <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect opacity="0.2" x="0.5" y="0.5" width="69" height="69" rx="9.5" fill="white" stroke="#636E88" />
            <path d="M23.3333 25.1H23.1833V25.25V28.5V28.65H23.3333H26.6667H26.8167V28.5V25.25V25.1H26.6667H23.3333ZM23.3333 41.35H23.1833V41.5V44.75V44.9H23.3333H26.6667H26.8167V44.75V41.5V41.35H26.6667H23.3333ZM33.4833 22.15H49.85V25.1H33.4833V22.15ZM33.4833 28.65H43.1833V31.6H33.4833V28.65ZM33.4833 38.4H49.85V41.35H33.4833V38.4ZM33.4833 44.9H43.1833V47.85H33.4833V44.9ZM20.15 22.15H29.85V31.6H20.15V22.15ZM20.15 38.4H29.85V47.85H20.15V38.4Z" fill="#222222" stroke="white" stroke-width="0.3" />
        </svg>

    );
}

// Right
export const UnderlineSvg = ({ width, height }) => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.7">
                <path d="M8.96006 15.2805C11.9431 15.2805 14.3701 12.8535 14.3701 9.87049V1.03906H12.7337V9.87049C12.7337 11.9516 11.041 13.6442 8.96006 13.6442C6.87916 13.6442 5.18639 11.9514 5.18639 9.87049V1.03906H3.55004V9.87049C3.55004 12.8535 5.97705 15.2805 8.96006 15.2805ZM0.77832 16.3303H17.142V17.9666H0.77832V16.3303Z" fill="#222222" />
            </g>
        </svg>


    );
}

export const ItalicSvg = ({ width, height }) => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.7">
                <path d="M13.7058 2.28035V0.776367H9.19384V2.28035H10.4389L6.37888 15.7958H4.68188V17.2998H9.19384V15.7958H7.94878L12.009 2.28035H13.7058Z" fill="#222222" />
            </g>
        </svg>


    );
}

export const AlignLetfSvg = ({ width, height }) => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.7">
                <path d="M0 1.63635H18V3.27272H0V1.63635Z" fill="#222222" />
                <path d="M0 5.8418H13.0909V7.47816H0V5.8418Z" fill="#222222" />
                <path d="M0 14.252H13.0909V15.8883H0V14.252Z" fill="#222222" />
                <path d="M0 10.0465H18V11.6837H0V10.0465Z" fill="#222222" />
            </g>
        </svg>


    );
}

export const AlignCenterSvg = ({ width, height }) => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.7">
                <path d="M0 1.63635H18V3.27272H0V1.63635Z" fill="#222222" />
                <path d="M2.45459 5.84094H15.5455V7.47731H2.45459V5.84094Z" fill="#222222" />
                <path d="M2.45459 14.2511H15.5455V15.8875H2.45459V14.2511Z" fill="#222222" />
                <path d="M0 10.0465H18V11.6829H0V10.0465Z" fill="#222222" />
            </g>
        </svg>


    );
}

export const AlignRigthSvg = ({ width, height }) => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 1.63635H18V3.27272H0V1.63635Z" fill="#222222" />
            <path d="M4.90918 5.84094H18.0001V7.47731H4.90918V5.84094Z" fill="#222222" />
            <path d="M4.90918 14.252H18.0001V15.8883H4.90918V14.252Z" fill="#222222" />
            <path d="M0 10.0465H18V11.6829H0V10.0465Z" fill="#222222" />
        </svg>


    );
}

export const AlignJustifySvg = ({ width, height }) => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.7">
                <path d="M0 1.63635H18V3.27353H0V1.63635Z" fill="#222222" />
                <path d="M0 5.8418H18V7.47816H0V5.8418Z" fill="#222222" />
                <path d="M0 14.2527H18V15.889H0V14.2527Z" fill="#222222" />
                <path d="M0 10.0472H18V11.6836H0V10.0472Z" fill="#222222" />
            </g>
        </svg>


    );
}

export const BackMobleSvg = ({ width, height }) => {
    return (
        <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.72 5.47284L5.01 1.21583C5.3 0.928058 5.78 0.928058 6.07 1.21583C6.36 1.5036 6.36 1.97991 6.07 2.26768L3.06 5.25453H12.75C13.16 5.25453 13.5 5.59191 13.5 5.99876C13.5 6.40561 13.16 6.74299 12.75 6.74299H3.06L6.07 9.72984C6.36 10.0176 6.36 10.4939 6.07 10.7817C5.92 10.9305 5.73 11 5.54 11C5.35 11 5.16 10.9305 5.01 10.7817L0.72 6.52468C0.58 6.38576 0.5 6.19722 0.5 5.99876C0.5 5.8003 0.58 5.61176 0.72 5.47284Z" fill="#636E88" stroke="#636E88" stroke-width="0.3" />
        </svg>
    );
}



