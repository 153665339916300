import React, { useState } from 'react';
import {
    ArrowLeftOutlined
} from '@ant-design/icons';
import { Button, Layout, theme } from 'antd';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
const { Header, Content } = Layout;

const HeaderCreateComponent = ({isHide, backTitle, onClick, bntTitle}) => {
    const [collapsed, setCollapsed] = useState(false);
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');

    return (
        <div className='w-100 h-100'>
            <Layout style={{ height: "100" }} className='w-100 h-100 mymain'>
                <Layout style={{overflow: "hidden"}}>
                    <Header
                        style={{
                            position: 'sticky',
                            top: 0,
                            zIndex: 1,
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            padding: 0,
                            background: colorBgContainer,
                            borderBottom: "1px solid #dcdcdcd4",
                        }}
                    >
                        
                        <div className="w-100 bg-white- ps-4 pe-4" >
                            <div className="d-flex justify-content-between align-items-center ps-4- m-0">
                                <h4 className="m-0 text-wrap-nowrap"><span onClick={onClick && onClick} style={{cursor: "pointer"}}> <ArrowLeftOutlined /></span> {id ? "Edit" : "Create"} {backTitle || ''}</h4>
                                <div className="d-flex align-items-center">
                                    <div className="align-items-center d-flex">
                                        <div
                                            style={{
                                                cursor: 'pointer',
                                            }}
                                            className="userGroup"
                                        >
                                            <Button
                                                // type="text"
                                                className='b-danger'
                                                type="primary" 
                                                onClick={() => setCollapsed(!collapsed)}
                                                style={{
                                                    fontSize: '16px',
                                                }}
                                                danger
                                            >
                                                {bntTitle || ''}
                                            </Button> 
                                            {/* <ButtontSubmitComponent size={"middle"} title={`Create`} /> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Header>

                    <Content
                        className='h-100 w-100 cus-content'
                        style={{
                            background: "#fff",
                            overflow: 'auto',
                            // padding: "32px",
                            // overflow: "auto"
                        }}
                    >
                        <Outlet />
                    </Content>

                </Layout>
            </Layout>
        </div>
    );
};
export default HeaderCreateComponent;