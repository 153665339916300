import React, { useEffect, useRef, useState } from "react";
import NewHeader from "./NewHeader";
import NewsTabs from "./TableListNews";
import image from "../../assets/images/News1.png"
import image1 from "../../assets/images/New2.png"
import { deleteDataRequest, getDataRequest, putDataRequest } from "../../api/serviceMethods";
import { NEWS_GET_URL } from "../../api/URLs";
import { useTranslation } from "react-i18next";
import { Spin } from "antd";
import { formatDateSecondary } from "../../utils/dateFormatUtils";
import MessageConfirmModal from "../../components/messageModal/MessageConfirmModal";
import MobileViewTextModal from "../../components/newsManagemet/MobileViewTextModal";

const NewManagementPage = () => {
  const [viewType, setViewType] = useState('table');
  const messageModalRef = useRef('')
  const [isListView, setIsListView] = useState(true);
  const [dates, setDates] = useState([null, null]);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
    },
  });
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true);
  const [keyword, setkeyword] = useState('');
  let dataSource = data ? data : []
  const { t } = useTranslation()

  const getDataRequestAll = async(value) => {
    setLoading(true)
    await getDataRequest(`${NEWS_GET_URL}`, {
      pageNo: tableParams.pagination.current,
      pageSize: tableParams.pagination.pageSize,
      keyword: value ? value.trim() : '',
      startDate: dates[0] ? formatDateSecondary(dates[0]) : "",
      endDate: dates[1] ? formatDateSecondary(dates[1]) : "",
      sortKeys: { [tableParams.sortField]: tableParams.sortOrder === "ascend" ? "asc" : "desc" },
    }).then((res) => {
      if (res.status === 200) {
        let datas = res?.data ? res?.data.map((it , i) => ({
          ...it,
          key: it._id,
          cover: it.coverimage && it.coverimage ? it.coverimage.url : "",
          // title: "",
          // subtitle: "",
          category: it.category,
          releaseTime: it.expiryAt ? it.expiryAt : '',
          account: it.createdBy && it.createdBy ? it.createdBy : '',
          active: it.isActive
        })) : []
        setData(datas);
      }
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: res.total,
        },
      });
      setLoading(false);
    }).catch((err) => {
      setLoading(false)
      console.log("Error", err)
    });
  }
  useEffect(() => {
    getDataRequestAll(keyword)
  }, [
    tableParams.pagination?.current,
    tableParams.pagination?.pageSize,
    tableParams?.sortOrder,
    tableParams?.sortField,
    JSON.stringify(tableParams.filters),
  ]);

  const handleStatus  = async(item) => {
    try {
      setLoading(true);
      await putDataRequest(`${NEWS_GET_URL}/${item.newsId} `, {isActive: !item.active})
        .then((res) => {
          if (res.status === 200) {

            messageModalRef.current.showSuccessConfirmsAutoClose(res && res.message, () => { }, "", true);
            getDataRequestAll('');
            setTimeout(() => {
              setLoading(false);
            }, 2000);
          } else {
            messageModalRef.current.showWarningConfirmsAutoClose(res && res.message, () => { }, "", true);
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          messageModalRef.current.showWarningConfirmsAutoClose(err && err.response && err.response.data && err.response.data.message, () => { }, "", true);
        });
    } catch (error) {
      setLoading(false)
      messageModalRef.current.showWarningConfirmsAutoClose(`Server Error!`, () => { }, "", true);
    }
  }

  const handleDelete = async (item) => {
    messageModalRef.current.showWarningConfirm(t('Are you sure you want to delete this news?'), async () => {
      setLoading(true)
      await deleteDataRequest(`${NEWS_GET_URL}/${item.newsId} `).then((res) => {
        messageModalRef.current.showSuccessConfirmsAutoClose(res && res.message, () => { }, "", true);
        getDataRequestAll('');
        setLoading(false)
      }).catch((err) => {
        messageModalRef.current.showWarningConfirmsAutoClose(`Server Error!`, () => { }, "", true);
        setLoading(false)
      });
    }, "", true);
  };

  const [newsId, setNewsId] = useState(false)
  const handleView = async(item) => {
    setNewsId(item.newsId)
  }

  return (
    <div className="box-shadow h-100 w-100">
      <div className="w-100 h-100 overflow-auto">
        <div className="w-100">
          <Spin spinning={loading}>
          <div className="w-100 mt-3" style={{ padding: "0px 25px" }}>
            <NewHeader
              viewType={viewType}
              setViewType={setViewType}
              isListView={isListView} setIsListView={setIsListView}
              dates={dates} setDates={setDates}
              keyword={keyword} setkeyword={setkeyword}
              tableParams={tableParams} setTableParams={setTableParams}
              getDataRequestAll={getDataRequestAll}
            />
            <NewsTabs
              dataSource={dataSource ? dataSource : []}
              viewType={viewType} setViewType={setViewType} 
              tableParams={tableParams} setTableParams={setTableParams}
              getDataRequestAll={getDataRequestAll}
              handleStatus={handleStatus}
              handleDelete={handleDelete}
              handleView={handleView}
              />
          </div>
          </Spin>
        </div>
      </div>
      <MobileViewTextModal id={newsId} show={newsId} setShow={setNewsId} />
      <MessageConfirmModal textCentered ref={messageModalRef} />
    </div>
  )
}

export default NewManagementPage