import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import EsiApp from './App';
import reportWebVitals from './reportWebVitals';
import './i18n/config'
import { BrowserRouter } from 'react-router-dom';
import store from './stores';
import { Provider } from 'react-redux';
import { App } from 'antd';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App className='h-100'>
    <BrowserRouter>
      {/* <React.StrictMode> */}
        <Provider store={store}>
          <EsiApp />
        </Provider>
      {/* </React.StrictMode> */}
    </BrowserRouter>
  </App>
);
reportWebVitals();
