import React, { useEffect, useRef, useState } from 'react';
import { Input, Button, Space, Divider, Row, Col, Modal, Tag, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import AddSvg from '../../assets/images/AddSvg';
import TrashSvg from '../../assets/images/TrashSvg';
import CategorySvg from '../../assets/images/CategorySvg';
import { NEWS_CATEGORY_URL } from '../../api/URLs';
import { deleteDataRequest, getDataRequest, postDataRequest } from '../../api/serviceMethods';
import MessageConfirmModal from '../../components/messageModal/MessageConfirmModal';

const NewHeader = ({ }) => {
    const messageModalRef = useRef('')
    const [loading, setLoading] = useState(true);
    const [modalOpen, setModalOpen] = useState(false)
    const { t } = useTranslation();
    const [newTag, setNewTag] = useState("");
    const [tags, setTags] = useState([
        // {
        //     name: ""
        // }
    ]);
    const [tableParams, setTableParams] = useState({
        pagination: {
            // current: 1,
            // pageSize: 10,
            // total: 0,
        },
    });
    
    const getDataRequestAll = async (value) => {
        setLoading(true)
        await getDataRequest(`${NEWS_CATEGORY_URL}`, {
            pageNo: tableParams.pagination.current,
            pageSize: tableParams.pagination.pageSize,
            //   keyword: value ? value.trim() : '',
            //   createdAt: dates ? formatDateSecondary(dates) : '',
        }).then((res) => {
            if (res.status === 200) {
                let datas = res?.data ? res?.data.map((it, i) => ({
                    ...it,
                    name: it.name || "",
                })) : []
                setTags(datas);
            }
            setTableParams({
                ...tableParams,
                pagination: {
                    ...tableParams.pagination,
                    total: res.total,
                },
            });
            setLoading(false);
        }).catch((err) => {
            setLoading(false)
            console.log("Error", err)
        });
    }
    useEffect(() => {
        // modalOpen && getDataRequestAll()
        getDataRequestAll()
    }, [modalOpen])

    const handleCreate  = async(name) => {
        try {
          setLoading(true);
          await postDataRequest(`${NEWS_CATEGORY_URL}`, { name: name })
            .then((res) => {
              if (res.status === 200) {
                setNewTag("");
                messageModalRef.current.showSuccessConfirmsAutoClose(res && res.message, () => { }, "", true);
                getDataRequestAll('');
                setTimeout(() => {
                  setLoading(false);
                }, 2000);
              } else {
                messageModalRef.current.showWarningConfirmsAutoClose(res && res.message, () => { }, "", true);
              }
              setLoading(false);
            })
            .catch((err) => {
              setLoading(false);
              messageModalRef.current.showWarningConfirmsAutoClose(err && err.response && err.response.data && err.response.data.message, () => { }, "", true);
            });
        } catch (error) {
          setLoading(false)
          messageModalRef.current.showWarningConfirmsAutoClose(`Server Error!`, () => { }, "", true);
        }
      }
    
    const handleAddTag = () => {
        if (newTag.trim() !== "") {
            let filterData = tags.filter(it => it.name === newTag.trim())
            if(filterData.length > 0) {
                messageModalRef.current.showWarningConfirmsAutoClose(t('The category has exited!'), () => { }, "", true);
                return false
            }
            handleCreate(newTag.trim() )
            // setTags([...tags, { name: newTag }]);
            // setNewTag("");
        }
    };

    const handleDeleteTag = (tag, i) => {
        if (tag._id) {
            messageModalRef.current.showWarningConfirm(t('Are you sure you want to delete this category?'), async () => {
                setLoading(true)
                await deleteDataRequest(`${NEWS_CATEGORY_URL} `, { name: tag.name }).then((res) => {
                    messageModalRef.current.showSuccessConfirmsAutoClose(res && res.message, () => { }, "", true);
                    getDataRequestAll('');
                    setLoading(false)
                }).catch((err) => {
                    messageModalRef.current.showWarningConfirmsAutoClose(`Server Error!`, () => { }, "", true);
                    setLoading(false)
                });
            }, "", true);
        } else {
            setTags(tags.filter((t) => t !== tag));
        }
    };

    return (
        <>
            <Button
                // type="text"
                className=''
                type="text"
                onClick={() => setModalOpen(true)}
                style={{
                    fontSize: '16px',
                    marginRight: "1rem",
                    color: "#e72158",
                    border: "1px solid #e72158"
                }}
            >
                {t('Category Management')}
            </Button>
            <Modal
                title={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <CategorySvg />
                        <span style={{ marginLeft: "10px" }}>{t('Category Management')}</span>
                    </div>
                }
                centered
                width={700}
                open={modalOpen}
                className='custom-modal'
                onOk={() => setModalOpen(false)}
                onCancel={() => {
                    setModalOpen(false)
                    setTags([])
                }}
                cancelButtonProps={{ style: { display: 'none' } }}
                okButtonProps={{ style: { display: 'none' } }}
            >
                <Spin spinning={loading}>
                <hr />
                <div style={{ padding: "20px 60px", marginBottom: "10px" }}>
                    <Row className='d-flex align-items-center'>
                        <Col md={6}>{t("New Category")} :</Col>
                        <Col md={16}>
                            <Input
                                size='large'
                                style={{ marginRight: "8px" }}
                                placeholder=""
                                value={newTag}
                                onChange={(e) => setNewTag(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        handleAddTag(); // Call the function when Enter is pressed
                                    }
                                }}
                            />
                        </Col>
                        <Col md={2}>
                            <Button
                                style={{
                                    backgroundColor: "#F8E2E8",
                                    border: "none",
                                    color: "#E50A47",
                                    height: "26px",
                                    width: "26px",
                                    marginLeft: "10px",
                                    borderRadius: "50%"
                                }}
                                icon={<AddSvg />}
                                onClick={handleAddTag}
                            /></Col>
                    </Row>
                </div>
                <div style={{ borderBottom: "1px dashed #ddd", marginBottom: "16px" }}></div>
                <div className='d-flex align-items-baseline' style={{ padding: "10px 60px", }}>
                    <Col md={4} >{t("Category")} :</Col>
                    <div style={{ marginTop: "8px", display: "flex", flexWrap: "wrap", gap: "8px" }}>
                        {tags.map((tag, index) => (
                            <Tag
                                key={index}
                                closable
                                onClose={() => handleDeleteTag(tag)}
                                closeIcon={
                                    <div className='d-flex align-items-center'>
                                        <div style={{ height: "18px", width: "1.5px", backgroundColor: "white", marginLeft: "5px", marginRight: "5px" }}></div>
                                        <TrashSvg style={{ cursor: "pointer" }} />
                                    </div>
                                }
                                style={{
                                    backgroundColor: "#fdecf1",
                                    color: "#E50A47",
                                    width: "auto",
                                    height: "25px",
                                    border: "none",
                                    display: "flex",
                                    alignItems: "center",
                                    borderRadius: "6px",
                                    fontSize: "12px",
                                    fontWeight: 500
                                }}
                            >
                                <div style={{
                                    width: "5px",
                                    height: "5px",
                                    backgroundColor: "#E50A47",
                                    borderRadius: "50%",
                                    marginRight: "5px"
                                }}></div>
                                {tag.name || ""}
                            </Tag>
                        ))}
                    </div>
                </div>
                <div className='d-flex justify-content-center mt-3'>
                    <Button
                        disabled={tags.length > 0 ? false : true}
                        style={{
                            backgroundColor: "#E50A47",
                            border: "none",
                            color: "White",
                            height: "45px",
                            width: "230px",
                            margin: "auto",
                            borderRadius: "10px",
                            fontSize: "16px",
                            fontWeight: 500,
                            opacity: tags.length > 0 ? 1 : 0.5
                        }}
                        onClick={() => setModalOpen(false)}
                    >
                        {t("Confirm Submit")}
                    </Button>
                </div>
                </Spin>
            </Modal>
            <MessageConfirmModal textCentered ref={messageModalRef} />
        </>
    );
};

export default NewHeader;
