import React, { useState } from 'react';
import { Input, Button, Space, Divider, Row, Col, Modal, Tag } from 'antd';
import { SearchOutlined, AppstoreOutlined, BarsOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import UploadSvg from '../../assets/images/uploadSvg';
import DateComponent from '../../components/date/DateComponent';
import { useNavigate } from 'react-router-dom';
import AddSvg from '../../assets/images/AddSvg';
import TrashSvg from '../../assets/images/TrashSvg';
import CategorySvg from '../../assets/images/CategorySvg';
import CategoryModal from './CategoryModal';
import SearchSvg from '../../assets/images/SearchSvg';
import DateRangeComponent from '../../components/date/DateRangeComponent';

const NewHeader = ({ 
    viewType,
    setViewType,
    isListView,
    setIsListView,
    dates,
    setDates,
    keyword,
    setkeyword,
    tableParams,
    setTableParams,
    getDataRequestAll,
    handleStatus,
  }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <>
            <Space
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                }}
                size="small"
            >
                <Input
                    placeholder={t("Search")}
                    prefix={<SearchSvg style={{ color: '#8c8c8c' }} />}
                    size='large'
                    allowClear
                    onChange={(e) => {
                        const value = e.target.value;
                        setkeyword(value);
                        // if (value === "") {
                        //     setTableParams({
                        //         ...tableParams,
                        //         pagination: {
                        //             ...tableParams.pagination,
                        //             current: 1,
                        //             total: 0,
                        //         },
                        //     });
                        //     getDataRequestAll(value);
                        // }
                    }}
                    onPressEnter={async (e) => {
                        // const value = e.target.value;
                        // setTableParams({
                        //     ...tableParams,
                        //     pagination: {
                        //         ...tableParams.pagination,
                        //         current: 1,
                        //         total: 0,
                        //     },
                        // });
                        // getDataRequestAll(value);
                    }}
                />
                <DateRangeComponent
                    className="col-8 me-4"
                    placeholder={t("Please select a time or time period")}
                    value={dates ? dates : [null, null]}
                    setValue={setDates}
                />
                <Button size="large" 
                onClick={() => {
                    setTableParams({
                        ...tableParams,
                        pagination: {
                            ...tableParams.pagination,
                            current: 1,
                            total: 0,
                        },
                    });
                    getDataRequestAll(keyword)
                }}
                >
                    {t('Query')}
                </Button>
                <Divider type="vertical" style={{ height: '24px', borderColor: '#d9d9d9' }} />

                <Space size="small">
                    <Button
                        className='newStyleTabs'
                        type={isListView ? 'text' : 'default'}
                        icon={<AppstoreOutlined />}
                        onClick={() => {
                            setIsListView(false)
                            setViewType('card')
                        }}
                        style={{ padding: 0, color: isListView ? '#636E88' : '#E50A47' }}
                    />
                    <Button
                        type={isListView ? 'default' : 'text'}
                        icon={<BarsOutlined />}
                        onClick={() => {
                            setIsListView(true)
                            setViewType('table')
                        }}
                        style={{ padding: 0, color: isListView ? '#E50A47' : '#636E88' }}
                    />
                </Space>
                <Divider type="vertical" style={{ height: '24px', borderColor: '#d9d9d9' }} />
                <CategoryModal />
                <Button
                    icon={<UploadSvg />}
                    className=''
                    // type="primary" 
                    onClick={() => navigate("/news/upload")}
                    style={{
                        fontSize: '16px',
                        backgroundColor: "#F8E2E8",
                        border: "none",
                        color: "#E50A47"
                    }}
                    danger
                >
                    {t('Create news')}
                </Button>
            </Space>
        </>
    );
};

export default NewHeader;
