import { Card, Col, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import HeaderDetailComponent from "../../components/header/headerDetailComponent";
import { useLocation, useNavigate } from "react-router-dom";
import { MEMBER_SUBSCRIPTION_URL } from "../../api/URLs";
import { getDataRequest } from "../../api/serviceMethods";
import { formatDateTime } from "../../utils/dateFormatUtils";

const PaymentHistory = () => {
    const [loading, setLoading] = useState(false);
    const [subscriptions, setSubscriptions] = useState([]);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const userDetailId = params.get("id");

    const fetchSubscriptionDetails = async () => {
        try {
            setLoading(true);
            const response = await getDataRequest(`${MEMBER_SUBSCRIPTION_URL}/${userDetailId}`);
            if (response.status === 200) {
                setSubscriptions(response.data || []);
            }
        } catch (err) {
            console.error("Error fetching user details:", err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (userDetailId) {
            fetchSubscriptionDetails();
        }
    }, [userDetailId]);

    const getMembershipLabel = (plan, isFirstProPlan) => {
        switch (plan) {
            case "free":
                return "Membership trial";
            case "pro":
                return isFirstProPlan ? "Open Membership" : "Review Membership";
            default:
                return "Unknown Membership";
        }
    };

    // Enhance subscription data with labels
    let isFirstProPlan = true;
    const enhancedSubscriptions = subscriptions.map((item) => {
        if (item.plan === "pro" || item.plan === "free") {
            const membershipLabel = getMembershipLabel(item.plan, isFirstProPlan);
            if (item.plan === "pro") isFirstProPlan = false;
            return { ...item, membershipLabel };
        }
        return { ...item, membershipLabel: "Unknown Membership" };
    });

    return (
        <HeaderDetailComponent
            backgroundColor="#f8e2e8"
            border="1px solid #e72158"
            isHide="view"
            onClick={() => navigate(`/member/details?id=${userDetailId}`)}
            backTitle={t("Member History")}
            hideTitle="userDetails"
        >
            <div className="container newDetail">
                <Spin size="large" tip={t("Loading...")} spinning={loading}>
                    <div className="w-100 h-100" style={{ padding: "1rem 2rem" }}>
                        {enhancedSubscriptions.map((sub, index) => (
                            <div key={index} className="row gx-0 d-flex justify-content-center align-items-center">
                                <div className="col-12 d-flex justify-content-between">
                                    <div className="d-flex justify-content-center align-items-center">
                                        <IconCircle />
                                        <span className="ms-3 chart_milestone">
                                            {formatDateTime(sub.createdTS)}
                                        </span>
                                    </div>
                                </div>
                                <div className="col-12 d-flex justify-content-center align-items-center ps-1">
                                    <div
                                        className="w-100 h-100"
                                        style={{
                                            paddingLeft: "1.5rem",
                                            borderLeft: "0.125rem dashed #F8E2E8",
                                        }}
                                    >
                                        <Card style={{ width: "100%", marginTop: "0.5rem" }}>
                                            <p className="userTitle">{t(sub.membershipLabel)}</p>
                                            <Row align="middle">
                                                <Col span={8} className="userLabel">
                                                    {t("Member Operation time")}:
                                                </Col>
                                                <Col className="text-capitalize">
                                                    {formatDateTime(sub.startedTS)}
                                                </Col>
                                            </Row>
                                            <Row align="middle" className="mt-3">
                                                <Col span={8} className="userLabel">
                                                    {t("Membership expired date")}:
                                                </Col>
                                                <Col className="text-capitalize">
                                                    {formatDateTime(sub.endedTS)}
                                                </Col>
                                            </Row>
                                        </Card>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </Spin>
            </div>
        </HeaderDetailComponent>
    );
};

const IconCircle = () => (
    <div
        style={{
            width: "0.75rem",
            height: "0.75rem",
            borderRadius: "100%",
            background: "#E50A47",
        }}
    />
);

export default PaymentHistory;
