import React, { useEffect, useRef, useState } from 'react';
import { STAFF_CREATE_URL, STAFF_DETAIL_URL } from '../../api/URLs';
import './staff-create.css';
import '../member-pages/style.css';
import { Button, Card, Checkbox, Col, Image, Input, message, Row, Select, Spin, Switch, Upload, } from 'antd';
import InputComponent from '../../components/input/InputComponent';
import { getDataRequest, postDataRequest, putDataRequest } from '../../api/serviceMethods';
import MessageConfirmModal from '../../components/messageModal/MessageConfirmModal';
import { useLocation, useNavigate } from 'react-router-dom';
import HeaderCreateStaffComponent from '../../components/header/HeaderCreateStaffComponent';
import { useTranslation } from 'react-i18next';
import { permissionsHave } from '../../utils/permissionsHave';
import { validateEmail } from '../../utils/Validate';
import UploadComponent from '../../components/upload/UploadComponent';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setAuthenticatedUser } from '../../stores/authenticated-user.store';


function StaffCreatePage() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');
  const { t } = useTranslation();
  const user = useSelector((state) => state.authenticatedUser);
  let isSameUser = user && user.staffId === id
  const dispatch = useDispatch();

  const [isSubmit, setIsSubmit] = useState(false)
  const [loading, setLoading] = useState(false);
  const messageModalRef = useRef("");
  const navigate = useNavigate();

  const [dataObject, setDataObject] = useState({
    "email": "",
    "name": "",
    "permissions": [], //["用户管理", "收款管理", "杂志管理", "资讯管理", "账户管理"]
    "status": true
  });
  const [picUrl, setpicUrl] = useState("")

  const checkError = () => {
    if (
      !dataObject.email ||
      !dataObject.name ||
      // !picUrl ||
      !dataObject.permissions ||
      !dataObject.permissions.length === 0
    ) {
      return true
    }
    if (!validateEmail(dataObject.email)) {
      return true
    }
    return false
  }

  const addNewValue = async (data) => {
    setDataObject(
      {
        "email": data.email,
        "name": data.name,
        "picUrl": data.picUrl ? data.picUrl : "",
        "permissions": data.permissions ? data.permissions : [],
        "status": data.status ? data.status : false,
      }
    )
    setpicUrl(data.picUrl ? data.picUrl : "")
  }

  // get member Id
  const getMemberById = async () => {
    try {
      setLoading(true);
      await getDataRequest(`${STAFF_DETAIL_URL}/${id && id}`)
        .then((res) => {
          if (res) {
            addNewValue(res)
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
      message.error('Server Error!');
    }
  }
  useEffect(() => {
    if (id && id) { getMemberById() }
  }, []);

  // update Member
  const insertParams = () => {
    const memberPayload = {
      "email": dataObject.email,
      "name": dataObject.name,
      "picUrl": picUrl,
      "permissions": dataObject.permissions,
      "status": dataObject.status
    }
    return memberPayload
  }
  const hadleUpdateStaff = async (e) => {
    e.preventDefault();
    setIsSubmit(true);
    try {
      const memberPayload = insertParams()
      if (checkError()) {
        return false;
      }
      setLoading(true);
      await putDataRequest(`/staffs/update/${id && id} `, memberPayload)
        .then((res) => {
          if (res.status === 200) {
            setLoading(false);
            if(isSameUser) {
              let userNew = {...user}
              userNew.name = dataObject.name
              userNew.email = dataObject.email
              userNew.picUrl = picUrl
              dispatch(setAuthenticatedUser(userNew));
            }
            messageModalRef.current.showSuccessConfirmsAutoClose(res && res.message, () => { }, "", true);
            setTimeout(() => {
              navigate("/staff/search")
            }, 2000);
          } else {
            messageModalRef.current.showWarningConfirmsAutoClose(res && res.message, () => { }, "", true);
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          messageModalRef.current.showWarningConfirmsAutoClose(err && err.response && err.response.data && err.response.data.message, () => { }, "", true);
        });
    } catch (error) {
      console.log("error", error)
      setLoading(false);
      message.error('Server Error!');
    }
  }

  // Save member
  const handleSubmit = async () => {
    setIsSubmit(true)
    if (checkError()) {
      return false
    }
    setLoading(true);
    const memberPayload = insertParams()
    try {
      const res = await postDataRequest(STAFF_CREATE_URL, memberPayload);
      if (res?.status === 200) {
        messageModalRef.current.showSuccessConfirmsAutoClose(res.data?.message || t("Saved Successfully"), () => { }, "", true);
        setTimeout(() => {
          navigate("/staff/search")
        }, 2000);
      } else {
        messageModalRef.current.showWarningConfirmsAutoClose(res.data?.message || res.message || t("An error occurred."), () => { }, "", true);
      }
    } catch (err) {
      messageModalRef.current.showWarningConfirmsAutoClose(err && err.response && err.response.data && err.response.data.message, () => { }, "", true);
    } finally {
      setLoading(false);
      setIsSubmit(false);
    }
  }

  const permissions = permissionsHave()
  const onChangePermission = (e) => {
    if (e.target.checked) {
      dataObject.permissions.push(e.target.value)
      setDataObject({ ...dataObject })
    } else {
      dataObject.permissions = dataObject.permissions.filter(it => it !== e.target.value)
      setDataObject({ ...dataObject })
    }
  };
  const checkIsAccess = (access) => {
    return dataObject.permissions.includes(access)
  }


  return (
    <HeaderCreateStaffComponent
      bntTitle={<>
        <Button
          className='b-danger'
          disabled={loading}
          type="primary"
          onClick={id ? hadleUpdateStaff : handleSubmit}
          style={{
            fontSize: '16px',
            backgroundColor: "#E50A47",
            border: "1px solid #e72158",
            color: "#FFFFFF",
            opacity: loading ? '0.4' : ""
          }}
          danger
        >
          {id ? t("Submit") : t(`Submit`)}
        </Button>
      </>}
      isHide="view"
      onClick={() => {
        navigate('/staff/search')
      }}
      backTitle={t('btnCreateAccount')}
    >
      <Spin spinning={loading}>
        <div className='container newDetail'>
          <Row>
            <Col md={5}>
              <UploadComponent
                fileList={picUrl ? [{
                  "uid": "1",
                  "name": "",
                  "status": "done",
                  "url": picUrl
                }] : []}
                setFileList={(values) => {
                  setpicUrl(values.url)
                }}
                // isError={isSubmit && !picUrl && `error`}
              />
            </Col>
            <Col md={19}>
              <Row>
                <Card className='w-100'>
                  <Row align="middle" className='mt-3'>
                    <Col md={6} span={5} className='userLabel'><span className='span-danger'>*</span> {t('employee_email')}:</Col>
                    <Col md={18} className="text-capitalize">
                      <InputComponent className={`mb-2`} status={isSubmit && !validateEmail(dataObject.email) && `error`} value={dataObject.email}
                        setValue={(value) => setDataObject({ ...dataObject, email: value })} placeholder="" />
                    </Col>
                  </Row>
                  <Row align="middle" className='mt-3'>
                    <Col md={6} span={5} className='userLabel'><span className='span-danger'>*</span> {t('employee_name')}:</Col>
                    <Col md={18} className="text-capitalize">
                      <InputComponent className={`mb-2`} status={isSubmit && !dataObject.name && `error`} value={dataObject.name}
                        setValue={(value) => setDataObject({ ...dataObject, name: value })} placeholder={''} />
                    </Col>
                  </Row>
                  <Row align="middle-" className='mt-3'>
                    <Col md={6} span={5} className='userLabel'><span className='span-danger'>*</span> {t('employee_Permission')}:</Col>
                    <Col md={18} className="text-capitalize">
                      {
                        permissions.map(it => (
                          <div>
                            <Checkbox disabled={isSameUser} checked={checkIsAccess(it.value)} onChange={onChangePermission} value={it.value}>{t(it.label)}</Checkbox>
                          </div>
                        ))
                      }
                    </Col>
                  </Row>
                  <Row align="middle" className='mt-3'>
                    <Col md={6} span={5} className='userLabel'><span className='span-danger'>*</span> {t('Status')}:</Col>
                    <Col md={18} className="text-capitalize"><Switch disabled={isSameUser} checked={dataObject.status} onChange={(e) => setDataObject({ ...dataObject, status: e })} /></Col>
                  </Row>
                </Card>
              </Row>
            </Col>
          </Row>
        </div>
        <MessageConfirmModal textCentered ref={messageModalRef} />
      </Spin>
    </HeaderCreateStaffComponent>
  );
}

export default StaffCreatePage;
