import React, { useState } from 'react';
import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    UploadOutlined,
    UserOutlined,
    VideoCameraOutlined,
    RightOutlined,
    LeftOutlined
} from '@ant-design/icons';
import { Button, Layout, Menu, Space, theme } from 'antd';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import LogoESI from '../../assets/images/logo.png'
import NavigationTopper from './NavigationTopper';
import { menus } from '../navigations/menu';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
const { Header, Sider, Content } = Layout;

const HeaderComponent = ({isHide, pageTitle}) => {
    let collapsedLocal = localStorage.getItem("collapsed")
    const [collapsed, setCollapsed] = useState( collapsedLocal === "hide" ? true : false);
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();
    const location = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const user = useSelector((state) => state.authenticatedUser);

    const siderStyle = {
        // overflow: 'auto',
        // height: '100vh',
        height: "100%",
        // position: 'fixed',
        // insetInlineStart: 0,
        // top: 0,
        // bottom: 0,
        // scrollbarWidth: 'thin',
        // scrollbarGutter: 'stable',
        // background: "#ff",
        borderRight: "1px solid #dcdcdcd4",
        display: isHide === 'view' ? "none" : '',
    };

    const _menusRend = () => {
        let menusAccess = menus(user)
        let items = []
        menusAccess && menusAccess.forEach(it => {
            it.submenu && it.submenu.forEach(its => {
                items.push({
                    key: its.pathname,
                    icon: <span>{location.pathname === its.pathname ? its.iconActive : its.icon}</span>,
                    label: t(its.title),
                    onClick: () => navigate(its.pathname)
                })
            });
        });
        return items
      }

    return (
        <div className='w-100 h-100'>
            <Layout style={{ height: "100" }} className='w-100 h-100 mymain'>
                <Sider 
                    width={250}           // Width when expanded
                    // collapsedWidth={80} 
                className='cus-sider' style={siderStyle} trigger={null} collapsible collapsed={collapsed}>
                    <Button
                            type="text"
                            icon={collapsed ? <RightOutlined width={'5px'} style={{fontSize: "10px"}} /> : <LeftOutlined width={'2rem'} size={10} style={{fontSize: "10px"}} />}
                            onClick={() => {
                                setCollapsed(!collapsed)
                                localStorage.setItem("collapsed", !collapsed ? "hide" : "show")
                            }}
                            className='btn-sidebar'
                        />
                    <div className="demo-logo-vertical"
                        style={{
                            position: 'sticky',
                            top: 0,
                            zIndex: 1,
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            padding: 0,
                            background: "hsl(210, 20%, 98%)",
                            // background: colorBgContainer,
                            // borderBottom: "1px solid #dcdcdcd4",
                            height: 64,
                            justifyContent: "center"
                        }}
                    >
                        <Space
                            style={{
                                display: 'flex',
                                alignItems: 'end',
                            }}
                        >
                            <img alt='ESI-Logo' role='button' src={LogoESI} onClick={() => { navigate("/") }} className={`${ collapsed ? `esi-logo16` : `esi-logo`}`} />
                            
                        </Space>
                    </div>
                    <Menu
                        // theme="dark"
                        style={{background: "hsl(210, 20%, 98%)"}}
                        className='bluediv sidebar-cus-active ant-menu-flow ps-2 pe-2'
                        mode="inline"
                        // defaultSelectedKeys={['1']}
                        selectedKeys={[location.pathname]} 
                        items={_menusRend()}
                    />
                </Sider>
                <Layout style={{overflow: "hidden"}}>
                    <Header
                        style={{
                            position: 'sticky',
                            top: 0,
                            zIndex: 1,
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            padding: 0,
                            background: colorBgContainer,
                            borderBottom: "1px solid #dcdcdcd4",
                        }}
                    >
                        <NavigationTopper pageTitle={pageTitle} />
                    </Header>

                    <Content
                        className='h-100 w-100 cus-content'
                        style={{
                            background: "#fff",
                            // overflow: 'auto',
                            // padding: "32px",
                            paddingBottom: "11px"
                            // overflow: "auto"
                        }}
                    >
                        <Outlet />
                    </Content>

                </Layout>
            </Layout>
        </div>
    );
};
export default HeaderComponent;