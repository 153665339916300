import * as React from 'react';

const UserProSvg = () => {
    return (
        <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="23" cy="23" r="23" fill="#F8E2E8" />
            <path d="M13.222 18.2C15.686 18.2 17.1 19.418 17.1 21.574C17.1 23.814 15.686 25.116 13.222 25.116H11.08V28H9.204V18.2H13.222ZM13.124 23.464C14.524 23.464 15.322 22.862 15.322 21.63C15.322 20.426 14.524 19.838 13.124 19.838H11.08V23.464H13.124ZM26.9253 28H24.7833L23.0893 25.102C22.9773 25.116 22.8653 25.116 22.7393 25.116H20.5273V28H18.6513V18.2H22.7393C25.2733 18.2 26.7153 19.418 26.7153 21.574C26.7153 23.114 26.0293 24.22 24.7693 24.752L26.9253 28ZM20.5273 19.838V23.464H22.7393C24.1393 23.464 24.9233 22.862 24.9233 21.63C24.9233 20.426 24.1393 19.838 22.7393 19.838H20.5273ZM33.2205 18.144C36.1745 18.144 38.4425 20.286 38.4425 23.1C38.4425 25.914 36.1745 28.084 33.2205 28.084C30.2665 28.084 27.9985 25.914 27.9985 23.1C27.9985 20.272 30.2665 18.144 33.2205 18.144ZM33.2485 19.824C31.4285 19.824 29.9165 21.252 29.9165 23.1C29.9165 24.948 31.4425 26.404 33.2485 26.404C35.0405 26.404 36.5245 24.948 36.5245 23.1C36.5245 21.252 35.0405 19.824 33.2485 19.824Z" fill="#E50A47" />
        </svg>

    );
}

export default UserProSvg;