import { useEffect, useRef, useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import MessageConfirmModal from '../../components/messageModal/MessageConfirmModal';
import MobileViewTextComponent from './MobileViewTextComponent';
import { message, Spin } from 'antd';
import { getDataRequest } from '../../api/serviceMethods';
import { NEWS_GET_URL } from '../../api/URLs';
import { useTranslation } from 'react-i18next';

const MobileViewTextModal = (props) => {
  const { i18n, t } = useTranslation();
    let condeLang = "cn"//i18n.language ? i18n.language : "cn"
  const { id, show, setShow } = props;
  const [loading, setLoading] = useState(false);
  const messageModalRef = useRef('')
  const [datalocalize, setDatalocalize] = useState(null)

  const addNewValue = async (data) => {
    data && setDatalocalize(data)
  }
  const getDetailById = async () => {
    try {
      setLoading(true);
      await getDataRequest(`${NEWS_GET_URL}/${id && id}`)
        .then((res) => {
          if (res.status === 200) {
            addNewValue(res.data)
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
      message.error('Server Error!');
    }
  }
  useEffect(() => {
    if (id && id) { getDetailById() }
  }, [id]);

  return (
    <>
      {/* <Spin spinning={loading}> */}
      <Modal show={show} onHide={setShow} size="lg" centered>
        <Modal.Header closeButton className="custom-header">
          <Modal.Title className='text-center w-100'>{t('New Management')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Spin spinning={loading}>
          <Row className="section-content">
            <Col>
              <div className='mt-3 w-100 mb-3'>
              <MobileViewTextComponent data={datalocalize ? datalocalize : null} />
              </div>
            </Col>
          </Row>
          </Spin>
        </Modal.Body>
      </Modal>
      {/* </Spin> */}
      <MessageConfirmModal textCentered ref={messageModalRef} />
    </>
  );
};

export default MobileViewTextModal;
