import * as React from 'react';

const CategorySvg = () => {
    return (
        <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="23" cy="23" r="23" fill="#F8E2E8" />
            <path d="M30.5834 21.6459H28.4167C25.7951 21.6459 24.3542 20.2051 24.3542 17.5834V15.4167C24.3542 12.7951 25.7951 11.3542 28.4167 11.3542H30.5834C33.2051 11.3542 34.6459 12.7951 34.6459 15.4167V17.5834C34.6459 20.2051 33.2051 21.6459 30.5834 21.6459ZM28.4167 12.9792C26.7051 12.9792 25.9792 13.7051 25.9792 15.4167V17.5834C25.9792 19.2951 26.7051 20.0209 28.4167 20.0209H30.5834C32.2951 20.0209 33.0209 19.2951 33.0209 17.5834V15.4167C33.0209 13.7051 32.2951 12.9792 30.5834 12.9792H28.4167Z" fill="#E50A47" stroke="#E50A47" stroke-width="0.5" />
            <path d="M17.5834 34.6459H15.4167C12.7951 34.6459 11.3542 33.2051 11.3542 30.5834V28.4167C11.3542 25.7951 12.7951 24.3542 15.4167 24.3542H17.5834C20.2051 24.3542 21.6459 25.7951 21.6459 28.4167V30.5834C21.6459 33.2051 20.2051 34.6459 17.5834 34.6459ZM15.4167 25.9792C13.7051 25.9792 12.9792 26.7051 12.9792 28.4167V30.5834C12.9792 32.2951 13.7051 33.0209 15.4167 33.0209H17.5834C19.2951 33.0209 20.0209 32.2951 20.0209 30.5834V28.4167C20.0209 26.7051 19.2951 25.9792 17.5834 25.9792H15.4167Z" fill="#E50A47" stroke="#E50A47" stroke-width="0.5" />
            <path d="M16.5001 21.6459C13.6617 21.6459 11.3542 19.3384 11.3542 16.5001C11.3542 13.6617 13.6617 11.3542 16.5001 11.3542C19.3384 11.3542 21.6459 13.6617 21.6459 16.5001C21.6459 19.3384 19.3384 21.6459 16.5001 21.6459ZM16.5001 12.9792C14.5609 12.9792 12.9792 14.5609 12.9792 16.5001C12.9792 18.4392 14.5609 20.0209 16.5001 20.0209C18.4392 20.0209 20.0209 18.4392 20.0209 16.5001C20.0209 14.5609 18.4392 12.9792 16.5001 12.9792Z" fill="#E50A47" stroke="#E50A47" stroke-width="0.5" />
            <path d="M29.5001 34.6459C26.6617 34.6459 24.3542 32.3384 24.3542 29.5001C24.3542 26.6617 26.6617 24.3542 29.5001 24.3542C32.3384 24.3542 34.6459 26.6617 34.6459 29.5001C34.6459 32.3384 32.3384 34.6459 29.5001 34.6459ZM29.5001 25.9792C27.5609 25.9792 25.9792 27.5609 25.9792 29.5001C25.9792 31.4392 27.5609 33.0209 29.5001 33.0209C31.4392 33.0209 33.0209 31.4392 33.0209 29.5001C33.0209 27.5609 31.4392 25.9792 29.5001 25.9792Z" fill="#E50A47" stroke="#E50A47" stroke-width="0.5" />
        </svg>
    );
}

export default CategorySvg;