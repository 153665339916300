import * as React from 'react';

const EmptyImageSvg = () => {
    return (
        <svg width="35" height="27" viewBox="0 0 35 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="35" height="27" rx="4" fill="#AEB3C1" />
            <path d="M35 22.4846C35 22.565 35 22.7427 35 22.9832C35 25.1923 33.2091 26.9846 31 26.9846H4C1.79086 26.9846 0 25.1949 0 22.9857C0 21.2613 0 19.2402 0 17.4785C0 16.8251 0.156906 16.1853 0.488238 15.6221C1.75832 13.4632 5.29015 7.99769 8.5 7.98829C14 7.98829 18.5 19.4766 21.5 19.9883C24.5 20.5 26.5316 16.5543 29.5 16.9883C33 17.5 35 22 35 22.4846Z" fill="#DBDCE6" />
            <circle cx="26.5" cy="7.5" r="3.5" fill="white" />
        </svg>
    );
}

export default EmptyImageSvg;