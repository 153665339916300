import React, { useState } from 'react';
import { Switch, Space, Button, Row, Col, Tooltip } from 'antd';
import TableFetchData from '../../components/data-table/TableFetchData';
import EditTableSvg from '../../assets/images/EditTable';
import DeleteTableSvg from '../../assets/images/DeleteTable';
import ViewTableSvg from '../../assets/images/ViewTable';
import { useTranslation } from 'react-i18next';
import NewPostCard from '../../components/newsManagemet/NewPostCard';
import TagTextComponent from '../../components/tag/TagTextComponent';
import { formatDateTimeSlasSpaceMMDDYYTime } from '../../utils/dateFormatUtils';
import { useNavigate } from 'react-router-dom';
import { ImageNo } from '../../components/image/ImageComponent';
import PaginationFetchData from '../../components/data-table/PaginationFetchData';

const NewsTabs = ({ 
  dataSource,
  viewType,
  setViewType,
  tableParams,
  setTableParams,
  getDataRequestAll,
  loading, setLoading,
  handleStatus,
  handleDelete,
  handleView,
 }) => {
  const maxLength = 20
  const navigate = useNavigate();

  const { i18n, t } = useTranslation();
  let condeLang = "cn"//i18n.language ? i18n.language : "cn"
  const checktextByLang = (dataCode) => {
    let text = dataCode && dataCode[condeLang] && dataCode[condeLang].title ? dataCode[condeLang].title : ''
    const languages = dataCode ?  Object.keys(dataCode) : []
    if (!text) {
      for (let lang of languages) {
        if (dataCode && dataCode[lang] && dataCode[lang].title) {
          text = dataCode[lang].title;
          break;
        }
      }
    }
    return text
  }
  const checktextByLangSub = (dataCode) => {
    let text = dataCode && dataCode[condeLang] && dataCode[condeLang].subtitle ? dataCode[condeLang].subtitle : ''
    const languages = dataCode ?  Object.keys(dataCode) : []
    if (!text) {
      for (let lang of languages) {
        if (dataCode && dataCode[lang] && dataCode[lang].subtitle) {
          text = dataCode[lang].subtitle;
          break;
        }
      }
    }
    return text
  }

  const tableSettings = [
    {
      title: <label className="titleCard14400">{t("Magazine Cover")}</label>,
      render: (_, record) => (
        <img style={{width: "110px", height: "74px", borderRadius: "6px", objectFit: "cover"}} src={record.cover || ImageNo} />
      )
    },
    {
      title: <label className="titleCard14400">{t("News Title")}</label>,
      render: (_, record) => (
        <Tooltip title={checktextByLang(record.localize)}>
          {checktextByLang(record.localize).length > maxLength ? checktextByLang(record.localize).slice(0, maxLength) + "..." : checktextByLang(record.localize)}
        </Tooltip>
      )
    },
    {
      title: <label className="titleCard14400">{t("News subtitle")}</label>,
      render: (_, record) => {
        return <Tooltip title={checktextByLangSub(record.localize)}>
        {checktextByLangSub(record.localize).length > maxLength ? checktextByLangSub(record.localize).slice(0, maxLength) + "..." : checktextByLangSub(record.localize)}
      </Tooltip>
      }
    },
    {
      title: <label className="titleCard14400">{t('Category')}</label>,
      render: (_, record) => (
        <>
          <TagTextComponent textData={record.category ? [record.category] : []} />
        </>
      )
    },
    {
      title: <label className="titleCard14400">{t("Published Date")}</label>,
      dataIndex: 'createdAt',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.createdAt - b.createdAt,
      key: 'createdAt',
      render: (_, record) => (
        <span className='text-wrap-nowrap'>
          {record.createdAt ? formatDateTimeSlasSpaceMMDDYYTime(record.createdAt) : ""}
        </span>
      )
    },
    {
      title: <label className="titleCard14400">{t('Upload account')}</label>,
      dataIndex: 'account',
      key: 'account',
      render: (_, record) => {
        return (
          <label className="text-capitalize text-wrap-nowrap">{record && record.createdBy && record.createdBy.firstName ? record.createdBy.firstName : ''} {record && record.createdBy && record.createdBy.lastName ? record.createdBy.lastName : ""}</label>
        )
      }
    },
    {
      title: <label className="titleCard14400">{t('Operator')}</label>,
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Switch onChange={() => handleStatus(record)} checked={record.active} />
          <span onClick={() => navigate(`/news/upload?id=${record.newsId}`)} style={{ cursor: "pointer" }}>
            <EditTableSvg />
          </span>
          <span onClick={() => handleDelete(record)} style={{ cursor: "pointer" }}>
            <DeleteTableSvg />
          </span>
          <span onClick={() => handleView(record)}  style={{ cursor: "pointer" }}>
            <ViewTableSvg />
          </span>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Row gutter={16} align="middle" style={{ marginBottom: 16 }}>
        <Col style={{ marginLeft: 'auto' }}>
        </Col>
      </Row>

      {viewType === 'table' ? (
        <TableFetchData
          tableParams={tableParams}
          setTableParams={setTableParams}
          data={dataSource}
          columns={tableSettings}
          loading={loading}
          setLoading={setLoading}
        />
      ) : (
        <>
        <NewPostCard 
            tableParams={tableParams}
            setTableParams={setTableParams}
            dataSource={dataSource}
            columns={tableSettings}
            loading={loading}
            setLoading={setLoading}
            handleStatus={handleStatus}
            handleDelete={handleDelete}
            handleView={handleView}
         />
            <PaginationFetchData
              tableParams={tableParams}
              setTableParams={setTableParams}
            />
         </>
      )}
    </div>
  );
};

export default NewsTabs;
