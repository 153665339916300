import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Select } from 'antd';
import Flag from 'react-world-flags';
import './SwitchLanguage.css';

const { Option } = Select;

const SwitchLanguage = () => {
    const { i18n, t } = useTranslation();

    const handleLanguageChange = (value) => {
        i18n.changeLanguage(value);
        localStorage.setItem("lang", value)
    };

    return (
        <div className="switch_language_body">
            <Select
                defaultValue={i18n.language}
                onChange={handleLanguageChange}
                style={{ width: 120 }}
                value={i18n.language}
            >
                <Option value="en">
                    <Flag code="us" style={{ width: 20, marginRight: 8 }} /> EN
                </Option>
                <Option value="cn">
                    <Flag code="cn" style={{ width: 20, marginRight: 8 }} /> CN
                </Option>
            </Select>
            {/* {t('login')} */}
        </div>
    );
};

export default SwitchLanguage;