import * as React from 'react';

export const RemoveSvg = ({ width, height }) => {
    return (
        <svg width={width ? width : "24"} height={height ? height : "24"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="12" r="12" fill="#F8E2E8" />
            <g opacity="0.8">
                <path d="M8.4707 8.47266L11.6466 11.6485C11.8418 11.8438 12.1584 11.8438 12.3537 11.6485L15.5295 8.47266" stroke="#E50A47" stroke-width="1.5" stroke-linecap="round" />
                <path d="M15.5295 15.5306L12.3537 12.3548C12.1584 12.1595 11.8418 12.1595 11.6466 12.3548L8.47072 15.5306" stroke="#E50A47" stroke-width="1.5" stroke-linecap="round" />
            </g>
        </svg>
    );
}
