import * as React from 'react';

const MemberProSvg = () => {
    return (
        <svg width="45" height="20" viewBox="0 0 45 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.5 8C0.5 3.85786 3.85786 0.5 8 0.5H37C41.1421 0.5 44.5 3.85786 44.5 8V12C44.5 16.1421 41.1421 19.5 37 19.5H0.5V8Z" fill="url(#paint0_linear_1879_393)" stroke="url(#paint1_linear_1879_393)" />
            <path d="M13.476 5.6C15.588 5.6 16.8 6.644 16.8 8.492C16.8 10.412 15.588 11.528 13.476 11.528H11.64V14H10.032V5.6H13.476ZM13.392 10.112C14.592 10.112 15.276 9.596 15.276 8.54C15.276 7.508 14.592 7.004 13.392 7.004H11.64V10.112H13.392ZM25.2217 14H23.3857L21.9337 11.516C21.8377 11.528 21.7417 11.528 21.6337 11.528H19.7377V14H18.1297V5.6H21.6337C23.8057 5.6 25.0417 6.644 25.0417 8.492C25.0417 9.812 24.4537 10.76 23.3737 11.216L25.2217 14ZM19.7377 7.004V10.112H21.6337C22.8337 10.112 23.5057 9.596 23.5057 8.54C23.5057 7.508 22.8337 7.004 21.6337 7.004H19.7377ZM30.6175 5.552C33.1495 5.552 35.0935 7.388 35.0935 9.8C35.0935 12.212 33.1495 14.072 30.6175 14.072C28.0855 14.072 26.1415 12.212 26.1415 9.8C26.1415 7.376 28.0855 5.552 30.6175 5.552ZM30.6415 6.992C29.0815 6.992 27.7855 8.216 27.7855 9.8C27.7855 11.384 29.0935 12.632 30.6415 12.632C32.1775 12.632 33.4495 11.384 33.4495 9.8C33.4495 8.216 32.1775 6.992 30.6415 6.992Z" fill="#1A1B2D" />
            <defs>
                <linearGradient id="paint0_linear_1879_393" x1="46.9286" y1="18.5714" x2="-8.61725" y2="3.27999" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FED835" />
                    <stop offset="1" stop-color="#FFFAC3" />
                </linearGradient>
                <linearGradient id="paint1_linear_1879_393" x1="4.73684" y1="3.125" x2="45.2177" y2="12.1486" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FFE669" />
                    <stop offset="1" stop-color="#FFEE81" />
                </linearGradient>
            </defs>
        </svg>
    );
}

export default MemberProSvg;