import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';

import authenticatedUser from './authenticated-user.store';
import timezone from './timezone.store';
import { listenerMiddleware } from './middleware';

const authenticationState = JSON.parse(localStorage.getItem('user') || 'null');
const timezoneState = localStorage.getItem('timezone') || '';

const reducer = combineReducers({
  authenticatedUser,
  timezone,
});

const store = configureStore({
  preloadedState: {
    authenticatedUser: authenticationState,
    timezone: timezoneState,
  },
  reducer,
  middleware: (getDefaultMiddleware) => [...getDefaultMiddleware(), listenerMiddleware.middleware],
});

export default store;
