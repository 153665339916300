import React from 'react'
import { Button } from 'react-bootstrap'

const RoundButton = props => {
    const {
        marginTop,
        background,
        borderColor,
        borderRadius,
        width,
        fontFamily,
        fontWeight,
        fontSize,
        marginBottom,
        height,
        className, 
        marginLeft
    } = props
    return <Button
        {...props}
        size="sm" 
        variant="primary"
        style={{
            marginTop: marginTop ? marginTop : '',
            background: background ? background : '#1890ff',
            borderColor: borderColor ? borderColor : '#1890ff',
            borderRadius: borderRadius ? borderRadius : '',
            width: width ? width : props.width ? props.width : '',
            fontFamily: fontFamily ? fontFamily : '',
            fontWeight: fontWeight ? fontWeight : '',
            fontSize: fontSize ? fontSize : '',
            marginBottom: marginBottom ? marginBottom : '',
            height: height ? height : '2.5rem',
            marginLeft: marginLeft ? marginLeft : ''
        }}
        className={`${className}`}
        >{props.title}</Button>
}

export const RoundButtonCancel = props => {
    const {
        marginTop,
        background,
        borderColor,
        borderRadius,
        width,
        fontFamily,
        fontWeight,
        fontSize,
        marginBottom,
        height,
        color,
        className, 
    } = props
    return <Button
        {...props}
        size="sm" 
        variant="primary"
        style={{
            marginTop: marginTop ? marginTop : '',
            color: color ? color : 'black',
            background: background ? background : '#fff',
            borderColor: borderColor ? borderColor : '#d9d9d9',
            borderRadius: borderRadius ? borderRadius : '',
            width: width ? width : props.width ? props.width : '',
            fontFamily: fontFamily ? fontFamily : 'Montserrat',
            fontWeight: fontWeight ? fontWeight : '',
            fontSize: fontSize ? fontSize : '',
            marginBottom: marginBottom ? marginBottom : '',
            height: height ? height : '2.5rem'
        }}
        className={`text-btn-title ${className}`}
        >{props.title}</Button>
}

export default RoundButton;