import * as React from 'react';

const CorrectSvg = () => {
    return (
        <svg width="25" height="25" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.1" d="M8.9584 2.04063C9.5334 1.54896 10.4751 1.54896 11.0584 2.04063L12.3751 3.17396C12.6251 3.39062 13.0917 3.56563 13.4251 3.56563H14.8417C15.7251 3.56563 16.4501 4.29063 16.4501 5.17396V6.59062C16.4501 6.91563 16.6251 7.39062 16.8417 7.64062L17.9751 8.95729C18.4667 9.53229 18.4667 10.474 17.9751 11.0573L16.8417 12.374C16.6251 12.624 16.4501 13.0906 16.4501 13.424V14.8406C16.4501 15.724 15.7251 16.449 14.8417 16.449H13.4251C13.1001 16.449 12.6251 16.624 12.3751 16.8406L11.0584 17.974C10.4834 18.4656 9.54173 18.4656 8.9584 17.974L7.64173 16.8406C7.39173 16.624 6.92506 16.449 6.59173 16.449H5.15006C4.26673 16.449 3.54173 15.724 3.54173 14.8406V13.4156C3.54173 13.0906 3.36673 12.624 3.1584 12.374L2.0334 11.049C1.55007 10.474 1.55007 9.54063 2.0334 8.96563L3.1584 7.64062C3.36673 7.39062 3.54173 6.92396 3.54173 6.59896V5.16562C3.54173 4.28229 4.26673 3.55729 5.15006 3.55729H6.59173C6.91673 3.55729 7.39173 3.38229 7.64173 3.16562L8.9584 2.04063Z" fill="#E50A47" />
            <path d="M8.99163 12.644C8.82497 12.644 8.66663 12.5773 8.54997 12.4607L6.5333 10.444C6.29163 10.2023 6.29163 9.80234 6.5333 9.56068C6.77497 9.31901 7.17497 9.31901 7.41663 9.56068L8.99163 11.1357L12.575 7.55234C12.8166 7.31068 13.2166 7.31068 13.4583 7.55234C13.7 7.79401 13.7 8.19401 13.4583 8.43568L9.4333 12.4607C9.31663 12.5773 9.1583 12.644 8.99163 12.644Z" fill="#E50A47" stroke="#E50A47" stroke-width="0.2" />
        </svg>
    );
}

export default CorrectSvg;