import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, message, Row, Select, Spin, Upload } from 'antd';
import UploadColorSvg from '../../assets/images/uploadColorSvg';
import MessageConfirmModal from '../messageModal/MessageConfirmModal';
import InputComponent from '../input/InputComponent';
import DateComponent from '../date/DateComponent';
import HeaderCreateNewsComponent from '../header/HeaderCreateNewsComponent';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { NEWS_CATEGORY_URL, NEWS_GET_URL } from '../../api/URLs';
import { getDataRequest, postDataRequest, putDataRequest } from '../../api/serviceMethods';
import SelectComponent from '../select/SelectComponent';
import NewsPostCreateUpdatePage from '../../pages/news-management-page/news-posts/child-pages/NewPostCreateUpdatePage';
import DragViewMobileComponent from './DragViewMobileComponent';
import DragUploadComponent from '../upload/DragUploadComponent';
import MobileViewTextDragComponent from './MobileViewTextDragComponent';

function NewUpload() {
    const { newStep } = useParams();
    const { i18n, t } = useTranslation();
    let condeLang = "cn"//i18n.language ? i18n.language : "cn"
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    let id = queryParams.get('id');
    // newStep
    const { Dragger } = Upload;
    const messageModalRef = useRef("");
    const [dates, setDates] = useState(null);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false)
    const dragViewMobileComponentRef = useRef("")
    const [dataObject, setDataObject] = useState({
        "expiryAt": null,
        "category": "",
        "title": "",
        "subtitle": "",
        "status": false
    });
    const [picUrl, setpicUrl] = useState([
        // {
        //     "uid": "1",
        //     "name": "",
        //     "status": "done",
        //     "size": "34343",
        //     "url": `https://gw.alipayobjects.com/zos/rmsportal/KDpgvguMpGfqaHPjicRK.svg`
        // }
    ])
    const [optionsCategoryData, setoptionsCategoryData] = useState([]);

    const [datalocalize, setDatalocalize] = useState(
        {
            "coverimage": {
                "url": "http://esipro-dev.oss-cn-shanghai.aliyuncs.com/public/1732356198373-10kb.jpg",
                "size": 9539,
                "fileName": "10kb.jpg"
            },
            "expiryAt": "2024-11-30T00:00:00.000Z",
            "category": "标签20",
            "title": "多拉·弗莱彻，89 岁，60 多年来一直致力于美发事业",
            "subtitle": "全国美发美容联合会 (NHBF) 为来自南港的 89 岁美发师 Dora Fletcher 颁发了荣誉",
            "localize": {
                "cn": {
                    "title": "多拉·弗莱彻，89 岁，60 多年来一直致力于美发事业",
                    "subtitle": "全国美发美容联合会 (NHBF) 为来自南港的 89 岁美发师 Dora Fletcher 颁发了荣誉",
                    "layout": [
                        {
                            "label": "内容",
                            "tag": "content",
                            "text": "Hello fixe",
                            "textDecoration": "underline",//none, underline
                            "marginTop": 0,
                            "marginBottom": 0,
                            "marginLeft": 0,
                            "marginRight": 0,
                            "textAlign": "left",//center, rigth, justify
                            "italicType": "none",//italic
                        },
                        {
                            "label": "无序列表",
                            "tag": "bullet",
                            "text": "Hello",
                            "textDecoration": "underline",
                            "marginTop": 0,
                            "marginBottom": 0,
                            "marginLeft": 0,
                            "marginRight": 0,
                            "textAlign": "left",
                            "italicType": "none",//italic
                        },
                        {
                            "label": "按钮",
                            "tag": "button",
                            "text": "confirm",
                            "navigation": {
                                "routerName": "",
                                "screenName": "",
                                "params": {}
                            },
                            "textDecoration": "underline",
                            "marginTop": 0,
                            "marginBottom": 0,
                            "marginLeft": 0,
                            "marginRight": 0,
                            "textAlign": "left",
                            "italicType": "none",//italic
                        },
                        {
                            "label": "H1",
                            "tag": "h1",
                            "text": "Hello",
                            "textDecoration": "underline",
                            "marginTop": 0,
                            "marginBottom": 0,
                            "marginLeft": 0,
                            "marginRight": 0,
                            "textAlign": "left",
                            "italicType": "none",//italic
                        },
                        {
                            "label": "H2",
                            "tag": "h2",
                            "text": "Hello",
                            "textDecoration": "underline",
                            "marginTop": 0,
                            "marginBottom": 0,
                            "marginLeft": 0,
                            "marginRight": 0,
                            "textAlign": "left",
                            "italicType": "none",//italic
                        },
                        {
                            "label": "H3",
                            "tag": "h3",
                            "text": "Hello",
                            "textDecoration": "underline",
                            "marginTop": 0,
                            "marginBottom": 0,
                            "marginLeft": 0,
                            "marginRight": 0,
                            "textAlign": "left",
                            "italicType": "none",//italic
                        },
                        {
                            "label": "H4",
                            "tag": "h4",
                            "text": "Hello",
                            "textDecoration": "underline",
                            "marginTop": 0,
                            "marginBottom": 0,
                            "marginLeft": 0,
                            "marginRight": 0,
                            "textAlign": "left",
                            "italicType": "none",//italic
                        },
                        {
                            "label": "H5",
                            "tag": "h5",
                            "text": "Hello",
                            "textDecoration": "underline",
                            "marginTop": 0,
                            "marginBottom": 0,
                            "marginLeft": 0,
                            "marginRight": 0,
                            "textAlign": "left",
                            "italicType": "none",//italic
                        },
                        {
                            "label": "链接",
                            "tag": "link",
                            "link": "https://w...",
                            "text": "Hello",
                            "textDecoration": "underline",
                            "marginTop": 0,
                            "marginBottom": 0,
                            "marginLeft": 0,
                            "marginRight": 0,
                            "textAlign": "left",
                            "italicType": "none",//italic
                        },
                        {
                            "label": "图片",
                            "tag": "image",
                            "text": "Hello",
                            "url": "http://esipro-dev.oss-cn-shanghai.aliyuncs.com/public/1732153454621-9kb.jpg",
                            "textDecoration": "underline",
                            "marginTop": 0,
                            "marginBottom": 0,
                            "marginLeft": 0,
                            "marginRight": 0,
                            "textAlign": "left",
                            "italicType": "none",//italic
                        },
                        {
                            "label": "手风琴",
                            "tag": "accordion",
                            "text": "Hello",
                            "accordion": [
                                {
                                    "label": "内容",
                                    "tag": "content",
                                    "text": "Hello",
                                    "textDecoration": "underline",
                                    "marginTop": 0,
                                    "marginBottom": 0,
                                    "marginLeft": 0,
                                    "marginRight": 0,
                                    "textAlign": "left",
                                    "italicType": "none",//italic
                                },
                                {
                                    "label": "无序列表",
                                    "tag": "bullet",
                                    "text": "Hello",
                                    "textDecoration": "underline",
                                    "marginTop": 0,
                                    "marginBottom": 0,
                                    "marginLeft": 0,
                                    "marginRight": 0,
                                    "textAlign": "left",
                                    "italicType": "none",//italic
                                },
                                {
                                    "label": "按钮",
                                    "tag": "button",
                                    "text": "confirm",
                                    "navigation": {
                                        "routerName": "",
                                        "screenName": "",
                                        "params": {}
                                    },
                                    "textDecoration": "underline",
                                    "marginTop": 0,
                                    "marginBottom": 0,
                                    "marginLeft": 0,
                                    "marginRight": 0,
                                    "textAlign": "left",
                                    "italicType": "none",//italic
                                },
                                {
                                    "label": "H1",
                                    "tag": "h1",
                                    "text": "Hello",
                                    "textDecoration": "underline",
                                    "marginTop": 0,
                                    "marginBottom": 0,
                                    "marginLeft": 0,
                                    "marginRight": 0,
                                    "textAlign": "left",
                                    "italicType": "none",//italic
                                },
                                {
                                    "label": "H2",
                                    "tag": "h2",
                                    "text": "Hello",
                                    "textDecoration": "underline",
                                    "marginTop": 0,
                                    "marginBottom": 0,
                                    "marginLeft": 0,
                                    "marginRight": 0,
                                    "textAlign": "left",
                                    "italicType": "none",//italic
                                },
                                {
                                    "label": "H3",
                                    "tag": "h3",
                                    "text": "Hello",
                                    "textDecoration": "underline",
                                    "marginTop": 0,
                                    "marginBottom": 0,
                                    "marginLeft": 0,
                                    "marginRight": 0,
                                    "textAlign": "left",
                                    "italicType": "none",//italic
                                },
                                {
                                    "label": "H4",
                                    "tag": "h4",
                                    "text": "Hello",
                                    "textDecoration": "underline",
                                    "marginTop": 0,
                                    "marginBottom": 0,
                                    "marginLeft": 0,
                                    "marginRight": 0,
                                    "textAlign": "left",
                                    "italicType": "none",//italic
                                },
                                {
                                    "label": "H5",
                                    "tag": "h5",
                                    "text": "Hello",
                                    "textDecoration": "underline",
                                    "marginTop": 0,
                                    "marginBottom": 0,
                                    "marginLeft": 0,
                                    "marginRight": 0,
                                    "textAlign": "left",
                                    "italicType": "none",//italic
                                },
                                {
                                    "label": "链接",
                                    "tag": "link",
                                    "link": "https://w...",
                                    "text": "Hello",
                                    "textDecoration": "underline",
                                    "marginTop": 0,
                                    "marginBottom": 0,
                                    "marginLeft": 0,
                                    "marginRight": 0,
                                    "textAlign": "left",
                                    "italicType": "none",//italic
                                },
                                {
                                    "label": "图片",
                                    "tag": "image",
                                    "text": "Hello",
                                    "url": "http://esipro-dev.oss-cn-shanghai.aliyuncs.com/public/1732153454621-9kb.jpg",
                                    "textDecoration": "underline",
                                    "marginTop": 0,
                                    "marginBottom": 0,
                                    "marginLeft": 0,
                                    "marginRight": 0,
                                    "textAlign": "left",
                                    "italicType": "none",//italic
                                },
                            ]
                        },
                        {
                            "label": "手风琴",
                            "tag": "accordion",
                            "text": "Hello",
                            "accordion": [
                                {
                                    "label": "内容",
                                    "tag": "content",
                                    "text": "Hello",
                                    "textDecoration": "underline",
                                    "marginTop": 0,
                                    "marginBottom": 0,
                                    "marginLeft": 0,
                                    "marginRight": 0,
                                    "textAlign": "left",
                                    "italicType": "none",//italic
                                },
                                {
                                    "label": "无序列表",
                                    "tag": "bullet",
                                    "text": "Hello",
                                    "textDecoration": "underline",
                                    "marginTop": 0,
                                    "marginBottom": 0,
                                    "marginLeft": 0,
                                    "marginRight": 0,
                                    "textAlign": "left",
                                    "italicType": "none",//italic
                                },
                                {
                                    "label": "按钮",
                                    "tag": "button",
                                    "text": "confirm",
                                    "navigation": {
                                        "routerName": "",
                                        "screenName": "",
                                        "params": {}
                                    },
                                    "textDecoration": "underline",
                                    "marginTop": 0,
                                    "marginBottom": 0,
                                    "marginLeft": 0,
                                    "marginRight": 0,
                                    "textAlign": "left",
                                    "italicType": "none",//italic
                                },
                                {
                                    "label": "H1",
                                    "tag": "h1",
                                    "text": "Hello",
                                    "textDecoration": "underline",
                                    "marginTop": 0,
                                    "marginBottom": 0,
                                    "marginLeft": 0,
                                    "marginRight": 0,
                                    "textAlign": "left",
                                    "italicType": "none",//italic
                                },
                                {
                                    "label": "H2",
                                    "tag": "h2",
                                    "text": "Hello",
                                    "textDecoration": "underline",
                                    "marginTop": 0,
                                    "marginBottom": 0,
                                    "marginLeft": 0,
                                    "marginRight": 0,
                                    "textAlign": "left",
                                    "italicType": "none",//italic
                                },
                                {
                                    "label": "H3",
                                    "tag": "h3",
                                    "text": "Hello",
                                    "textDecoration": "underline",
                                    "marginTop": 0,
                                    "marginBottom": 0,
                                    "marginLeft": 0,
                                    "marginRight": 0,
                                    "textAlign": "left",
                                    "italicType": "none",//italic
                                },
                                {
                                    "label": "H4",
                                    "tag": "h4",
                                    "text": "Hello",
                                    "textDecoration": "underline",
                                    "marginTop": 0,
                                    "marginBottom": 0,
                                    "marginLeft": 0,
                                    "marginRight": 0,
                                    "textAlign": "left",
                                    "italicType": "none",//italic
                                },
                                {
                                    "label": "H5",
                                    "tag": "h5",
                                    "text": "Hello",
                                    "textDecoration": "underline",
                                    "marginTop": 0,
                                    "marginBottom": 0,
                                    "marginLeft": 0,
                                    "marginRight": 0,
                                    "textAlign": "left",
                                    "italicType": "none",//italic
                                },
                                {
                                    "label": "链接",
                                    "tag": "link",
                                    "link": "https://w...",
                                    "text": "Hello",
                                    "textDecoration": "underline",
                                    "marginTop": 0,
                                    "marginBottom": 0,
                                    "marginLeft": 0,
                                    "marginRight": 0,
                                    "textAlign": "left",
                                    "italicType": "none",//italic
                                },
                                {
                                    "label": "图片",
                                    "tag": "image",
                                    "text": "Hello",
                                    "url": "http://esipro-dev.oss-cn-shanghai.aliyuncs.com/public/1732153454621-9kb.jpg",
                                    "textDecoration": "underline",
                                    "marginTop": 0,
                                    "marginBottom": 0,
                                    "marginLeft": 0,
                                    "marginRight": 0,
                                    "textAlign": "left",
                                    "italicType": "none",//italic
                                },
                            ]
                        },
                    ]
                }
            },
        }
    )

    const getDataRequestAll = async (value) => {
        await getDataRequest(`${NEWS_CATEGORY_URL}`, {}).then((res) => {
            if (res.status === 200) {
                let datas = res?.data ? res?.data.map((it, i) => ({
                    ...it,
                    name: it.name || "",
                    label: it.name || "",
                    value: it.name || ""
                })) : []
                setoptionsCategoryData(datas);
            }
        }).catch((err) => {
        });
    }
    useEffect(() => {
        getDataRequestAll()
    }, [])

    const checktextByLang = (dataCode) => {
        let text = dataCode && dataCode[condeLang] && dataCode[condeLang].title ? dataCode[condeLang].title : ''
        const languages = dataCode ? Object.keys(dataCode) : []
        if (!text) {
            for (let lang of languages) {
                if (dataCode && dataCode[lang] && dataCode[lang].title) {
                    text = dataCode[lang].title;
                    break;
                }
            }
        }
        return text
    }
    const checktextByLangSub = (dataCode) => {
        let text = dataCode && dataCode[condeLang] && dataCode[condeLang].subtitle ? dataCode[condeLang].subtitle : ''
        const languages = dataCode ? Object.keys(dataCode) : []
        if (!text) {
            for (let lang of languages) {
                if (dataCode && dataCode[lang] && dataCode[lang].subtitle) {
                    text = dataCode[lang].subtitle;
                    break;
                }
            }
        }
        return text
    }

    const addNewValue = async (data) => {
        setDataObject(
            {
                ...data,
                "expiryAt": data.expiryAt || "",
                "category": data.category || "",
                "title": checktextByLang(data.localize),
                "subtitle": checktextByLangSub(data.localize),
            }
        )
        setpicUrl(data.coverimage ?
            [
                {
                    "uid": "1",
                    "name": data.coverimage && data.coverimage.fileName || "",
                    "status": "done",
                    "size": data.coverimage && data.coverimage.size || "",
                    "url": data.coverimage && data.coverimage.url || "",
                }
            ]
            : [])
        data && setDatalocalize(data)
    }
    const getDetailById = async () => {
        try {
            setLoading(true);
            await getDataRequest(`${NEWS_GET_URL}/${id && id}`)
                .then((res) => {
                    if (res.status === 200) {
                        addNewValue(res.data)
                    }
                    setLoading(false);
                })
                .catch((err) => {
                    console.log(err);
                    setLoading(false);
                });
        } catch (error) {
            setLoading(false);
            message.error('Server Error!');
        }
    }
    useEffect(() => {
        if (id && id) { getDetailById() }
    }, [newStep]);

    const checkError = () => {
        if (
            // !picUrl ||
            !dataObject.expiryAt ||
            !dataObject.category ||
            !dataObject.title ||
            !dataObject.subtitle
        ) {
            return true
        }
        return false
    }
    const insertParams = () => {
        const memberPayload = {
            "coverimage": picUrl && picUrl.length > 0 ? picUrl[0].url : "",
            "coverimage": picUrl && picUrl.length > 0 ? {
                "url": picUrl && picUrl.length > 0 ? picUrl[0].url : "",
                "size": picUrl && picUrl.length > 0 ? picUrl[0].size : "",
                "fileName": picUrl && picUrl.length > 0 ? picUrl[0].name : "",
            } : "",
            "expiryAt": dataObject.expiryAt,
            "category": dataObject.category,
            "title": dataObject.title,
            "subtitle": dataObject.subtitle,
        }
        return memberPayload
    }
    const handleSubmitNext = async () => {
        setIsSubmit(true)
        if (checkError()) {
            return false
        }
        setLoading(true);
        const memberPayload = insertParams()
        try {
            const res = await postDataRequest(NEWS_GET_URL, memberPayload);
            if (res?.status === 200) {
                // messageModalRef.current.showSuccessConfirmsAutoClose(res.data?.message || t("Saved Successfully"), () => { }, "", true);
                let newsIdData = res.data.newsId ? res.data.newsId : ""
                navigate(`/news/upload/2?id=${newsIdData}`)
            } else {
                messageModalRef.current.showWarningConfirmsAutoClose(res.data?.message || res.message || t("An error occurred."), () => { }, "", true);
            }
            setLoading(false);
        } catch (err) {
            setLoading(false);
            messageModalRef.current.showWarningConfirmsAutoClose(err && err.response && err.response.data && err.response.data.message, () => { }, "", true);
        } finally {
            setLoading(false);
            setIsSubmit(false);
        }
    }

    const hadleUpdateNext = async () => {
        setIsSubmit(true)
        if (checkError()) {
            return false
        }
        setLoading(true);
        const memberPayload = insertParams()
        try {
            const res = await putDataRequest(`${NEWS_GET_URL}/${id}`, memberPayload);
            if (res?.status === 200) {
                // messageModalRef.current.showSuccessConfirmsAutoClose(res.data?.message || t("Saved Successfully"), () => { }, "", true);
                navigate(`/news/upload/2?id=${id}`)
            } else {
                messageModalRef.current.showWarningConfirmsAutoClose(res.data?.message || res.message || t("An error occurred."), () => { }, "", true);
            }
            setLoading(false);
        } catch (err) {
            setLoading(false);
            messageModalRef.current.showWarningConfirmsAutoClose(err && err.response && err.response.data && err.response.data.message, () => { }, "", true);
        } finally {
            setLoading(false);
            setIsSubmit(false);
        }
    }

    console.log(`skhsk-datalocalize: `, datalocalize)
    const hadleUpdate = async () => {
        dragViewMobileComponentRef.current.handleSubmitContent()
        // setIsSubmit(true)
        // if (checkError()) {
        //     return false
        // }
        // setLoading(true);
        // const memberPayload = insertParams()
        // try {
        //     const res = await putDataRequest(`${NEWS_GET_URL}/${id}`, memberPayload);
        //     if (res?.status === 200) {
        //         messageModalRef.current.showSuccessConfirmsAutoClose(res.data?.message || t("Saved Successfully"), () => { }, "", true);
        //         setTimeout(() => {
        //             navigate(`/news}`)
        //         }, 2000);
        //     } else {
        //         messageModalRef.current.showWarningConfirmsAutoClose(res.data?.message || res.message || t("An error occurred."), () => { }, "", true);
        //     }
        //     setLoading(false);
        // } catch (err) {
        //     setLoading(false);
        //     messageModalRef.current.showWarningConfirmsAutoClose(err && err.response && err.response.data && err.response.data.message, () => { }, "", true);
        // } finally {
        //     setLoading(false);
        //     setIsSubmit(false);
        // }
    }

    let setps = [
        {
            title: t('Basic information settings'),
            status: newStep > 1 ? "done" : "processing",//done fail warning, processing
            stepCount: 1,
        },
        {
            title: t('Edit information content'),
            status: newStep > 2 ? "done" : "processing",
            stepCount: 2,
        },
    ]
    console.log(`skhsk-dataObject: `, dataObject)

    return (
        <>
            <HeaderCreateNewsComponent
                bntTitle={<>
                    <Button
                        className='b-danger'
                        disabled={loading}
                        type="primary"
                        onClick={() => {
                            if (!newStep || newStep - 0 === 1) {
                                if (id) {
                                    hadleUpdateNext()
                                } else {
                                    handleSubmitNext()
                                }
                            } else {
                                hadleUpdate()
                            }
                        }}
                        style={{
                            fontSize: '16px',
                            backgroundColor: "#E50A47",
                            border: "1px solid #e72158",
                            color: "#FFFFFF",
                            opacity: loading ? '0.4' : ""
                        }}
                        danger
                    >
                        {/* {id ? t("Next step") : t(`Next step`)} */}
                        {
                            newStep - 0 === setps.length ? t("SubmitNews") : t("Next step")
                        }
                    </Button>
                </>}
                isHide="view"
                newStep={newStep}
                setps={setps}
                onClick={() => {
                    navigate(newStep - 1 ? `/news/upload/${newStep - 1}?id=${id}` : `/news-posts`)
                    // navigate('/news/upload/2')
                }}
                backTitle={t('Create news')}
            >
                <Spin spinning={loading}>
                    {
                        !newStep || newStep - 0 === 1 ? <div className='container newDetail'>
                            <Row className='mb-5'>
                                <Col md={4}>{t("Cover")} :</Col>
                                <Col md={20}>
                                    <Row className='w-100'>
                                        <Col md={24} sm={24}>
                                            <div className='w-100'>
                                                <DragUploadComponent
                                                    fileList={picUrl ? picUrl : []}
                                                    setFileList={(values) => {
                                                        setpicUrl(values)
                                                    }}
                                                />
                                                {/* <Dragger {...props} className='newUpload'>
                                                <Button
                                                    style={{
                                                        height: "48px",
                                                        width: "48px",
                                                        backgroundColor: "#F5F7F9",
                                                        border: "none"
                                                    }}
                                                >
                                                    <UploadColorSvg />
                                                </Button>
                                                <div className='mt-2'>
                                                    <p className="ant-upload-text mt-2">
                                                        {t("Drag files here or")}
                                                        <a href='' style={{ marginLeft: "5px", textDecoration: "underline" }}>{t("Select File")}</a>
                                                    </p>
                                                    <p className="ant-upload-hint">
                                                        支持 png/jpg/pdf 等格式, 文件大小最大10MB
                                                    </p>
                                                </div>
                                            </Dragger> */}
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className='mt-4 d-flex align-items-center'>
                                <Col md={4}>{t("News Title")} :</Col>
                                <Col md={20}>
                                    <InputComponent className={`mb-2`} status={isSubmit && !dataObject.title && `error`} value={dataObject.title}
                                        setValue={(value) => setDataObject({ ...dataObject, title: value })} placeholder="" />
                                </Col>
                            </Row>
                            <Row className='mt-4 d-flex align-items-center'>
                                <Col md={4}>{t("News subtitle")} :</Col>
                                <Col md={20}>
                                    <InputComponent className={`mb-2`} status={isSubmit && !dataObject.subtitle && `error`} value={dataObject.subtitle}
                                        setValue={(value) => setDataObject({ ...dataObject, subtitle: value })} placeholder="" />
                                </Col>
                            </Row>
                            <Row className='mt-4 d-flex align-items-center'>
                                <Col md={4}>{t('Expiry time')} :</Col>
                                <Col md={20}>
                                    <DateComponent
                                        value={dataObject.expiryAt ? dataObject.expiryAt : null}
                                        status={isSubmit && !dataObject.expiryAt && `error`}
                                        setValue={(value) => setDataObject({ ...dataObject, expiryAt: value })}
                                        style={{ width: "100%" }}
                                    />
                                </Col>
                            </Row>
                            <Row className='mt-4 d-flex align-items-center'>
                                <Col md={4}>{t("Category")} :</Col>
                                <Col md={20}>
                                    <SelectComponent
                                        options={optionsCategoryData} value={dataObject.category}
                                        setValue={(value, values) => {
                                            setDataObject({ ...dataObject, category: value })
                                        }}
                                        status={isSubmit && !dataObject.category && `error`}
                                        placeholder={``}
                                    />
                                </Col>
                            </Row>
                        </div>
                            :
                            <>
                                <div className='w-100 h-100' style={{ background: "rgb(241 241 241)" }}>
                                    <DragViewMobileComponent
                                        ref={dragViewMobileComponentRef}
                                        datalocalize={datalocalize} setDatalocalize={setDatalocalize}
                                        loading={loading} setLoading={setLoading}
                                    />
                                    {/* <div className='mt-3 w-100 mb-3'>
                                        <MobileViewTextDragComponent data={datalocalize ? datalocalize : null}  />
                                    </div> */}
                                </div>
                                <NewsPostCreateUpdatePage compose />
                            </>
                    }
                    <MessageConfirmModal textCentered ref={messageModalRef} />
                </Spin>
            </HeaderCreateNewsComponent>
        </>
    );
}

export default NewUpload;
