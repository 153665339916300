import React, { useState } from 'react';
import {
    ArrowLeftOutlined
} from '@ant-design/icons';
import { Button, Layout, theme } from 'antd';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
const { Header, Content } = Layout;

const HeaderMagazineComponent  = ({ isHide, backTitle, onClick, onClick1, bntTitle, bntTitle1, type, backgroundColor, border, border1, opacity, color, children, setModalOpenNew }) => {
    const [collapsed, setCollapsed] = useState(false);
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');

    return (
        <div className='w-100 h-100'>
            <Layout style={{ height: "100" }} className='w-100 h-100 mymain'>
                <Layout style={{ overflow: "hidden" }}>
                    <Header
                        style={{
                            position: 'sticky',
                            top: 0,
                            zIndex: 1,
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            padding: 0,
                            background: colorBgContainer,
                            borderBottom: "1px solid #dcdcdcd4",
                        }}
                    >

                        <div className="w-100 bg-white- ps-4 pe-4" >
                            <div className="d-flex justify-content-between align-items-center ps-4- m-0">
                                <h4 className="m-0 text-wrap-nowrap navHeader20500"><span onClick={onClick && onClick} style={{ cursor: "pointer", paddingRight: "0.5rem" }}> <ArrowLeftOutlined /></span>{backTitle || ''}</h4>
                                <div className="d-flex align-items-center">
                                    <div className="align-items-center d-flex">
                                        <div
                                            style={{
                                                cursor: 'pointer',
                                            }}
                                            className="userGroup"
                                        >
                                            {
                                                bntTitle ?
                                                    <Button
                                                        // type="text"
                                                        className=''
                                                        type={type ? type : ""}
                                                        onClick={() => {
                                                            setCollapsed(!collapsed)
                                                            setModalOpenNew(true)
                                                        }}
                                                        style={{
                                                            fontSize: '16px',
                                                            marginRight: "1rem",
                                                            color: "#e72158",
                                                            border: border ? border : "none",
                                                        }}
                                                        danger
                                                    >
                                                       {bntTitle || ''}
                                                    </Button> : ""}
                                            {
                                                bntTitle1 ?
                                                    <Button
                                                        // type="text"
                                                        className=''
                                                        // type="primary" 
                                                        onClick={onClick1 && onClick1}
                                                        style={{
                                                            fontSize: '16px',
                                                            backgroundColor: backgroundColor ? backgroundColor : "",
                                                            border: border1 ? border1 : "none",
                                                            color: color ? color : "#e72158",
                                                            opacity: opacity ? opacity : ""
                                                        }}
                                                        danger
                                                    >
                                                        {bntTitle1 || ''}
                                                    </Button> : ""
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Header>

                    <Content
                        className='h-100 w-100 cus-content'
                        style={{
                            background: "#fff",
                            overflow: 'auto',
                            // padding: "32px",
                            // overflow: "auto"
                        }}
                    >
                        {children}
                    </Content>

                </Layout>
            </Layout>
        </div>
    );
};
export default HeaderMagazineComponent;