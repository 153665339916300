import React, { useRef } from 'react';
import { Switch, Space, Button, Row, Col } from 'antd';
import TableFetchData from '../../components/data-table/TableFetchData';
import MagazineCard from '../../components/magazine/MagazineCard';
import EditTableSvg from '../../assets/images/EditTable';
import DeleteTableSvg from '../../assets/images/DeleteTable';
import ViewTableSvg from '../../assets/images/ViewTable';
import { useTranslation } from 'react-i18next';
import MagazineModal from '../../components/magazine/MagazineModal';
import { ImageNo } from '../../components/image/ImageComponent';
import { formatDateTime } from '../../utils/dateFormatUtils';
import { useNavigate } from 'react-router-dom';
import { formatFileSize } from '../../utils/string';

const MagazineTabs = ({ 
  viewType, magazineData, tableParams,
   setTableParams, loading, setLoading,
   hadleUpdateMagazine, deleteMagazineData
}) => {
  const { t } = useTranslation();

  // new code for view pdf
  const magazineRef = useRef();
  const appDownloadRef = useRef();
  const navigate = useNavigate();
  const handleShowMagazine = (item) => {
    magazineRef.current?.showModal(item);
  }
  const handleCloseDownloadApp = () => appDownloadRef.current?.closeModal();

  const tableSettings = {
    appendable: false,
    removable: false,
    bordered: true,
    size: "small",
    pagination: {
      showLessItems: true,
      pageSize: 10,
    },
    columns: [
      {
        title: <label className="titleCard14400">{t("Magazine Cover")}</label>,
        render: (_, record) => (
          <img style={{ width: "72px", height: "96px", borderRadius: "6px", objectFit: "cover" }} src={record.picUrl || ImageNo} />
        )
      },
      {
        title: <label className="titleCard14400">{t("Title")}</label>,
        render: (_, record) => (
          <label className="text-capitalize">{record && record.title}</label>
        )
      },
      {
        title: <label className="titleCard14400">{t("Magazine Year")}</label>,
        render: (_, record) => (
          <label className="text-capitalize">{record && record.releaseYear}</label>
        )
      },
      {
        title: <label className="titleCard14400">{t('Label')}</label>,
        width:"20%",
        render: (_, record) => (
          <>
            {
              record && record.tags.map((lab, ind) => (
                <Button disabled className='me-2'
                  style={{
                    cursor: 'default',
                    fontSize: '12px',
                    backgroundColor: "#F8E2E8",
                    border: "none",
                    color: "#E50A47",
                    height: "21px"
                  }}
                >{lab.name}</Button>
              ))
            }
          </>
        )
      },
      {
        title: <label className="titleCard14400">{t("Collection")}</label>,
        dataIndex: 'favorite',
        key: 'favorite',
      },
      {
        title: <label className="titleCard14400">{t("Likes")}</label>,
        dataIndex: 'like',
        key: 'like',
      },
      {
        title: <label className="titleCard14400">{t('Upload time')}</label>,
        dataIndex: 'privateFile.uploadedTS',
        defaultSortOrder: 'descend',
        sorter: (a, b) => a.privateFile.uploadedTS - b.privateFile.uploadedTS,
        key: 'privateFile.uploadedTS',
        render: (_, record) => (
          <label className="text-capitalize">{record && record.privateFile && formatDateTime(record.privateFile.uploadedTS)}</label>
        )
      },
      {
        title: <label className="titleCard14400">{t('Upload account')}</label>,
        render: (_, record) => (
          <label className="text-capitalize">{record?.privateFile?.uploadedBy ? `${record.privateFile.uploadedBy.firstName || ''} ${record.privateFile.uploadedBy.lastName || ''}`.trim() : ''}</label>
        )
      },
      {
        title: <label className="titleCard14400">{t('File size')}</label>,
        render: (_, record) => (
          <label className="text-capitalize">{record && record.privateFile && formatFileSize(record.privateFile.size)}</label>
        )
      },
      {
        title: <label className="titleCard14400">{t('Operator')}</label>,
        key: 'action',
        render: (_, record) => (
          <Space size="middle">
            <Switch checked={record.status} onChange={() => hadleUpdateMagazine(record)} />
            <span style={{ cursor: "pointer" }} onClick={() => navigate(`/magazine/upload/${record.magazineId}`)}>
              <EditTableSvg />
            </span>
            <span style={{ cursor: "pointer" }} onClick={() => deleteMagazineData(record)}>
              <DeleteTableSvg />
            </span>
            <span style={{ cursor: "pointer" }} onClick={() =>
              handleShowMagazine(record)
            }>
              <ViewTableSvg />
            </span>
          </Space>
        ),
      },
    ],
  };

  return (
    <div>
      <Row gutter={16} align="middle" style={{ marginBottom: 16 }}>
        <Col style={{ marginLeft: 'auto' }}>
        </Col>
      </Row>

      {viewType === 'table' ? (
        <TableFetchData
          tableParams={tableParams}
          setTableParams={setTableParams}
          data={magazineData}
          columns={tableSettings.columns}
          loading={loading}
          setLoading={setLoading}
        />
      ) : (
        <MagazineCard 
          magazineData={magazineData} setLoading={setLoading} 
          loading={loading} tableParams={tableParams}
          tableSettings={tableSettings}
          setTableParams={setTableParams}
          hadleUpdateMagazine={hadleUpdateMagazine}
          deleteMagazineData={deleteMagazineData}
          handleShowMagazine={handleShowMagazine}
          navigate={navigate}
          t={t}
        />
      )}
      <MagazineModal
        ref={magazineRef}
        handleCloseDownloadApp={handleCloseDownloadApp}
      />
    </div>
  );
};

export default MagazineTabs;
