import {
    Affix,
    Alert,
    Button,
    Card,
    Checkbox,
    Col,
    Collapse,
    Dropdown,
    InputNumber,
    Menu,
    Modal,
    Popconfirm,
    Result,
    Row,
    Segmented,
    Tabs,
    Tag,
    Tooltip,
    Tree,
    Typography,
    Upload,
} from 'antd';
import { AddBox, ChangeCircle, DeleteSweep, UploadFile } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { FloatingLabel, Form } from 'react-bootstrap';
import JSONInput from 'react-json-editor-ajrm';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { BsCardImage, BsCardText, BsFillMenuButtonFill } from 'react-icons/bs';
import { MdErrorOutline, MdFormatListBulleted } from 'react-icons/md';
import { FaHeading } from 'react-icons/fa';
import { AiFillDatabase, AiOutlineLink } from 'react-icons/ai';
import moment from 'moment/moment';
import { FcTreeStructure } from 'react-icons/fc';
import { BiArrowToTop } from 'react-icons/bi';
import { useNavigate, useParams } from 'react-router-dom';
import Slider from '@mui/material/Slider';
import { useDispatch, useSelector } from 'react-redux';
import { AccordionSvg, AlignCenterSvg, AlignJustifySvg, AlignLetfSvg, AlignRigthSvg, ButtonSvg, CoverSvg, H1Svg, H2Svg, H3Svg, H4Svg, H5Svg, ImageSvg, ItalicSvg, LinkSvg, UnderlineSvg, UnorderedListSvg } from '../../assets/images/DragSvg';
import SelectComponent from '../select/SelectComponent';
import { useTranslation } from 'react-i18next';
import TextAreaComponent from '../input/TextAreaComponent';
import { isEmptyObject } from '../../utils/string';
import UploadComponent from '../upload/UploadComponent';


const RightDragTextComponent = ({ compose, edit, template, itemData, setItemData, types }) => {
    console.log(`skhsk-itemData: `, itemData)
    const { i18n, t } = useTranslation();

    // Content
    // bullet
    // Button
    // H5
    // Link
    // Image
    // Accordion
    const [styleTypeTag, setStyleTypeTag] = useState([
        {
            label: t("Body"),
            tag: "content",
            value: "content",
        },
        {
            label: t("Bullet"),
            tag: "bullet",
            value: "bullet",
        },
        {
            label: t("Button"),
            tag: "button",
            value: "button",
        },
        {
            label: t("H1"),
            tag: "h1",
            value: "h1",
        },
        {
            label: t("H2"),
            tag: "h2",
            value: "h2",
        },
        {
            label: t("H3"),
            tag: "h3",
            value: "h3",
        },
        {
            label: t("H4"),
            tag: "h4",
            value: "h4",
        },
        {
            label: t("H5"),
            tag: "h5",
            value: "h5",
        },
        {
            label: t("Link Button"),
            tag: "link",
            value: "link",
        },
        {
            label: t("Image"),
            tag: "image",
            value: "image",
        },
        {
            label: t("Accordion"),
            tag: "accordion",
            value: "accordion",
        },
    ])

    const [previewOpen, setPreviewOpen] = useState(false);
    const handlePreviewImage = async (url) => {
        setPreviewOpen(url);
    };
    const capitalizeFirstLetter = (str) => {
        return str ? str.charAt(0).toUpperCase() + str.slice(1) : 'NA';
    };
    const styleCheck = (it) => {
        let styleNew = {
            textDecoration: it.textDecoration ? it.textDecoration : "",
            marginTop: it.marginTop ? `${it.marginTop}px` : "0px",
            marginBottom: it.marginBottom ? `${it.marginBottom}px` : "0px",
            marginLeft: it.marginLeft ? `${it.marginLeft}px` : "0px",
            marginRight: it.marginRight ? `${it.marginRight}px` : "0px",
            textAlign: it.textAlign ? it.textAlign : "",
            fontStyle: it.italicType ? it.italicType : "",
        }
        return styleNew
    }
    const _RenderPreview = (items) => {
        if (!items) {
            return ''
        }
        let arr = []
        let i = 0
        let item = items ? items : {}
        let result = []
        let tag = item && item.tag ? item.tag : ''
        // console.log(`skhsk-tag: `, tag)
        switch (tag) {
            case `content`:
                result.push(
                    <>
                        <div className='col-12'>
                            <div className='w-100 d-flex justify-content-between'>
                                <span>Text</span>
                            </div>
                        </div>
                        <div className='col-12 mt-2'>
                            <div className='w-100'>
                                <div className="w-100 content-cus-body">
                                    <FloatingLabel
                                        label={`Text`} className="mb-1-">
                                        <Form.Control
                                            type="input"
                                            as="textarea"
                                            style={{ minHeight: '80px' }}
                                            // placeholder={`Please set Text`}
                                            value={itemData.text || ""}
                                            onChange={(e) => {
                                                itemData.text = e.target.value
                                                setItemData({ ...itemData })
                                            }}
                                        />
                                    </FloatingLabel>
                                </div>
                            </div>
                        </div>
                    </>
                );
                break;
            case `bullet`:
                result.push(
                    <>
                        <div className='col-12 mt-2'>
                            <div className='w-100'>
                                <div className="w-100 content-cus-body">
                                    <FloatingLabel
                                        label={`Text`} className="mb-1-">
                                        <Form.Control
                                            type="input"
                                            as="textarea"
                                            style={{ minHeight: '80px' }}
                                            // placeholder={`Please set Text`}
                                            value={itemData.text || ""}
                                            onChange={(e) => {
                                                itemData.text = e.target.value
                                                setItemData({ ...itemData })
                                            }}
                                        />
                                    </FloatingLabel>
                                </div>
                            </div>
                        </div>
                    </>
                );
                break;
            case `button`:
                result.push(
                    <>
                        <div className='col-12 mt-2'>
                            <div className='w-100'>
                                <div className="w-100 content-cus-body">
                                    <FloatingLabel
                                        label={`Title`} className="mb-1-">
                                        <Form.Control
                                            type="input"
                                            // as="textarea"
                                            // style={{ minHeight: '80px' }}
                                            // placeholder={`Please set title`}
                                            value={itemData.text || ""}
                                            onChange={(e) => {
                                                itemData.text = e.target.value
                                                setItemData({ ...itemData })
                                            }}
                                        />
                                    </FloatingLabel>
                                </div>
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className='w-100 d-flex justify-content-between'>
                                <span>
                                    <div>Navigation (JSON):</div>
                                    Ask Developer for the value
                                </span>
                            </div>
                        </div>
                        <div className='col-12 mt-2'>
                            <div className='w-100'>
                                <div className="w-100 content-cus-body">
                                    <JSONInput
                                        placeholder={ 
                                            typeof itemData.navigation !== 'object'
                                                ? types.find((t) => t.tag === 'button').default.navigation
                                                : itemData.navigation
                                        }
                                        theme={'light_mitsuketa_tribute'}
                                        width={'100%'}
                                        height="auto"
                                        style={{
                                            contentBox: { cursor: 'text' },
                                            body: { border: '1px lightgrey solid', borderRadius: '4px' },
                                        }}
                                        onBlur={(e) => {
                                            if (!e.error) {
                                                itemData.navigation = e.jsObject;
                                                setItemData({ ...itemData })
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                );
                break;
            case `h1`:
            case `h2`:
            case `h3`:
            case `h4`:
            case `h5`:
                result.push(
                    <>
                        <div className='col-12 mt-2'>
                            <div className='w-100'>
                                <div className="w-100 content-cus-body">
                                    <FloatingLabel
                                        label={`Text`} className="mb-1-">
                                        <Form.Control
                                            type="input"
                                            as="textarea"
                                            style={{ minHeight: '80px' }}
                                            // placeholder={`Please set Text`}
                                            value={itemData.text || ""}
                                            onChange={(e) => {
                                                itemData.text = e.target.value
                                                setItemData({ ...itemData })
                                            }}
                                        />
                                    </FloatingLabel>
                                </div>
                            </div>
                        </div>
                    </>
                );
                break;
            case `link`:
                result.push(
                    <>
                        <div className='col-12 mt-2'>
                            <div className='w-100'>
                                <div className="w-100 content-cus-body">
                                    <FloatingLabel
                                        label={`Title`} className="mb-2">
                                        <Form.Control
                                            type="input"
                                            // placeholder={`Please set Title`}
                                            value={itemData.text || ""}
                                            onChange={(e) => {
                                                itemData.text = e.target.value
                                                setItemData({ ...itemData })
                                            }}
                                        />
                                    </FloatingLabel>
                                    <FloatingLabel
                                        label={`Link`} className="mb-1-">
                                        <Form.Control
                                            type="input"
                                            // placeholder={`Please set link`}
                                            value={itemData.link || ""}
                                            onChange={(e) => {
                                                itemData.link = e.target.value
                                                setItemData({ ...itemData })
                                            }}
                                        />
                                    </FloatingLabel>
                                </div>
                            </div>
                        </div>
                    </>
                );
                break;
            case `image`:
                result.push(
                    <>
                        <div className='col-12 mt-2'>
                            <div className='w-100'>
                                <div className="w-100 content-cus-body">
                                    <UploadComponent
                                        fileList={itemData.url ? [{
                                            "uid": "1",
                                            "name": "",
                                            "status": "done",
                                            "url": itemData.url
                                        }] : []}
                                        setFileList={(values) => {
                                            itemData.url = values.url
                                            setItemData({ ...itemData })
                                        }}
                                        uploadavatarTitle={t('Upload')}
                                        hideAvatarTitle={true}
                                        className={`d-flex justify-content-center`}
                                    // isError={isSubmit && !picUrl && `error`}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                );
                break;
            case `accordion`:
                result.push(
                    <>
                        <div className='col-12 mt-2'>
                            <div className='w-100'>
                                <div className="w-100 content-cus-body">
                                    <FloatingLabel
                                        label={`Text`} className="mb-1-">
                                        <Form.Control
                                            type="input"
                                            as="textarea"
                                            style={{ minHeight: '80px' }}
                                            // placeholder={`Please set Text`}
                                            value={itemData.text || ""}
                                            onChange={(e) => {
                                                itemData.text = e.target.value
                                                setItemData({ ...itemData })
                                            }}
                                        />
                                    </FloatingLabel>
                                </div>
                            </div>
                        </div>
                    </>
                );
                break;

            default:
                break;
        }
        return result
    }


    return <div className="w-100 pe-4 ps-4 pt-4 pb-4 h-100" style={{ background: "#ffffff", borderRadius: "8px" }}>
        <div className="w-100">
            <div className='w-100 d-flex justify-content-between'>
                <span>文字样式</span>
            </div>
            <div className='w-100'>
                <div className='row'>
                    <div className='col-2'>
                        <div className="cursor-grab d-flex flex-wrap align-content-center justify-content-center mt-2 flex-column text-center">
                            <div className={`${itemData.textDecoration === "underline" ? "active-under" : ""}`} style={{ cursor: "pointer" }}
                                onClick={() => {
                                    itemData.textDecoration = itemData.textDecoration === "underline" ? "none" : "underline"
                                    setItemData({ ...itemData })
                                }}
                            ><UnderlineSvg /></div>
                        </div>
                    </div>
                    <div className='col-2'>
                        <div className="cursor-grab d-flex flex-wrap align-content-center justify-content-center mt-2 flex-column text-center">
                            <div className={`${itemData.italicType === "italic" ? "active-under" : ""}`} style={{ cursor: "pointer" }}
                                onClick={() => {
                                    itemData.italicType = itemData.italicType === "italic" ? "normal" : "italic"
                                    setItemData({ ...itemData })
                                }}
                            ><ItalicSvg /></div>
                        </div>
                    </div>
                    <div className='col-8'>
                        <SelectComponent
                            options={styleTypeTag}
                            value={itemData.tag || null}
                            setValue={(value) => {
                                itemData.tag = value
                                setItemData({ ...itemData })
                            }}
                            // placeholder={`Select tag`}
                            size={"middle"}
                        />
                    </div>
                    <div className='col-12'>
                        <div className="w-100"><hr /></div>
                    </div>
                    <div className='col-12'>
                        <div className='w-100 d-flex justify-content-between'>
                            <span>对齐</span>
                        </div>
                    </div>
                    <div className='col-12 mt-2 text-center'>
                        <Segmented
                            value={itemData.textAlign}
                            onChange={(value) => {
                                itemData.textAlign = value
                                setItemData({ ...itemData })
                            }}
                            style={{
                                border: "1px solid #ECECEF",
                                // marginBottom: 8,
                                backgroundColor: '#ffffff', // Custom background color
                                // padding: '10px',
                                // borderRadius: '8px',
                                // borderColor: '#1890ff', // Custom border color
                                // color: '#1890ff', // Custom font color
                            }}
                            className='segmented-cus'
                            options={[
                                {
                                    label: (
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <span role="img" aria-label="start" style={{ marginRight: 5 }}><AlignLetfSvg /></span>
                                        </div>
                                    ),
                                    value: 'start',
                                },
                                {
                                    label: (
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <span role="img" aria-label="center" style={{ marginRight: 5 }}><AlignCenterSvg /></span>
                                        </div>
                                    ),
                                    value: 'center',
                                },
                                {
                                    label: (
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <span role="img" aria-label="end" style={{ marginRight: 5 }}><AlignRigthSvg /></span>
                                        </div>
                                    ),
                                    value: 'end',
                                },
                                {
                                    label: (
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <span role="img" aria-label="justify" style={{ marginRight: 5 }}><AlignJustifySvg /></span>
                                        </div>
                                    ),
                                    value: 'justify',
                                },
                            ]}
                        />
                    </div>
                    <div className='col-12'>
                        <div className="w-100"><hr /></div>
                    </div>
                    <div className='col-12'>
                        <div className='w-100 d-flex justify-content-between'>
                            <span>容器填充</span>
                        </div>
                    </div>
                    <div className='col-6 mt-2'>
                        <div>{t("Up")}</div>
                        <div>
                            <InputNumber type='number'
                                addonBefore={<span style={{ cursor: "pointer" }} onClick={(value) => setItemData({ ...itemData, marginTop: itemData.marginTop !== undefined ? itemData.marginTop - 0 + 1 : 1 })}>{"+"}</span>}
                                addonAfter={<span style={{ cursor: "pointer" }} onClick={() => setItemData({ ...itemData, marginTop: itemData.marginTop !== undefined ? itemData.marginTop - 0 - 1 : -1 })}>{"-"}</span>}
                                value={itemData.marginTop ? itemData.marginTop : 0}
                                onChange={(value) => {
                                    itemData.marginTop = value
                                    setItemData({ ...itemData })
                                }}
                            />
                        </div>
                    </div>
                    <div className='col-6 mt-2'>
                        <div>{t("Down")}</div>
                        <div>
                            <InputNumber type='number'
                                addonBefore={<span style={{ cursor: "pointer" }} onClick={(value) => setItemData({ ...itemData, marginBottom: itemData.marginBottom !== undefined ? itemData.marginBottom - 0 + 1 : 1 })}>{"+"}</span>}
                                addonAfter={<span style={{ cursor: "pointer" }} onClick={() => setItemData({ ...itemData, marginBottom: itemData.marginBottom !== undefined ? itemData.marginBottom - 0 - 1 : -1 })}>{"-"}</span>}
                                value={itemData.marginBottom}
                                onChange={(value) => {
                                    itemData.marginBottom = value
                                    setItemData({ ...itemData })
                                }}
                            />
                        </div>
                    </div>
                    <div className='col-6 mt-2'>
                        <div>{t("Left")}</div>
                        <div>
                            <InputNumber type='number'
                                addonBefore={<span style={{ cursor: "pointer" }} onClick={(value) => setItemData({ ...itemData, marginLeft: itemData.marginLeft !== undefined ? itemData.marginLeft - 0 + 1 : 1 })}>{"+"}</span>}
                                addonAfter={<span style={{ cursor: "pointer" }} onClick={() => setItemData({ ...itemData, marginLeft: itemData.marginLeft !== undefined ? itemData.marginLeft - 0 - 1 : -1 })}>{"-"}</span>}
                                value={itemData.marginLeft}
                                onChange={(value) => {
                                    itemData.marginLeft = value
                                    setItemData({ ...itemData })
                                }}
                            />
                        </div>
                    </div>
                    <div className='col-6 mt-2'>
                        <div>{t("Right")}</div>
                        <div>
                            <InputNumber type='number'
                                addonBefore={<span style={{ cursor: "pointer" }} onClick={(value) => setItemData({ ...itemData, marginRight: itemData.marginRight !== undefined ? itemData.marginRight - 0 + 1 : 1 })}>{"+"}</span>}
                                addonAfter={<span style={{ cursor: "pointer" }} onClick={() => setItemData({ ...itemData, marginRight: itemData.marginRight !== undefined ? itemData.marginRight - 0 - 1 : -1 })}>{"-"}</span>}
                                value={itemData.marginRight}
                                onChange={(value) => {
                                    itemData.marginRight = value
                                    setItemData({ ...itemData })
                                }}
                            />
                        </div>
                    </div>
                    {!isEmptyObject(itemData) ? <div className='col-12'>
                        <div className="w-100"><hr /></div>
                    </div> : ""}

                    <>
                        {
                            !isEmptyObject(itemData) && _RenderPreview(itemData)
                        }
                    </>

                </div>

            </div>
        </div>
    </div>
}
export default RightDragTextComponent;