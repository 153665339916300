import React, { useEffect, useState } from 'react';
import { Input, Button, Space, Divider, Row, Col, Modal, Tag } from 'antd';
import { AppstoreOutlined, BarsOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import UploadSvg from '../../assets/images/uploadSvg';
import { useNavigate } from 'react-router-dom';
import TagsSvg from '../../assets/images/TagsSvg';
import AddSvg from '../../assets/images/AddSvg';
import TrashSvg from '../../assets/images/TrashSvg';
import DateRangeComponent from '../../components/date/DateRangeComponent';
import { deleteDataRequest, getDataRequest, postDataRequest } from '../../api/serviceMethods';
import { TAGS_URL } from '../../api/URLs';

const MagazineHeader = ({
    setViewType, setModalOpen,
    modalOpen, dates, setDates,
    loading, setTableParams, setLoading,
    tableParams, initLoadMagazine,
    setKeyword, keyword, messageModalRef
}) => {
    const [isListView, setIsListView] = useState(true);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [newTag, setNewTag] = useState("");
    const [tags, setTags] = useState([]);

    const getDataRequestAll = async (value) => {
        setLoading(true)
        await getDataRequest(`${TAGS_URL}`, {
            pageNo: tableParams.pagination.current,
            pageSize: tableParams.pagination.pageSize,
        }).then((res) => {
            if (res.status === 200) {
                let datas = res?.data ? res?.data.map((it, i) => ({
                    ...it,
                    name: it.name || "",
                })) : []
                setTags(datas);
            }
            setTableParams({
                ...tableParams,
                pagination: {
                    ...tableParams.pagination,
                    total: res.total,
                },
            });
            setLoading(false);
        }).catch((err) => {
            setLoading(false)
            console.log("Error", err)
        });
    }
    useEffect(() => {
        // modalOpen && getDataRequestAll()
        getDataRequestAll()
    }, [modalOpen])

    const handleCreate = async (name) => {
        try {
            setLoading(true);
            await postDataRequest(`${TAGS_URL}`, { name: name })
                .then((res) => {
                    if (res.status === 200) {
                        setNewTag("");
                        messageModalRef.current.showSuccessConfirmsAutoClose(res && res.message, () => { }, "", true);
                        getDataRequestAll('');
                        setTimeout(() => {
                            setLoading(false);
                        }, 2000);
                    } else {
                        messageModalRef.current.showWarningConfirmsAutoClose(res && res.message, () => { }, "", true);
                    }
                    setLoading(false);
                })
                .catch((err) => {
                    setLoading(false);
                    messageModalRef.current.showWarningConfirmsAutoClose(err && err.response && err.response.data && err.response.data.message, () => { }, "", true);
                });
        } catch (error) {
            setLoading(false)
            messageModalRef.current.showWarningConfirmsAutoClose(`Server Error!`, () => { }, "", true);
        }
    }

    const handleAddTag = () => {
        if (newTag.trim() !== "") {
            let filterData = tags.filter(it => it.name === newTag.trim())
            if (filterData.length > 0) {
                messageModalRef.current.showWarningConfirmsAutoClose(t('The tag has exited!'), () => { }, "", true);
                return false
            }
            handleCreate(newTag.trim())
        }
    };

    const handleDeleteTag = (tag, i) => {
        if (tag._id) {
            messageModalRef.current.showWarningConfirm(t('Are you sure you want to delete this tag?'), async () => {
                setLoading(true)
                await deleteDataRequest(`${TAGS_URL}/${tag._id}`).then((res) => {
                    messageModalRef.current.showSuccessConfirmsAutoClose(res && res.message, () => { }, "", true);
                    getDataRequestAll('');
                    setLoading(false)
                }).catch((err) => {
                    messageModalRef.current.showWarningConfirmsAutoClose(`Server Error!`, () => { }, "", true);
                    setLoading(false)
                });
            }, "", true);
        } else {
            setTags(tags.filter((t) => t !== tag));
        }
    };

    return (
        <>
            <Space
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                }}
                size="small"
            >
                <Input
                    placeholder={t("Search")}
                    size="large"
                    disabled={loading}
                    loading={loading}
                    className="me-4"
                    allowClear
                    value={keyword}
                    onChange={(e) => setKeyword(e.target.value)}
                />
                <DateRangeComponent
                    placeholder={t("Please select a time or time period")}
                    disabled={loading}
                    value={dates ? dates : [null, null]}
                    setValue={setDates}
                />
                <Button
                    size="large"
                    onClick={() => {
                        setTableParams({
                            ...tableParams,
                            pagination: {
                                ...tableParams.pagination,
                                current: 1,
                            },
                        });
                        initLoadMagazine();
                    }}
                >
                    {t('Query')}
                </Button>
                <Divider type="vertical" style={{ height: '24px', borderColor: '#d9d9d9' }} />

                <Space size="small">
                    <Button
                        className='newStyleTabs'
                        type={isListView ? 'text' : 'default'}
                        icon={<AppstoreOutlined />}
                        onClick={() => {
                            setIsListView(false)
                            setViewType('card')
                        }}
                        style={{ padding: 0, color: isListView ? '#636E88' : '#E50A47' }}
                    />
                    <Button
                        type={isListView ? 'default' : 'text'}
                        icon={<BarsOutlined />}
                        onClick={() => {
                            setIsListView(true)
                            setViewType('table')
                        }}
                        style={{ padding: 0, color: isListView ? '#E50A47' : '#636E88' }}
                    />
                </Space>
                <Divider type="vertical" style={{ height: '24px', borderColor: '#d9d9d9' }} />
                <Button
                    // type="text"
                    className=''
                    type="text"
                    onClick={() => setModalOpen(true)}
                    style={{
                        fontSize: '16px',
                        marginRight: "1rem",
                        color: "#e72158",
                        border: "1px solid #e72158"
                    }}
                >
                    {t('tag')}
                </Button>
                <Button
                    icon={<UploadSvg />}
                    className=''
                    // type="primary" 
                    onClick={() => navigate("/magazine/upload")}
                    style={{
                        fontSize: '16px',
                        backgroundColor: "#F8E2E8",
                        border: "none",
                        color: "#E50A47"
                    }}
                    danger
                >
                    {t('Upload Magazine')}
                </Button>
            </Space>
            <Modal
                title={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <TagsSvg />
                        <span style={{ marginLeft: "10px" }}>{t('tag')}</span>
                    </div>
                }
                centered
                width={700}
                open={modalOpen}
                className='custom-modal'
                onOk={() => setModalOpen(false)}
                onCancel={() => {
                    setModalOpen(false)
                    setTags([])
                }}
                cancelButtonProps={{ style: { display: 'none' } }}
                okButtonProps={{ style: { display: 'none' } }}
            >
                <hr />
                <div style={{ padding: "20px 60px", marginBottom: "10px" }}>
                    <Row className='d-flex align-items-center'>
                        <Col md={4}>{t("New Label")} :</Col>
                        <Col md={18}>
                            <Input
                                size='large'
                                style={{ marginRight: "8px" }}
                                placeholder=""
                                value={newTag}
                                onChange={(e) => setNewTag(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        handleAddTag(); // Call the function when Enter is pressed
                                    }
                                }}
                            />
                        </Col>
                        <Col md={2}>
                            <Button
                                style={{
                                    backgroundColor: "#F8E2E8",
                                    border: "none",
                                    color: "#E50A47",
                                    height: "26px",
                                    width: "26px",
                                    marginLeft: "10px",
                                    borderRadius: "50%"
                                }}
                                icon={<AddSvg />}
                                onClick={handleAddTag}
                            /></Col>
                    </Row>
                </div>
                <div style={{ borderBottom: "1px dashed #ddd", marginBottom: "16px" }}></div>
                <div className='d-flex align-items-baseline' style={{ padding: "10px 60px", }}>
                    <Col md={4} >{t("Already have tags")} :</Col>
                    <div style={{ marginTop: "8px", display: "flex", flexWrap: "wrap", gap: "8px" }}>
                        {tags.map((tag, index) => (
                            <Tag
                                key={index}
                                closable
                                onClose={() => handleDeleteTag(tag)}
                                closeIcon={
                                    <div className='d-flex align-items-center'>
                                        <div style={{ height: "18px", width: "1.5px", backgroundColor: "white", marginLeft: "5px", marginRight: "5px" }}></div>
                                        <TrashSvg style={{ cursor: "pointer" }} />
                                    </div>
                                }
                                style={{
                                    backgroundColor: "#fdecf1",
                                    color: "#E50A47",
                                    width: "auto",
                                    height: "25px",
                                    border: "none",
                                    display: "flex",
                                    alignItems: "center",
                                    borderRadius: "6px",
                                    fontSize: "12px",
                                    fontWeight: 500
                                }}
                            >
                                <div style={{
                                    width: "5px",
                                    height: "5px",
                                    backgroundColor: "#E50A47",
                                    borderRadius: "50%",
                                    marginRight: "5px"
                                }}></div>
                                {tag.name || ""}
                            </Tag>
                        ))}
                    </div>
                </div>
                <div className='d-flex justify-content-center mt-3'>
                    <Button
                        disabled={tags.length > 0 ? false : true}
                        style={{
                            backgroundColor: "#E50A47",
                            border: "none",
                            color: "White",
                            height: "45px",
                            width: "230px",
                            margin: "auto",
                            borderRadius: "10px",
                            fontSize: "16px",
                            fontWeight: 500,
                            opacity: tags.length > 0 ? 1 : 0.5
                        }}
                        onClick={() => {
                            setModalOpen(false)
                        }}
                    >
                        {t("Confirm Submit")}
                    </Button>
                </div>
            </Modal>
        </>
    );
};

export default MagazineHeader;
