import React, { useRef, useState } from "react";
import MessageConfirmModal from "../../components/messageModal/MessageConfirmModal";
import DashboardComponent from "../../components/dashboard/dashboardMember";
import TableUserManagerment from "../../components/dashboard/tabUserManagement";
import { Spin } from "antd";

const MemberPage = () => {
    const messageModalRef = useRef("");
    const [loading, setLoading] = useState(false)
    return (
        <Spin spinning={loading}>
            <div className="box-shadow h-100 w-100">
                <div className="w-100 h-100 overflow-auto">
                    <div className="w-100">
                        <DashboardComponent />
                        <TableUserManagerment messageModalRef={messageModalRef} loading={loading} setLoading={setLoading} />
                    </div>
                    <MessageConfirmModal textCentered ref={messageModalRef} />
                </div>
            </div>
        </Spin>
    )
}

export default MemberPage