import React, { useEffect, useState } from "react";
import { Dropdown, Form } from "react-bootstrap";
import "./select-language.component.css";
import { FaTimes } from 'react-icons/fa';
import { languages } from "../../pages/AddLanguageModal";

const SelectLanguageComponent = ({ supportedLanguage, defaultLanguage, onSupportLanguageChange, onDefaultLanguageChange }) => {
    const [selectedLanguages, setSelectedLanguages] = useState([...supportedLanguage]);
    const [theDefault, setTheDefault] = useState(defaultLanguage);
    const selectChange = (key, value, checked) => {
        if (checked) {
            onSupportLanguageChange('select', key);
            setSelectedLanguages([...selectedLanguages, key]);
        } else {
            onSupportLanguageChange('deselect', key);
            setSelectedLanguages(([...selectedLanguages].filter(lang => lang !== key)));
        }
    }

    useEffect(() => {
        setSelectedLanguages([...supportedLanguage]);
        setTheDefault(defaultLanguage);
    }, [supportedLanguage, defaultLanguage]);

    const defaultLanguageChange = (newDefault) => {
        setTheDefault(newDefault);
        onDefaultLanguageChange(newDefault);
    }

    return (
        <>
            <div className="d-flex flex-row">
                <div style={{ width: "100px" }}>
                    Supported Languages:
                </div>
                <div style={{ width: "100%" }}>
                    <div style={{ display: "flex" }}>
                        <Dropdown style={{ flex: 1 }}>
                            <Dropdown.Toggle style={{ width: "100%", textAlign: "right" }}>
                                <div style={{ position: "absolute", left: 0, width: "100%", height: "100%", textAlign: "left" }}>
                                    <span style={{ marginTop: "auto", marginBottom: "auto", marginLeft: 10 }}>
                                        {
                                            selectedLanguages.length === 0 ?
                                                'Please select language' :
                                                (
                                                    selectedLanguages.length === 1 ?
                                                        languages[selectedLanguages[0]] : `${selectedLanguages.length} Languages`
                                                )
                                        }
                                    </span>
                                </div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu style={{ maxHeight: 160, overflow: "scroll", fontSize: 12 }}>
                                {
                                    Object.entries(languages).map(([key, value], index) => {
                                        return <li key={'li' + index} style={{
                                            paddingLeft: 10,
                                            paddingRight: 10,
                                            whiteSpace: "nowrap",
                                            textOverflow: "ellipsis",
                                            overflow: "hidden"
                                        }}>
                                            <Form.Check
                                                type="checkbox"
                                                id={key}
                                                label={value}
                                                checked={selectedLanguages.indexOf(key) > -1}
                                                onChange={event => selectChange(key, value, event.target.checked)}
                                            />
                                        </li>
                                    })
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    {
                        selectedLanguages.length > 0 ? (
                            <div style={{ margin: "10px 0" }}>
                                {
                                    selectedLanguages.map((key) => {
                                        return <div className="selected-language" key={'fatimes' + key}>
                                            {languages[key]}
                                            <FaTimes className="cancel-selected" onClick={() => selectChange(key, languages[key], false)} />
                                        </div>
                                    })
                                }
                            </div>
                        ) : null
                    }
                </div>
            </div>
            {
                onDefaultLanguageChange ? <div className="d-flex flex-row" style={{ marginBottom: 10 }}>
                    <div style={{ width: "100px" }}>
                        Default Language:
                    </div>
                    <select className="form-control input-sm" onChange={event => defaultLanguageChange(event.target.value)}>
                        <option value="">Select the default language</option>
                        {
                            selectedLanguages.map(lang => <option value={lang} selected={theDefault === lang}>{languages[lang]}</option>)
                        }
                    </select>
                </div> : null
            }
        </>
    )
}

export default SelectLanguageComponent